import moment from 'moment-timezone'
import React from 'react'

import { CameraCanvas } from 'components/cameras'
import { Spinner } from 'content'
import { Progress } from 'rsuite'
import RTMIP, { Camera, Detected, Frame, WS } from 'rtmip'
import getFrame from './getframe'

interface Props {
  src: string
  className?: string

  camera: Camera
  rate: number
}

interface State {
  analytics: Record<number, Detected>
}

interface AnalyticsResp {
  timestamp: number
  latency: number
  time: number
  detected: Detected[]
}

export default class SmartVIDEO extends React.Component<Props, State> {
  state = {} as State

  video: any = React.createRef<HTMLVideoElement>()
  videoHidden: any = React.createRef<HTMLVideoElement>()
  canvas: HTMLCanvasElement = document.createElement('canvas')
  cache = [] as AnalyticsResp[]
  cacheState = {
    time: 0,
  }
  timeout = 0
  interval = 0
  started = false

  sendTime = 0
  sendOffset = 0
  ws?: WS = undefined

  componentDidMount() {
    this.ws = RTMIP.ws()
    this.ws.camerasParseFrame(this.setDetected).catch(this.setError)
  }

  componentWillUnmount() {
    console.log('unmount')
    window.clearTimeout(this.timeout)
    window.clearInterval(this.interval)

    this.ws?.close()
  }

  getFrame = (callback: (frame: any) => void) => {
    if (!this.video || !this.videoHidden) return

    this.canvas.width = this.video.videoWidth
    this.canvas.height = this.video.videoHeight

    getFrame(this.videoHidden, this.canvas, { onFrame: callback })
  }

  //
  // analytics
  //

  getRate = () => {
    const { rate } = this.props
    return rate ? 1000 / rate : 1000
  }

  sendFrame = () => {
    const { camera } = this.props
    this.sendTime = moment().valueOf()
    this.timeout = window.setTimeout(this.sendFrame, 5000)

    this.clearCache()

    this.getFrame((img: string) => {
      const offset = Math.round(this.videoHidden.currentTime * 1000)
      console.log('cache time:', offset)

      const frame = {
        img,
        camera: { id: camera.id, name: camera.name },
        offset,
      } as Frame

      this.sendOffset = offset
      this.ws?.send({ camera_id: camera.id, frame })
    })
  }

  setDetected = (d: Detected[]) => {
    const rate = this.getRate()

    window.clearTimeout(this.timeout)

    this.cache.push({
      timestamp: moment().unix(),
      latency: moment().valueOf() - this.sendTime,
      time: Number((this.sendOffset / 1000).toFixed(2)),
      detected: d,
    })

    this.cacheState.time = this.sendOffset

    const diff = this.sendTime + rate - moment().valueOf()

    if (!this.started && this.sendOffset > 2) {
      this.video.play()
      this.started = true
    }

    if (diff > 30) {
      this.timeout = window.setTimeout(this.sendFrame, diff)
    } else {
      this.sendFrame()
    }
  }

  setError = (err: any) => {
    const { camera } = this.props
    console.error(`failed to parse frame: ${camera.name}, ${err}`)
  }

  clearCache = () => {
    if (this.cache.length < 10) return
    if (!this.video) return

    const now = moment().unix() - this.video.duration * 2
    this.cache = this.cache.filter((a) => a.timestamp > now)
  }

  showAnalytics = () => {
    if (!this.video) return

    const time = Number(this.video.currentTime.toFixed(2))
    let resp = { detected: [] as Detected[] } as AnalyticsResp
    let diff = 10

    this.cache.some((a) => {
      const d = a.time > time ? a.time - time : time - a.time
      if (d < diff) {
        resp = a
        diff = d
      }

      return d <= 0.1
    })

    const analytics = {} as Record<number, Detected>
    resp?.detected?.forEach((d: Detected) => (analytics[d.analytics.id] = d))

    this.setState({ analytics })
  }

  //
  // handlers
  //

  handleLoaded = () => {
    this.sendFrame()
    this.interval = window.setInterval(this.showAnalytics, this.getRate() * 0.5)
  }

  getCachePercent = (): number => {
    return (this.cacheState.time / (this.videoHidden.duration * 1000)) * 100
  }

  //
  // render
  //

  render() {
    const src = RTMIP.urlauth2(this.props.src)
    if (!src) return this.renderLoader()

    return (
      <div>
        <video
          ref={(ref) => (this.videoHidden = ref)}
          src={src}
          className='smartvideo-hidden'
          // crossOrigin='use-credentials'
          crossOrigin='anonymous'
          onLoadedMetadata={this.handleLoaded}
          controls={false}
          autoPlay
          muted
          loop
        />
        <video
          ref={(ref) => (this.video = ref)}
          src={src}
          className={this.props.className}
          // crossOrigin='use-credentials'
          crossOrigin='anonymous'
          controls={this.started}
          loop
        />
        {!this.started && <Spinner className='spinner-small spinner-center' />}
        <CameraCanvas
          analytics={this.state.analytics || {}}
          width={this.canvas.width}
          height={this.canvas.height}
        />
        <div className='smartvideo-cacheprogress'>
          <Progress.Line
            percent={this.getCachePercent()}
            showInfo={false}
            strokeWidth={4}
          />
        </div>
      </div>
    )
  }

  renderLoader() {
    return (
      <div className={this.props.className}>
        <div className='camera-loader'>
          <Spinner className='spinner-small spinner-center' />
        </div>
      </div>
    )
  }
}

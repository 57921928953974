import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaExclamationCircle } from 'react-icons/fa'
import { Link, RouteComponentProps } from 'react-router-dom'
import { ButtonToolbar, CheckPicker, Panel, Popover, Whisper } from 'rsuite'

import RTMIP, { ShortModel, StatusModel } from 'rtmip'
import * as STATUS from 'status'
import Content, {
  ContentState,
  HeaderLeft,
  HeaderSearch,
  setTitle,
} from './content'

import './statuspage.less'

export const filterStatusValues: ShortModel[] = [
  { name: STATUS.DISABLED } as ShortModel,
  { name: STATUS.ENABLED } as ShortModel,
  { name: STATUS.ACTIVE } as ShortModel,
  { name: STATUS.ERRORS } as ShortModel,
  { name: STATUS.INIT } as ShortModel,
]

interface RouteParams {
  section: string
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}

interface State extends ContentState {
  list: StatusModel[]
  filter: {
    status: string[]
  }
}

class StatusPage extends React.Component<Props, State> {
  state = {
    filter: {},
  } as State

  interval = 0

  componentDidMount() {
    setTitle(this.getSection() + '.title')
    this.loadStatus()
    this.interval = window.setInterval(this.loadStatus, 20000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadStatus = () => {
    RTMIP.sectionStatus(this.getSection())
      .then((list) => {
        this.setState({ list, loaded: true })
      })
      .catch((err) => this.setState({ error: err.message, loaded: true }))
  }

  //
  // handlers
  //

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleFilter = (val: any, key: string) => {
    const { filter } = this.state
    Object.assign(filter, { [key]: val })

    this.setState({ filter })
  }

  //
  // data
  //

  getSection = (): string => {
    return this.props.match.params.section || ''
  }

  getData = (): StatusModel[] => {
    let { list, filter, search } = this.state

    if (filter.status?.length) {
      list = list?.filter((s) => statusFilter(s, filter.status))
    }

    if (search) {
      search = search.toLocaleLowerCase()
      list = list?.filter((s) => {
        return (
          s.name?.toLocaleLowerCase().includes(search!) ||
          s.desc?.toLocaleLowerCase().includes(search!)
        )
      })
    }

    return list || []
  }

  //
  // render
  //

  render() {
    const { loaded, error } = this.state

    const data = this.getData()

    return (
      <Content
        className='status-content'
        loaded={loaded}
        error={error}
        header={this.renderHeader()}>
        <div className='status-container'>{data.map(this.renderStatus)}</div>
      </Content>
    )
  }

  renderStatus = (p: StatusModel, i: number) => {
    return (
      <Link to={`/settings/${this.getSection()}/${p.id}`}>
        <Panel
          key={p.name + i}
          data-state={p.status}
          className='status-panel'
          header={
            <div>
              {p.name}
              {p.errors && (
                <Whisper
                  placement='autoVertical'
                  speaker={<Popover>{p.errors}</Popover>}>
                  <span className='exclamationicon'>
                    <FaExclamationCircle />
                  </span>
                </Whisper>
              )}
            </div>
          }
        />
      </Link>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { filter, search } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        left={
          <HeaderLeft back={`/settings/${this.getSection()}`}>
            <ButtonToolbar>
              <CheckPicker
                placeholder={t('health.status_filter')}
                className='header-picker'
                data={filterStatusValues}
                value={filter.status}
                onChange={(v) => this.handleFilter(v, 'status')}
                labelKey='name'
                valueKey='name'
              />
            </ButtonToolbar>
          </HeaderLeft>
        }></HeaderSearch>
    )
  }
}

export default withTranslation()(StatusPage)

export function statusFilter(s: StatusModel, status: string[]): boolean {
  return status.some((status) => {
    switch (status) {
      case STATUS.DISABLED:
        return !s.enabled
      case STATUS.ENABLED:
        return s.enabled
      default:
        return s.status === status
    }
  })
}

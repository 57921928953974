import React from 'react'
import url from 'url'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Divider,
  Form,
  Grid,
  IconButton,
  InputGroup,
  InputNumber,
  InputPicker,
  Message,
  Modal,
  Row,
  Tag,
  Tooltip,
  Whisper,
} from 'rsuite'

import RTMIP, { ONVIFprofile, ONVIFsize } from 'rtmip'
import { TextField, Spinner, success, alert } from 'content'
import { InputDataType } from 'types'
import './onvif.less'
import { FaCog, FaReply } from 'react-icons/fa'

interface Props extends WithTranslation {
  addr: string
  onAddr: (uri: string) => void
}
interface State {
  auth: {
    user: string
    pass: string
  }

  show: boolean
  unauthorized: boolean
  error?: string

  profiles?: ONVIFprofile[]
  selected?: ONVIFprofile
}

class ONVIF extends React.Component<Props, State> {
  state = {
    auth: { user: '', pass: '' },
  } as State

  ONVIFprofiles = () => {
    const { addr } = this.props
    const { auth } = this.state

    RTMIP.ONVIFprofiles(addr, auth).then(this.setProfiles).catch(this.setError)
  }

  setProfiles = (profiles: ONVIFprofile[]) => {
    if (!profiles?.length) {
      this.setState({ unauthorized: false, error: 'profiles not found' })
      return
    }

    this.setState({
      unauthorized: false,
      error: undefined,
      profiles,
      selected: profiles[0],
    })
  }

  setError = (err: Error) => {
    this.setState({
      error: err.message,
      unauthorized:
        err.message.includes('401') ||
        err.message.includes('is not authorized'),
    })
  }

  //
  // handlers
  //

  handleAuthForm = (form: any) => {
    const { auth } = this.state
    Object.assign(auth, form)

    this.setState({ auth })
  }

  handleToggle = () => {
    const { show } = this.state

    this.setState({ show: !show })

    if (!show) {
      this.ONVIFprofiles()
    }
  }

  handleSelectProfile = (p: ONVIFprofile) => {
    this.setState({ selected: p })
  }

  handleApplyAddr = (uri: string) => {
    const { addr } = this.props
    let { user, pass } = this.state.auth

    if (addr) {
      try {
        const u = new URL(addr.replace('rtsp://', 'http://'))
        user = decodeURIComponent(u.username)
        pass = decodeURIComponent(u.password)
      } catch (e) {
        // do nothing
      }
    }

    if (user) {
      const newAddr = url.parse(uri)
      newAddr.auth = `${user}:${pass}`
      uri = decodeURIComponent(url.format(newAddr))
    }

    this.props.onAddr(uri)
    this.setState({ show: false })
  }

  handleSave = () => {
    const { addr } = this.props
    const { auth, selected } = this.state
    if (!selected) return

    const conf = selected.video
    conf.Quality = Number(conf.Quality)
    conf.H264.GovLength = Number(conf.H264.GovLength)
    conf.RateControl.BitrateLimit = Number(conf.RateControl.BitrateLimit)
    conf.RateControl.FrameRateLimit = Number(conf.RateControl.FrameRateLimit)

    RTMIP.changeONVIFprofile(addr, conf, auth).then(success).catch(alert)
  }

  handleProfileForm = (kv: Object) => {
    const { selected } = this.state
    if (!selected) return

    Object.assign(selected.video, kv)
    this.setState({})
  }

  handleProfileFormRate = (kv: any) => {
    const { selected } = this.state
    if (!selected) return

    Object.assign(selected.video.RateControl, kv)
    this.setState({})
  }

  handleProfileFormH264 = (kv: any) => {
    const { selected } = this.state
    if (!selected) return

    Object.assign(selected.video.H264, kv)
    this.setState({})
  }

  //
  // data
  //

  cleanAddress = (): string => {
    let { addr } = this.props

    addr = addr.replace('rtsp://', '')
    addr = addr.split('/')[0]
    addr = addr.split('@').pop() || ''
    addr = addr.split(':')[0]

    return addr
  }

  getEncodingOptions = () => {
    const { selected } = this.state
    if (!selected) return []

    let options = [] as InputDataType[]
    if (selected.options.H264.ResolutionsAvailable) {
      options.push({ value: 'H264', label: 'H264' })
    }
    if (selected.options.JPEG.ResolutionsAvailable) {
      options.push({ value: 'MJPEG', label: 'MJPEG' })
    }

    return options
  }

  getEncodingOption = () => {
    const { selected } = this.state
    if (!selected) return undefined

    if (selected.options.H264.ResolutionsAvailable) {
      return selected.options.H264
    }
    if (selected.options.JPEG.ResolutionsAvailable) {
      return selected.options.JPEG
    }

    return undefined
  }

  getResolutionOptions = () => {
    const { selected } = this.state
    if (!selected) return []

    // let opt = (selected.options as any)[
    //   selected.video.Encoding
    // ] as ONVIFprofileEncodingOption
    // if (!opt || !opt.ResolutionsAvailable) return []
    const opt = this.getEncodingOption()
    if (!opt) return []

    return opt.ResolutionsAvailable?.map(this.toString).map((s) => ({
      label: s,
      value: s,
    }))
  }

  getQualityOptions = () => {
    const { t } = this.props
    const { selected } = this.state
    if (!selected) return []

    const options = [] as InputDataType[]
    const { Min, Max } = selected.options.QualityRange
    for (let q = Min; q <= Max; q++) {
      options.push({
        label: t(`onvif.quality.${q}`),
        value: q.toString(),
      })
    }

    return options
  }

  getH264Profiles = () => {
    const { selected } = this.state
    if (!selected) return []

    return selected.options.H264.H264ProfilesSupported.map((v) => ({
      value: v,
      label: v,
    }))
  }

  toString = (size: ONVIFsize): string => {
    return `${size.Width}x${size.Height}`
  }

  strToSize = (s: string): ONVIFsize => {
    const ss = s.split('x')
    return {
      Width: Number(ss[0]),
      Height: Number(ss[1]),
    }
  }

  //
  // render
  //

  render() {
    const { show } = this.state

    if (show) return this.renderWindow()

    return (
      <InputGroup.Button disabled={show} onClick={this.handleToggle}>
        <FaCog />
      </InputGroup.Button>
    )
  }

  renderWindow() {
    const { unauthorized, error } = this.state

    return (
      <Modal onClose={() => this.handleToggle()} overflow={false} open>
        <Modal.Header>
          <h4>
            <img
              className='onvif-logo'
              src='/assets/img/onvif.png'
              alt='ONVIF'
            />
            <Tag>{this.cleanAddress()}</Tag>
          </h4>
        </Modal.Header>
        {error && (
          <Message type='error' showIcon>
            {error}
          </Message>
        )}
        <Modal.Body>
          {unauthorized ? this.renderAuth() : this.renderProfiles()}
        </Modal.Body>
      </Modal>
    )
  }

  renderProfiles() {
    const { profiles, selected } = this.state

    return (
      <div>
        {!profiles && <Spinner center />}
        <ButtonGroup block justified>
          {profiles?.map((p) => (
            <Button
              key={p.name}
              active={p.name === selected?.name}
              onClick={() => this.handleSelectProfile(p)}>
              {p.name}
            </Button>
          ))}
        </ButtonGroup>
        {this.renderProfile()}
      </div>
    )
  }

  renderProfile() {
    const { t } = this.props
    const { selected } = this.state
    if (!selected) return ''

    return (
      <Form onSubmit={this.handleSave} fluid>
        {selected.stream_uri && (
          <TextField
            label={t('onvif.stream_uri')}
            tail={
              <Whisper
                placement='top'
                speaker={<Tooltip>{t('onvif.apply_stream_uri')}</Tooltip>}>
                <IconButton
                  icon={<FaReply />}
                  onClick={() => this.handleApplyAddr(selected.stream_uri)}
                />
              </Whisper>
            }>
            {selected.stream_uri}
          </TextField>
        )}
        {selected.snapshot_uri && (
          <TextField
            label={t('onvif.snapshot_uri')}
            tail={
              <Whisper
                placement='top'
                speaker={<Tooltip>{t('onvif.apply_stream_uri')}</Tooltip>}>
                <IconButton
                  icon={<FaReply />}
                  onClick={() => this.handleApplyAddr(selected.snapshot_uri)}
                />
              </Whisper>
            }>
            {selected.snapshot_uri}
          </TextField>
        )}

        <Divider />

        <Grid fluid>
          <Row gutter={15}>
            <Col md={12}>
              <Form.ControlLabel>{t('cameras.framesize')}</Form.ControlLabel>
              <InputPicker
                data={this.getResolutionOptions()}
                value={this.toString(selected.video.Resolution)}
                onChange={(v) =>
                  this.handleProfileForm({ Resolution: this.strToSize(v) })
                }
                cleanable={false}
                block
              />
            </Col>

            <Col md={12}>
              <Form.ControlLabel>{t('cameras.quality')}</Form.ControlLabel>
              <InputPicker
                data={this.getQualityOptions()}
                value={selected.video.Quality.toString()}
                onChange={(v) => this.handleProfileForm({ Quality: v })}
                cleanable={false}
                block
              />
            </Col>
            <Col md={12}>
              <Form.ControlLabel>{t('cameras.framerate')}</Form.ControlLabel>
              <InputNumber
                value={selected.video.RateControl.FrameRateLimit}
                min={this.getEncodingOption()?.FrameRateRange.Min || 1}
                max={this.getEncodingOption()?.FrameRateRange.Max || 25}
                onChange={(v) =>
                  this.handleProfileFormRate({ FrameRateLimit: v })
                }
              />
            </Col>

            <Col md={12}>
              <Form.ControlLabel>{t('cameras.stat.bitrate')}</Form.ControlLabel>
              <InputNumber
                value={selected.video.RateControl.BitrateLimit}
                min={256}
                max={16384}
                onChange={(v) =>
                  this.handleProfileFormRate({ BitrateLimit: v })
                }
              />
            </Col>
          </Row>

          <Row gutter={15}>
            <Col md={24}>
              <Form.ControlLabel>{t('onvif.encoding')}</Form.ControlLabel>
              <div>
                <ButtonGroup block justified>
                  {selected.options.H264.ResolutionsAvailable && (
                    <Button
                      key='H264'
                      active={selected.video.Encoding === 'H264'}
                      onClick={() =>
                        this.handleProfileForm({ Encoding: 'H264' })
                      }>
                      H264
                    </Button>
                  )}
                  {selected.options.JPEG.ResolutionsAvailable && (
                    <Button
                      key='JPEG'
                      active={selected.video.Encoding === 'JPEG'}
                      onClick={() =>
                        this.handleProfileForm({ Encoding: 'JPEG' })
                      }>
                      MJPEG
                    </Button>
                  )}
                </ButtonGroup>
              </div>

              {/* <InputPicker
                data={this.getEncodingOptions()}
                value={selected.video.Encoding}
                onChange={(v) => this.handleProfileForm({ Encoding: v })}
                cleanable={false}
                block
              /> */}
            </Col>
          </Row>

          {selected.video.Encoding === 'H264' && (
            <Row gutter={15}>
              <Col md={12}>
                <Form.ControlLabel>
                  {t('onvif.encoding_profile')}
                </Form.ControlLabel>
                <InputPicker
                  data={this.getH264Profiles()}
                  value={selected.video.H264.H264Profile}
                  onChange={(v) =>
                    this.handleProfileFormH264({ H264Profile: v })
                  }
                  cleanable={false}
                  block
                />
              </Col>
              <Col md={12}>
                <Form.ControlLabel>
                  {t('onvif.iframe_interval')}
                </Form.ControlLabel>
                <InputNumber
                  value={selected.video.H264.GovLength}
                  min={this.getEncodingOption()?.GovLengthRange.Min || 1}
                  max={this.getEncodingOption()?.GovLengthRange.Max || 250}
                  onChange={(v) => this.handleProfileFormH264({ GovLength: v })}
                />
              </Col>
            </Row>
          )}
        </Grid>

        <Modal.Footer>
          <Button onClick={this.handleToggle}>{t('cancel')}</Button>
          <Button type='submit' appearance='primary'>
            {t('save')}
          </Button>
        </Modal.Footer>
      </Form>
    )
  }

  renderAuth() {
    const { t } = this.props
    const { auth } = this.state

    return (
      <Form
        formValue={auth}
        onChange={this.handleAuthForm}
        onSubmit={this.ONVIFprofiles}
        fluid>
        <Form.Group>
          <Form.ControlLabel>{t('auth.username')}</Form.ControlLabel>
          <Form.Control name='user'></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>{t('auth.password')}</Form.ControlLabel>
          <Form.Control name='pass' type='password'></Form.Control>
        </Form.Group>

        <ButtonToolbar align='center'>
          <Button appearance='primary' type='submit'>
            {t('auth.submit')}
          </Button>
        </ButtonToolbar>
      </Form>
    )
  }
}

export default withTranslation()(ONVIF)

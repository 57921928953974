import React from 'react'
import { ButtonToolbar, Panel } from 'rsuite'

import './toolbar.less'

interface Props {
  label?: any
  right?: any
  children?: any
}

export default class Toolbar extends React.Component<Props> {
  render() {
    const { label, right, children } = this.props

    return (
      <Panel className='toolbar' bordered bodyFill>
        <ButtonToolbar
          justifyContent='flex-end'
          style={{ whiteSpace: 'nowrap' }}>
          <span>{label}</span>
        </ButtonToolbar>
        <ButtonToolbar justifyContent='center'>{children}</ButtonToolbar>
        <ButtonToolbar
          justifyContent='flex-end'
          style={{ whiteSpace: 'nowrap' }}>
          {right}
        </ButtonToolbar>
      </Panel>
    )
  }
}

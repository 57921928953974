import React from 'react'
import moment from 'moment-timezone'

import IMAGE from './image'

interface Props {
  camera_id: number
  rate?: number
  className?: string

  onError?: () => boolean
}

interface State {
  imgSrc: string
  tick: boolean
}

const failTimeout = 5000

export default class FRAMES extends React.Component<Props, State> {
  state = {
    imgSrc: '',
    tick: false,
  } as State

  unmounted: boolean = false
  failWatchLnterval = 0

  time = moment().valueOf() - 10
  mstimeout = 200 // Math.max(this.props.rate ? 1000 / this.props.rate : 1000, 50),
  timeOffset = 0

  refIMAGE = React.createRef<IMAGE>()

  componentDidMount() {
    // eslint-disable-next-line
    this.state.imgSrc = this.buildSrc()

    const { rate } = this.props
    if (rate) this.mstimeout = 1000 / rate

    this.failWatchLnterval = window.setInterval(this.failWatch, failTimeout)
  }

  componentWillUnmount() {
    this.unmounted = true
    window.clearInterval(this.failWatchLnterval)
  }

  failWatch = () => {
    if (this.time < moment().valueOf() - failTimeout) {
      this.updateSrc()
      console.warn(`camera ${this.props.camera_id} stream is restarted`)

      // this.refIMAGE.current?.loadImage(this.state.imgSrc)
      if (this.props.onError) this.props.onError()
    }
  }

  setTime = () => {
    this.time = moment().valueOf()
  }

  buildSrc() {
    const { camera_id } = this.props
    let imgTime = this.refIMAGE?.current?.state.imgTime || 0
    imgTime++

    return `/api/cameras/${camera_id}/frame?timems=${imgTime}&substream=true`
  }

  updateSrc() {
    const { tick } = this.state
    this.setTime()
    this.setState({ imgSrc: this.buildSrc(), tick: !tick })
  }

  onLoad = () => {
    const msdurr = moment().valueOf() - this.time - this.mstimeout

    this.setTime()
    window.setTimeout(() => this.updateSrc(), -msdurr)
  }

  onError = () => {
    const { onError } = this.props
    if (!onError) return

    const next = onError()
    if (!next) window.clearInterval(this.failWatchLnterval)
  }

  //
  //
  //

  render() {
    if (this.unmounted) return
    const { className } = this.props

    return (
      <div className={className}>
        <IMAGE
          ref={this.refIMAGE}
          src={this.state.imgSrc}
          onLoad={this.onLoad}
          onError={this.onError}
          className={className}
        />
        <div className='frames-heartbeat' data-tick={this.state.tick} />
      </div>
    )
  }
}

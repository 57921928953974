import queryString from 'query-string'

const queryStringOptions: any = { arrayFormat: 'comma' }

export function getQuery(): Record<string, any> {
  return queryString.parse(window.location.search, queryStringOptions)
}

export function setQuery(q: Record<string, any>, replace?: boolean) {
  const s = `?${queryString.stringify(q, queryStringOptions)}`
  if (replace) window.history.replaceState(q, 'video-archive', s)
  else window.history.pushState(q, 'video-archive', s)
}

export function addQuery(q: Record<string, any>, replace?: boolean) {
  const query = new URLSearchParams(window.location.search)

  Object.entries(q).forEach(([k, v]) => {
    query.set(k, v)
  })

  var url = window.location.pathname + '?' + query.toString()

  if (replace) window.history.replaceState(null, 'video-archive', url)
  else window.history.pushState(null, 'video-archive', url)
}

import path from 'path'
import React from 'react'
import { FaChevronCircleDown } from 'react-icons/fa'
import { Redirect } from 'react-router-dom'
import { Dropdown, IconButton, Popover, Whisper } from 'rsuite'

import {
  StorageFace,
  localStorageSaveFace,
} from 'components/events/eventsutils'
import { itemsTranslate, trans } from 'i18n'
import ROUTES from 'routes'
import {
  Detected,
  DirectionLeft,
  DirectionNone,
  DirectionRight,
  Item,
  Line,
  Point,
  Region,
  TextBox,
} from 'rtmip'
import AssignProfilePhoto from './assignprofilephoto'
import Canvas from './canvas'
import viewSettings from './viewsettings'

interface Props {
  analytics: { [key: number]: Detected }
  width: number
  height: number
  regions?: Region[]
  lines?: Line[]
  onDraw?: (points: Point[]) => void
  onException?: (item: Item) => void
  img?: string
}

export default class CameraCanvas extends React.Component<Props, any> {
  getRegions = () => {
    if (viewSettings.show('regions') || this.props.onDraw)
      return this.props.regions
    else return undefined
  }

  getLines = () => {
    if (viewSettings.show('regions') || this.props.onDraw)
      return this.props.lines
    else return undefined
  }

  getItemsKeypoints = () => {
    const { analytics } = this.props
    const keypoints = [] as Record<string, number[]>[]

    if (viewSettings.show('keypoints')) {
      Object.values(analytics).forEach((d: any) => {
        if (!d.items) return
        d.items.forEach((item: Item) => this.getItemKeypoints(item, keypoints))
      })
    }

    return keypoints
  }

  getItemKeypoints = (item: Item, keypoints: Record<string, number[]>[]) => {
    if (item.keypoints) keypoints.push(item.keypoints)
    if (item.items)
      item.items.forEach((item: Item) => this.getItemKeypoints(item, keypoints))
  }

  getItemsLines = () => {
    const { analytics } = this.props
    const lines = [] as number[][]

    if (viewSettings.show('keypoints')) {
      Object.values(analytics).forEach((d: any) => {
        if (!d.items) return
        d.items.forEach((item: Item) => this.getItemLines(item, lines))
      })
    }

    return lines
  }

  getItemLines = (item: Item, lines: number[][]) => {
    if (item.lines) lines.push(...item.lines)
    if (item.items) item.items.forEach((item) => this.getItemLines(item, lines))
  }

  toggleDirection = (l: Line) => {
    if (!l.edit) return

    switch (l.direction) {
      case DirectionNone:
        l.direction = DirectionRight
        break
      case DirectionRight:
        l.direction = DirectionLeft
        break
      case DirectionLeft:
        l.direction = DirectionNone
        break
    }

    this.setState({})
  }

  //
  // render
  //

  render() {
    const { analytics, onDraw, onException } = this.props
    const { width, height } = this.props

    return (
      <div
        className='camera-canvas'
        data-interaction={onDraw !== undefined || onException !== undefined}>
        {Object.values(analytics).map(this.renderDetected)}

        {viewSettings.show('stat') && analytics && (
          <div className='camera-canvas-stat'>
            {Object.values(analytics).map(this.renderAnalyticsStat)}
          </div>
        )}

        <Canvas
          width={width}
          height={height}
          regions={this.getRegions()}
          lines={this.getLines()}
          itemsKeypoints={this.getItemsKeypoints()}
          itemsLines={this.getItemsLines()}
          onDraw={onDraw}
        />
        {this.renderLines()}
      </div>
    )
  }

  renderLines() {
    const lines = this.getLines()
    if (!lines || !lines.length) return null

    return lines.map(this.renderLine)
  }

  getLineRot = (a: number[], b: number[]): ILineRot => {
    const { width, height } = this.props
    const [x1, y1] = a
    const [x2, y2] = b

    const angle = Math.atan2((y2 - y1) * height, (x2 - x1) * width)
    const length = Math.hypot((x2 - x1) * width, (y2 - y1) * height)

    return { angle, length } as ILineRot
  }

  renderLine = (l: Line, i: number) => {
    const rot = this.getLineRot(l.a, l.b)

    return (
      <div
        key={i}
        className='analyticsline'
        data-editable={l.edit}
        style={{
          transform: `rotate(${rot.angle}rad)`,
          width: rot.length + 'px',
          top: l.a[1] * 100 + '%',
          left: l.a[0] * 100 + '%',
        }}>
        {/* <div
          className='analyticsline-line'
          style={{
            backgroundColor: `rgba(${l.color[0]},${l.color[1]},${l.color[2]},0.8)`,
          }}
        /> */}
        {(!l.direction || l.direction === DirectionLeft) && (
          <div
            className='analyticsline-arrow_left'
            onClick={() => this.toggleDirection(l)}
          />
        )}
        {(!l.direction || l.direction === DirectionRight) && (
          <div
            className='analyticsline-arrow_right'
            onClick={() => this.toggleDirection(l)}
          />
        )}
      </div>
    )
  }

  renderDetected = (detected: Detected) => {
    const { onException } = this.props
    const { camera, analytics, items, texts, status, state, alert } = detected
    if (!camera || !analytics) return

    return (
      <div
        key={`canvas_${camera.id}_${analytics.id}`}
        className='camera-canvas-items'
        data-selectable={onException !== undefined}>
        {viewSettings.show('status') && status && (
          <div
            data-state={state}
            data-alert={alert}
            className='camera-canvas-status'
            dangerouslySetInnerHTML={{ __html: status }}
          />
        )}

        {items && items.map(this.renderTrack)}
        {items && items.map(this.renderItem)}
        {texts && texts.map(this.renderTextBox)}
      </div>
    )
  }

  renderAnalyticsStat = (detected: Detected) => {
    const { analytics, items } = detected
    if (!analytics.id || !analytics.name) return

    const grouped = {} as { [key: string]: number }
    if (items) {
      items.forEach((item: Item) => {
        grouped[item.type] ? grouped[item.type]++ : (grouped[item.type] = 1)
      })
    }

    return (
      <div
        key={'stat_' + detected.analytics.id}
        className='camera-canvas-stat-group'>
        <h5 className='camera-canvas-stat-title'>{analytics.name}</h5>
        {Object.entries(grouped).map(([type, count]) => (
          <p
            className='camera-canvas-stat-row'
            key={detected.analytics.id + type}>
            {itemsTranslate(type)}: {count}
          </p>
        ))}
      </div>
    )
  }

  renderItem = (item: Item): any => {
    if (!item || isEmptyBBox(item.bbox)) return null

    const { onException } = this.props

    const elems = item.items ? item.items.map(this.renderItem) : ([] as any[])

    elems.push(
      <BBox
        key={`bbox_${item.id}`}
        {...item}
        onException={onException}
        img={this.props.img}
      />
    )

    if (item.text && !isEmptyBBox(item.bbox)) {
      elems.push(
        <BBox
          key={`text_${item.id}`}
          {...item.text}
          onException={onException}
        />
      )
    }

    return elems
  }

  renderTextBox = (t: TextBox): any => {
    const left = t.x * 100 + '%'
    const top = t.y * 100 + '%'
    const width = t.w ? t.w * 100 + '%' : 'auto'
    const height = t.h ? t.h * 100 + '%' : 'auto'

    return (
      <div
        className='textbox'
        style={{ left, top, width, height, fontSize: t.size + 'vh' }}
        dangerouslySetInnerHTML={{ __html: t.text }}
      />
    )
  }

  renderTrack = (item: Item, i: number): any => {
    if (!item.track || !item.track.points) return null

    const color = item.track.color
      ? `rgba(${item.track.color.join(',')}, 1)`
      : 'red'

    const points = item.track.points

    return (
      <div className='camera-canvas' key={i}>
        {points.map((p, i) => {
          const styles = {
            left: p[0] * 100 + '%',
            top: p[1] * 100 + '%',
            backgroundColor: color,
            opacity: 1 - i / points.length,
          } as Record<string, any>

          const b = points[i + 1]
          if (b) {
            const rot = this.getLineRot(p, b)
            styles.transform = `rotate(${rot.angle}rad)`
            styles.width = rot.length + 'px'
          }

          return <div key={i} className='item-track' style={styles} />
        })}
      </div>
    )
  }
}

export interface ILineRot {
  angle: number
  length: number
}

function isEmptyBBox(bbox: number[]): boolean {
  return !bbox || bbox.length !== 4 || (!bbox[2] && !bbox[3])
}

export interface BBoxProps extends Item {
  onException?: (item: Item) => void
  img?: string
}

interface BBoxState {
  redirect: string

  assignItem?: Item
}

// Item component should be renderer in the camera-canvas
export class BBox extends React.Component<BBoxProps, BBoxState> {
  state = {} as BBoxState

  // build inline position bbox styles
  bboxStyles() {
    const item = this.props
    if (!Array.isArray(item.bbox) || item.bbox.length !== 4) return {}

    return {
      left: `${item.bbox[0] * 100}%`,
      top: `${item.bbox[1] * 100}%`,
      width: `${item.bbox[2] * 100}%`,
      height: `${item.bbox[3] * 100}%`,
    }
  }

  lookupColor(c: Item): string | undefined {
    if (!c) return undefined

    if (c.name) return c.name
    if (c.items && c.items.length) {
      c.items.sort((a: Item, b: Item): number => {
        if (a.prob > b.prob) return -1
        if (a.prob < b.prob) return 1
        return 0
      })

      return c.items[0].name || undefined
    }

    return undefined
  }

  searchByVector(vector: number[]) {
    const face = { vector: vector } as StorageFace
    localStorageSaveFace(face)

    let url = ROUTES.eventsarchive
    if (window.location.pathname === ROUTES.eventsarchive) {
      url += window.location.search
    }

    this.setState({ redirect: url })
  }

  openAssignPhoto = (item?: Item) => {
    this.setState({ assignItem: item })
  }

  openProfile = (item: Item) => {
    let redirect: string | undefined

    if (item.profile) {
      redirect = path.join(ROUTES.profiles.people, item.profile.id.toString())
    } else if (item.car) {
      redirect = path.join(ROUTES.profiles.cars, item.car.id.toString())
    }

    if (!redirect) return

    this.setState({ redirect })
  }

  openGroup = (item: Item) => {
    let redirect: string | undefined

    if (!item.profile.group) return

    if (item.profile) {
      redirect = `${ROUTES.profiles.people}?search=${item.profile.group.name}`
    } else if (item.car) {
      redirect = `${ROUTES.profiles.cars}?search=${item.car.group.name}`
    }

    if (!redirect) return

    this.setState({ redirect })
  }

  //
  // render
  //

  // render bbox with related text fields
  render() {
    const { onException, img, ...item } = this.props
    if (!item || !item.bbox || !item.type) return ''

    const { assignItem, redirect } = this.state

    if (redirect) return <Redirect to={redirect} />

    return (
      <div
        className='bbox'
        data-border={viewSettings.get('bbox-border')}
        data-state={item.state ? item.state : ''}
        data-alert={item.alert ? item.alert : ''}
        style={this.bboxStyles()}>
        {this.renderBBoxMenu()}
        {assignItem && img && (
          <AssignProfilePhoto
            item={assignItem}
            img={img}
            onClose={this.openAssignPhoto}
          />
        )}

        <div className='bbox-texts'>
          {this.renderTextField({
            val: item.name || item.type,
            state: item.state,
            keySuffix: 'name',
            color: this.lookupColor(item.colors),
          })}

          {this.renderTextField({ val: item.id, keySuffix: 'id' })}

          {item.prob !== undefined &&
            this.renderTextField({
              val: `${(item.prob * 100).toFixed(0).toString()}%`,
              keySuffix: 'prob',
            })}

          {item.attributes &&
            Object.entries(item.attributes).map(([key, val]) =>
              this.renderTextField({
                val: val.toString(), // > 0.01 ? val.toFixed(2) : '0.0',
                key: key,
                keySuffix: 'attr',
              })
            )}

          {viewSettings.show('attr') &&
            item.items &&
            item.items.map((item: Item) => this.renderNestedItem(item))}

          {item.text && this.renderNestedItem(item.text)}
        </div>
        {item.emotions && this.renderEmotions(item.emotions)}
      </div>
    )
  }

  renderNestedItem(item: Item) {
    if (!isEmptyBBox(item.bbox)) return undefined

    return this.renderTextField({
      val: item.name,
      key: item.type,
      state: item.state,
    })
  }

  renderTextField(opt: TextFieldProps) {
    if (!opt.val) return ''

    if (opt.key && opt.key.length > 4 && (opt.key + opt.val).length > 10) {
      opt.key = opt.key.substr(0, 4)
    }

    if (opt.keySuffix && viewSettings.hide(opt.keySuffix)) return ''

    const { id } = this.props
    let key = opt.key ? opt.key + opt.val : opt.val
    if (opt.keySuffix) key += '.' + opt.keySuffix

    return (
      <div key={id + '.' + key} className='bbox-field'>
        <div className='bbox-text' data-state={opt.state}>
          {opt.color && (
            <span className='bbox-color' style={{ background: opt.color }} />
          )}
          {opt.key && itemsTranslate(opt.key) + ': '}
          {itemsTranslate(opt.val)}
        </div>
      </div>
    )
  }

  renderEmotions(emotions: Record<string, number>) {
    return (
      <div className='bbox-emotions'>
        {Object.entries(emotions).map(([key, val]) => (
          <div className='bbox-field' key={key}>
            <div className='bbox-fill' style={{ width: val * 100 + '%' }} />
            <div className='bbox-emotion'>{itemsTranslate(key)}</div>
          </div>
        ))}
      </div>
    )
  }

  renderBBoxMenu() {
    const { onException, ...item } = this.props
    if (!onException && (!item.vector || !item.vector.length)) return null

    return (
      <div className='bbox-menu'>
        <Whisper
          placement='bottomStart'
          trigger='click'
          speaker={
            <Popover full>
              <Dropdown.Menu>
                {(item.profile || item.car) && (
                  <Dropdown.Item
                    key='openprofile'
                    onSelect={() => this.openProfile(item)}>
                    {trans('profiles.open')}
                  </Dropdown.Item>
                )}
                {((item.profile && item.profile.group_id) ||
                  (item.car && item.car.group_id)) && (
                  <Dropdown.Item
                    key='opengroup'
                    onSelect={() => this.openGroup(item)}>
                    {trans('profiles.groups.open')}
                  </Dropdown.Item>
                )}

                {!item.profile &&
                  item.vector &&
                  item.vector.length > 0 && [
                    <Dropdown.Item
                      key='assignphoto'
                      onSelect={() => this.openAssignPhoto(item)}>
                      {trans('profiles.assign_photo')}
                    </Dropdown.Item>,
                    <Dropdown.Item
                      key='searchbyvector'
                      onSelect={() => this.searchByVector(item.vector)}>
                      {trans('eventsarchive.searchbyface')}
                    </Dropdown.Item>,
                  ]}

                {onException && (
                  <Dropdown.Item
                    key='exception'
                    onSelect={() => onException(item)}>
                    {trans('analytics.create_exception')}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Popover>
          }>
          <IconButton icon={<FaChevronCircleDown />} size='sm' />
        </Whisper>
      </div>
    )
  }
}

interface TextFieldProps {
  val: any
  key?: string
  state?: string
  keySuffix?: string
  color?: string
}

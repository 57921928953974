import queryString from 'query-string'
import React from 'react'
import { Pagination, Stack, Table, Tag } from 'rsuite'

import { trans } from 'i18n'

interface Props {
  data: any[]
  children: any
  total?: number
  margin?: number
  page?: number

  onChangePage?: (page: number, limit: number) => void

  sort?: Sort
  sortColumn?: string
  sortType?: string
  onSortColumn?: (sortColumn: string, sortType: string) => void

  rowHeight?: number
  rowClassName?: ((row: any) => string) | string
  showInfo?: boolean
  limit?: number
}

interface State {
  page: number
  limit: number
  height: number

  sort?: Sort
}

export interface Sort {
  col: string
  type: string
}

export default class PageTable extends React.Component<Props, State> {
  state = {
    page: 1,
    limit: this.props.limit || 20,
    height: 400,
  } as State

  constructor(props: Props) {
    super(props)

    const margin = props.margin || 255
    this.state.height = window.innerHeight - margin

    const q = queryString.parse(window.location.search)
    if (q.page) this.state.page = parseInt(q.page as string)
    if (q.limit) this.state.limit = parseInt(q.limit as string)
  }

  componentDidMount() {
    window.addEventListener('resize', this.setSize)
    window.setTimeout(this.setSize, 200)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize)
  }

  setSize = () => {
    const margin = this.props.margin || 255
    const height = window.innerHeight - margin

    this.setState({ height })
  }

  setURL = (page: number, limit: number) => {
    const q = queryString.parse(window.location.search)
    q.page = page.toString()
    q.limit = limit.toString()

    window.history.replaceState(q, '', '?' + queryString.stringify(q))

    if (this.props.onChangePage) this.props.onChangePage(page, limit)
  }

  handleChangePage = (page: number) => {
    this.setState({ page })
    this.setURL(page, this.state.limit)
  }

  handleChangeLength = (limit: number) => {
    this.setState({ limit })
    this.setURL(this.state.page, limit)
  }

  handleSort = (col?: string, type?: string) => {
    if (!col || !type) return
    this.setState({ sort: { col, type } })
  }

  getData() {
    let { data } = this.props
    const { page, limit } = this.state
    const sort = this.state.sort || this.props.sort

    if (sort) {
      data.sort((a, b) => {
        let x = a[sort.col]
        let y = b[sort.col]

        if (typeof x === 'string') {
          let val = sort.type === 'asc' ? -1 : 1
          if (x < y) return val
          if (x > y) return -val
          return 0
        }

        if (sort.type === 'asc') {
          return x - y
        } else {
          return y - x
        }
      })
    }

    if (data.length > limit) {
      let at = limit * (page - 1)
      let to = Math.min(at + limit, data.length)

      data = data.slice(at, to)
    }

    return data
  }

  render() {
    const { children, rowHeight } = this.props
    const { height } = this.state
    const sort = this.state.sort || this.props.sort
    const rh = rowHeight || 45

    const data = this.getData()
    let dataHeight = Math.min(height, rh * data.length + 50)
    if (dataHeight < 200) dataHeight = 200

    return [
      <Table
        key='table'
        data={data}
        className='pagetable'
        rowHeight={rh}
        rowClassName={this.props.rowClassName}
        sortColumn={sort?.col}
        sortType={(sort?.type as any) || undefined}
        onSortColumn={this.handleSort}
        height={dataHeight}
        autoHeight>
        {children}
      </Table>,
      this.renderPagination(),
    ]
  }

  renderPagination() {
    const { total } = this.props
    const { page, limit } = this.state

    if (total && total <= limit && page <= 1) return null

    return (
      <Stack key='pagination' justifyContent='center'>
        <Pagination
          activePage={this.props.page || page}
          limit={limit}
          total={total || 0}
          // showInfo={this.props.showInfo}
          onChangePage={this.handleChangePage}
          onChangeLimit={this.handleChangeLength}
          limitOptions={[10, 20, 30, 50, 100]}
          maxButtons={5}
          last
          first
          next
          prev
        />
        <Tag>
          {trans('total')}:{total}
        </Tag>
      </Stack>
    )
  }
}

import React from 'react'
import { Button, ButtonToolbar, IconButton, Popover, Whisper } from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaTrash } from 'react-icons/fa'

import { trans } from 'i18n'

interface Props {
  onConfirm?: () => void
  disabled?: boolean
  circle?: boolean

  selected?: Record<string, any>
  suffix?: string
}
interface State {}

export default class DelButton extends React.Component<Props, State> {
  ref = React.createRef<any>()

  handleCancel = () => {
    this.ref.current?.close()
  }

  handleConfirm = () => {
    const { onConfirm } = this.props
    if (onConfirm) onConfirm()
    this.handleCancel()
  }

  render() {
    const { selected, suffix, disabled, circle } = this.props

    let text = ''
    if (selected) {
      const list = Object.values(selected)
      if (list?.length > 1)
        text = `${list.length} ${suffix || trans('records')}`
      else if (list?.length == 1) text = list[0].name || list[0].id || ''
    }

    return (
      <Whisper
        ref={this.ref}
        trigger='click'
        placement='bottomEnd'
        speaker={
          <Popover title={`${trans('delete')} ${text}?`}>
            <ButtonToolbar>
              <Button onClick={this.handleCancel}>{trans('cancel')}</Button>
              <Button
                appearance='primary'
                color='red'
                onClick={this.handleConfirm}>
                {trans('delete')}
              </Button>
            </ButtonToolbar>
          </Popover>
        }>
        <IconButton
          appearance='primary'
          disabled={disabled}
          icon={<Icon as={FaTrash} />}
          color='red'
          circle={circle}
        />
      </Whisper>
    )
  }
}

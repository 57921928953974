import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CustomProvider } from 'rsuite'

import './i18n'
import './index.less'
import App from './app'

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <CustomProvider theme='dark'>
      <Suspense fallback={null}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </CustomProvider>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import {
  Divider,
  Dropdown,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Whisper,
} from 'rsuite'

import { trans } from 'i18n'
import {
  FaBraille,
  FaEllipsisV,
  FaExpandArrowsAlt,
  FaListAlt,
  FaPen,
  FaVideo,
} from 'react-icons/fa'
import ROUTES from 'routes'
import { Camera } from 'rtmip'
import viewSettings from './viewsettings'

export interface CameraHeaderOptions {
  edit?: boolean
  open?: boolean
  events?: boolean
}

interface Props {
  camera: Camera

  expanded?: boolean
  onExpand?: () => void

  heatmap?: boolean
  onHeatmap?: () => void

  options?: CameraHeaderOptions
}

export default class CameraHeader extends React.Component<Props> {
  handleViewSettings = (key: string, val?: string) => {
    viewSettings.set(key, val)
    this.setState({})
  }

  render() {
    const { camera, onHeatmap, onExpand, expanded, heatmap } = this.props

    let { options } = this.props
    if (!options)
      options = { edit: true, open: true, events: true } as CameraHeaderOptions
    if (options.edit === undefined) options.edit = true
    if (options.open === undefined) options.open = true

    return (
      <div className='camera-header'>
        <div></div>
        <h6>{camera.name}</h6>

        <Stack
          className='camera-actions'
          spacing={4}
          alignItems='flex-end'
          justifyContent='flex-end'>
          {onHeatmap && (
            <Whisper
              placement='top'
              trigger='hover'
              speaker={<Tooltip>{trans('cameras.heatmap')}</Tooltip>}>
              <IconButton
                active={heatmap}
                appearance='subtle'
                onClick={() => onHeatmap()}
                icon={<FaBraille />}
              />
            </Whisper>
          )}
          {onExpand && (
            <Whisper
              placement='top'
              trigger='hover'
              speaker={<Tooltip>{trans('cameras.expand')}</Tooltip>}>
              <IconButton
                active={expanded}
                appearance='subtle'
                onClick={() => onExpand()}
                icon={<FaExpandArrowsAlt />}
              />
            </Whisper>
          )}
          {options.events && (
            <Whisper
              placement='top'
              trigger='hover'
              speaker={<Tooltip>{trans('events')}</Tooltip>}>
              <Link to={`${ROUTES.eventsarchive}?cameras=${camera.id}`}>
                <IconButton appearance='subtle' icon={<FaListAlt />} />
              </Link>
            </Whisper>
          )}
          {options.open && camera.enabled && (
            <Whisper
              placement='top'
              trigger='hover'
              speaker={<Tooltip>{trans('cameras.open')}</Tooltip>}>
              <Link to={`${ROUTES.livestream}?camera=${camera.id}`}>
                <IconButton appearance='subtle' icon={<FaVideo />} />
              </Link>
            </Whisper>
          )}
          {options.edit && (
            <Whisper
              placement='top'
              trigger='hover'
              speaker={<Tooltip>{trans('cameras.edit')}</Tooltip>}>
              <Link to={`${ROUTES.settings.cameras}/${camera.id}`}>
                <IconButton appearance='subtle' icon={<FaPen />} />
              </Link>
            </Whisper>
          )}
          {camera.enabled && (
            <Whisper
              placement='bottomEnd'
              trigger='click'
              enterable
              speaker={this.renderViewSettings()}>
              <IconButton appearance='subtle' icon={<FaEllipsisV />} />
            </Whisper>
          )}
        </Stack>
      </div>
    )
  }

  renderViewSettings() {
    return (
      <Popover full>
        <Dropdown.Menu className='camera-view_menu'>
          {this.renderViewSettingsToggle('name')}
          {this.renderViewSettingsToggle('prob')}
          {this.renderViewSettingsToggle('id')}
          {this.renderViewSettingsToggle('attr')}
          <Divider />
          {this.renderViewSettingsToggle('regions')}
          {this.renderViewSettingsToggle('keypoints')}
          {this.renderViewSettingsToggle('stat')}
          {this.renderViewSettingsToggle('status')}
          <Divider />
          {this.renderViewSettingsText('bbox-border', 'thin', 'thick')}
        </Dropdown.Menu>
      </Popover>
    )
  }

  renderViewSettingsToggle(key: string) {
    return (
      <Dropdown.Item
        className={'view-menu_' + viewSettings.get(key)}
        onClick={() => this.handleViewSettings(key)}>
        {key}
      </Dropdown.Item>
    )
  }

  renderViewSettingsText(key: string, valTrue: string, valFalse: string) {
    let val = viewSettings.get(key, valTrue)

    return (
      <Dropdown.Item
        onClick={() =>
          this.handleViewSettings(key, val === valTrue ? valFalse : valTrue)
        }>
        {val}
      </Dropdown.Item>
    )
  }
}

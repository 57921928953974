import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Panel,
  Footer,
  Table,
  ButtonToolbar,
  Button,
  Checkbox,
  IconButton,
  Whisper,
  Tooltip,
  Tag,
} from 'rsuite'
import { FaPen, FaTrash } from 'react-icons/fa'
import path from 'path'

import RTMIP, { FloorMap } from 'rtmip'
import Content, {
  ContentState,
  HeaderSearch,
  setTitle,
  alert,
  DelButton,
} from 'content'

interface Props extends WithTranslation {}

interface State extends ContentState {
  fmaps: FloorMap[]
  toDelete: Record<number, FloorMap>
}

class FloorMaps extends React.Component<Props, State> {
  state = {
    toDelete: {},
  } as State

  componentDidMount() {
    setTitle('floormaps.title')

    RTMIP.floormaps()
      .then((fmaps: FloorMap[]) => this.setState({ fmaps, loaded: true }))
      .catch(this.setError)
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  //
  // handlers
  //

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleDelete = (fmap: FloorMap) => {
    const { toDelete } = this.state
    toDelete[fmap.id] ? delete toDelete[fmap.id] : (toDelete[fmap.id] = fmap)

    this.setState({ toDelete })
  }

  perfomDelete = () => {
    let { fmaps, toDelete } = this.state

    Object.values(toDelete).forEach((fmap) => {
      RTMIP.deleteFloormap(fmap.id).catch(alert)
    })

    fmaps = fmaps.filter((fmap) => {
      return toDelete[fmap.id] === undefined
    })

    this.setState({ fmaps, toDelete: {} })
  }

  //
  // data
  //

  getData(): FloorMap[] {
    const search = this.state.search || ''
    let fmaps = this.state.fmaps || ([] as FloorMap[])

    if (search) {
      fmaps = fmaps.filter((fmap) => {
        return fmap.name.includes(search) || fmap.desc.includes(search)
      })
    }

    return fmaps
  }

  //
  // render
  //

  render() {
    const { loaded, error } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {this.renderTable()}
      </Content>
    )
  }

  renderHeader() {
    const { search, toDelete } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={
          <DelButton
            disabled={Object.keys(toDelete || {}).length === 0}
            onConfirm={this.perfomDelete}
            circle
          />
        }
      />
    )
  }

  renderTable() {
    const { t } = this.props
    const { loaded, toDelete } = this.state
    const fmaps = this.getData()

    const { Column, HeaderCell, Cell } = Table

    return (
      <Panel className='content-panel'>
        <Table data={fmaps} loading={!loaded} autoHeight>
          <Column flexGrow={1}>
            <HeaderCell>{t('name')}</HeaderCell>
            <Cell>
              {(fmap: FloorMap | any) => (
                <Link
                  to={path.join(window.location.pathname, fmap.id.toString())}>
                  {fmap.name}
                </Link>
              )}
            </Cell>
          </Column>

          <Column width={80}>
            <HeaderCell>{t('type')}</HeaderCell>
            <Cell dataKey='type' align='center'>
              {(fmap: FloorMap | any) => (
                <Tag>{fmap.type.toLocaleUpperCase() || 'IMAGE'}</Tag>
              )}
            </Cell>
          </Column>

          <Column flexGrow={2}>
            <HeaderCell>{t('desc')}</HeaderCell>
            <Cell dataKey='desc' />
          </Column>

          <Column fixed='right' align='right' width={100}>
            <HeaderCell> </HeaderCell>
            <Cell className='actions-cell'>
              {(fmap: FloorMap | any) => (
                <ButtonToolbar>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>{t('edit')}</Tooltip>}>
                    <Link
                      to={path.join(
                        window.location.pathname,
                        fmap.id.toString(),
                        'edit'
                      )}>
                      <IconButton appearance='subtle' icon={<FaPen />} />
                    </Link>
                  </Whisper>
                  <Checkbox
                    checked={toDelete[fmap.id] !== undefined || false}
                    onChange={() => this.handleDelete(fmap)}
                  />
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </Table>
        <Footer className='footer-buttons'>
          <Link to={path.join(window.location.pathname, 'new/edit')}>
            <Button appearance='primary'>{t('floormaps.add')}</Button>
          </Link>
        </Footer>
      </Panel>
    )
  }
}

export default withTranslation()(FloorMaps)

import React, { RefObject } from 'react'
import ResizeDetector from 'react-resize-detector'

import { Canvas } from 'components/cameras'
import { Event, FloorMap, FloorMapObject, Item, Point, Region } from 'rtmip'
import CanvasImage from './canvasimage'
import CanvasMap from './canvasmap'
import {
  OBJ_CAMERA,
  TYPE_IMAGE,
  TYPE_MAP,
  bboxPoints,
  dist,
  lerpPoints,
  lineIntersectPoints,
  pointPosition,
  pointsCenter,
  settings,
} from './utils'

interface Props extends CanvasProps {
  subref?: RefObject<CanvasMap | CanvasImage>
  onDrawFOVOnMap?: (points: Point[]) => void
}

export interface CanvasProps {
  fmap: FloorMap
  selected?: FloorMapObject

  onSelect?: (o?: FloorMapObject) => void
  onObjectDoubleClick?: (o: FloorMapObject) => void
  onDrawFOVItems?: (cam_id: number, items: Item[], fov?: Point[]) => void

  events?: Event[]
  editable?: boolean
  showArea?: boolean

  size?: {
    w?: number
    h?: number
  }
}

interface State {
  camerasItems: Record<number, Item[]>
  camerasFov: Record<number, Point[]>
  size: { w: number; h: number }
}

export default class FloorMapCanvas extends React.Component<Props, State> {
  state = {
    camerasItems: {},
    camerasFov: {},
    size: { w: 0, h: 0 },
  } as State

  //
  // handlers
  //

  handleItems = (cam_id: number, items: Item[], fov?: Point[]) => {
    const { camerasItems, camerasFov } = this.state
    camerasItems[cam_id] = items
    if (fov) camerasFov[cam_id] = fov

    this.setState({})
  }

  handleCanvasSize = (w?: number, h?: number) => {
    if (!w || !h) return
    this.setState({ size: { w, h } })
  }

  //
  // data
  //

  getRegions = () => {
    const { fmap, selected, events } = this.props
    const { camerasItems, camerasFov } = this.state

    const edit = fmap.objects?.some((o) => o.edit)

    const cams = {} as Record<number, { points: Point[]; o: FloorMapObject }>
    const regions = [] as Region[]
    fmap.objects?.forEach((o) => {
      if (o.type !== OBJ_CAMERA) return

      let opacity = 0.8
      if (edit && selected && selected.id !== o.id) {
        opacity = 0.4
      }

      const points = camerasFov[o.id] || this.getCameraPoints(o)
      if (points) {
        cams[o.id] = { points, o }
        if (settings.getCamerasView()) {
          regions.push({
            id: o.id.toString(),
            color: [104, 181, 97, opacity],
            points,
          } as Region)
        }
      }

      if (selected && selected.edit && o.id === selected.id) {
        regions.push({
          id: `${o.id}_selected`,
          points: o.fov_map,
          color: [104, 97, 181, 1],
          edit: selected.edit,
        } as Region)
      }
    })

    const items = [] as Region[]

    Object.entries(camerasItems).forEach(([cam, camItems]) => {
      const area = cams[cam]
      if (!area) return

      camItems.forEach((item) => {
        items.push({
          id: `item${cam}${item.type}${item.id}`,
          name: item.type,
          color: this.itemColor(item),
          points:
            item.data?.points ||
            this.itemPoints(item.bbox, area.points, area.o),
          bbox: item.bbox,
        } as Region)
      })
    })

    events?.forEach((e) => {
      const cam = e.camera_id
      const area = cams[e.camera_id]
      if (!area) return

      e.items?.forEach((item, i) => {
        items.push({
          id: `item${cam}${item.type}${item.id}_${i}_${e.id}`,
          name: item.name || item.type,
          color: this.itemColor(item),
          points:
            item.data?.points ||
            this.itemPoints(item.bbox, area.points, area.o),
          bbox: item.bbox,
        } as Region)
      })
    })

    return { regions, items }
  }

  getCameraPoints(o: FloorMapObject) {
    if (o.fov_map?.length !== 4) return null
    if (o.fov_cam?.length !== 4) return o.fov_map

    // frame corners
    const frame = [
      [0, 0],
      [1, 0],
      [1, 1],
      [0, 1],
    ] as Point[]

    const cc = pointsCenter(o.fov_cam)
    const mc = pointsCenter(o.fov_map)

    const points = [] as Point[]
    for (let i = 0; i < frame.length; i++) {
      const cp = lineIntersectPoints(o.fov_cam, cc, frame[i])
      if (!cp) return null

      const k1 =
        dist(o.fov_cam[cp.i], cp.c) / dist(o.fov_cam[cp.i], o.fov_cam[cp.j])
      const k2 = dist(cc, frame[i]) / cp.d

      let p = lerpPoints(o.fov_map[cp.i], o.fov_map[cp.j], k1)
      p = lerpPoints(mc, p, k2)
      points.push(p)
    }

    return points
  }

  itemPoints = (bbox: number[], area: Point[], o: FloorMapObject): Point[] => {
    if (
      o.fov_cam &&
      o.fov_map &&
      o.fov_cam?.length >= 4 &&
      o.fov_map?.length >= 4
    ) {
      const cc = pointsCenter(o.fov_cam)
      const mc = pointsCenter(o.fov_map)

      const item = bboxPoints(bbox)

      const points = [] as Point[]
      for (let i = 0; i < item.length; i++) {
        const p = item[i]
        const d = dist(cc, p)

        const pc = lineIntersectPoints(o.fov_cam, cc, lerpPoints(cc, p, 2 / d))
        if (!pc) return points

        const pd =
          dist(o.fov_cam[pc.i], pc.c) / dist(o.fov_cam[pc.i], o.fov_cam[pc.j])

        let pm = lerpPoints(o.fov_map[pc.i], o.fov_map[pc.j], pd)
        pm = lerpPoints(mc, pm, d / pc.d)

        points.push(pm)
      }

      return points
    }

    return [
      pointPosition([bbox[0], bbox[1]], area),
      pointPosition([bbox[0] + bbox[2], bbox[1]], area),
      pointPosition([bbox[0] + bbox[2], bbox[1] + bbox[3]], area),
      pointPosition([bbox[0], bbox[1] + bbox[3]], area),
    ]
  }

  itemColor = (item: Item): number[] => {
    if (item.state === 'pass') return [88, 177, 91, 0.9]
    if (item.state === 'fail') return [240, 79, 64, 0.9]
    return [255, 255, 255, 0.9]
  }

  //
  // render
  //

  render() {
    const { fmap, editable, onDrawFOVOnMap } = this.props
    const { size } = this.state

    return (
      <div className='floormaps-container' data-interaction={editable}>
        <ResizeDetector
          onResize={this.handleCanvasSize}
          skipOnMount
          handleWidth
          handleHeight>
          {this.renderMap()}
        </ResizeDetector>

        {fmap.type === TYPE_IMAGE && size.w && size.h && (
          <Canvas
            width={size.w}
            height={size.h}
            onDraw={onDrawFOVOnMap}
            showLabels
            {...this.getRegions()}
          />
        )}
      </div>
    )
  }

  renderMap() {
    const { subref, ...props } = this.props

    if (props.fmap.type === TYPE_MAP)
      return <CanvasMap ref={subref as RefObject<CanvasMap>} {...props} />

    return (
      <CanvasImage
        ref={subref as RefObject<CanvasImage>}
        {...props}
        showArea={settings.getCamerasView()}
        onDrawFOVItems={this.handleItems}
      />
    )
  }
}

import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button, Checkbox, Dropdown, Modal } from 'rsuite'

import { alert, success } from 'content'
import RTMIP from 'rtmip'

interface Props extends WithTranslation {}

interface State {
  showModal: boolean
  confirm: boolean
}

class DeleteImages extends React.Component<Props, State> {
  state = {
    showModal: false,
    confirm: false,
  } as State

  handleToggleModal = (show: boolean) => {
    this.setState({ showModal: show })
  }

  handleConfirm = () => {
    this.setState({ confirm: !this.state.confirm })
  }

  handleDeleteImages = () => {
    RTMIP.deleteAllProfilesPhotos()
      .then(() => {
        success()
        this.handleToggleModal(false)
      })
      .catch(alert)
  }

  render() {
    const { t } = this.props

    return (
      <div>
        {this.renderModal()}
        <Dropdown.Item onClick={() => this.handleToggleModal(true)}>
          <p>{t('profiles.delete_images')}</p>
          <span className='dropdown-help'>
            {t('profiles.help.delete_images')}
          </span>
        </Dropdown.Item>
      </div>
    )
  }

  renderModal() {
    const { t } = this.props
    const { showModal, confirm } = this.state
    if (!showModal) return null

    return (
      <Modal open={true} onClose={() => this.handleToggleModal(false)}>
        <Modal.Header>
          <Modal.Title>{t('profiles.delete_images')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Checkbox checked={confirm} onChange={this.handleConfirm}>
            {t('profiles.delete_images_confirm')}
          </Checkbox>
          <div
            className='dropdown-help'
            style={{ margin: 10, fontSize: '90%' }}>
            {t('profiles.help.delete_images')}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.handleToggleModal(false)}>
            {t('cancel')}
          </Button>
          <Button
            onClick={this.handleDeleteImages}
            appearance='primary'
            disabled={!confirm}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withTranslation()(DeleteImages)

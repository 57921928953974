import { Bounds, Draggable, Map, Overlay, Point } from 'pigeon-maps'
import React from 'react'

import { FloorMapObject } from 'rtmip'
import { CanvasProps } from './floormapcanvas'
import ObjectCamera from './objectcamera'

interface Props extends CanvasProps {}

interface State {
  selected?: FloorMapObject
}

interface IMapMove {
  center: [number, number]
  zoom: number
  bounds: Bounds
  initial: boolean
}

export default class CanvasMap extends React.Component<Props> {
  state = {} as State

  constructor(props: Props) {
    super(props)

    // init default values
    let { position, zoom } = props.fmap.map_props
    if (!position.reduce((a, b) => a + b)) {
      props.fmap.map_props.position = [47.5, 17.2]
    }

    if (!zoom) props.fmap.map_props.zoom = 4
  }

  //
  // public
  //

  public getNewObjectPosition(): [number, number] {
    return this.props.fmap.map_props.position
  }

  //
  // handlers
  //

  handleMapMove = (e: IMapMove) => {
    const { fmap } = this.props
    fmap.map_props.position = e.center
    fmap.map_props.zoom = e.zoom
    this.setState({})
  }

  handleSelect = (o?: FloorMapObject) => {
    const { onSelect } = this.props
    if (onSelect) onSelect(o)

    this.setState({ selected: o })
  }

  handleDrag = (o: FloorMapObject, p: Point) => {
    o.position = p
    this.setState({})
  }

  handleDoubleClick = (o: FloorMapObject) => {
    const { onObjectDoubleClick } = this.props
    if (onObjectDoubleClick) onObjectDoubleClick(o)
  }

  //
  // render
  //

  render() {
    const { fmap } = this.props

    let { position, zoom } = fmap.map_props

    return (
      <div className='floormaps-map'>
        <Map
          height={450}
          center={position}
          zoom={zoom}
          onBoundsChanged={this.handleMapMove}
          onClick={() => this.handleSelect()}>
          {fmap.objects && fmap.objects.map(this.renderObjectContainer)}
        </Map>
      </div>
    )
  }

  renderObjectContainer = (o: FloorMapObject) => {
    const { selected } = this.state

    if (selected === o) {
      return (
        <Draggable
          anchor={o.position}
          key={o.type + o.id}
          onDragEnd={(p) => this.handleDrag(o, p)}>
          {this.renderObject(o)}
        </Draggable>
      )
    }

    return (
      <Overlay anchor={o.position} key={o.type + o.id}>
        {this.renderObject(o)}
      </Overlay>
    )
  }

  renderObject = (o: FloorMapObject) => {
    const { fmap } = this.props
    const { selected } = this.state

    const countId = `${o.type}_${o.id}_count`

    return (
      <div
        key={o.type + o.id}
        className='floormaps-map-object'
        onClick={() => this.handleSelect(o)}
        onDoubleClick={() => this.handleDoubleClick(o)}>
        <ObjectCamera
          fmapid={fmap.id}
          object={o}
          selected={selected === o}
          countTarget={countId}
          showArea={false}
          showItems={false}
          // items={this.getEventsItems(o.id)}
          // container='.floormaps-image'
        />
        {(selected === o || fmap.map_props.zoom > 14) && (
          <span
            key={o.type + o.id + 'title'}
            className='floormaps-object_title'
            style={{ top: 5, left: 5 }}>
            {o.name}
          </span>
        )}
        <span
          key={o.type + o.id + 'count'}
          className='floormaps-object_count'
          id={countId}
          style={{ top: 0, left: 0 }}
        />
      </div>
    )
  }
}

const ROUTES = {
  dashboard: '/',
  dashboard_id: '/dashboards/:id',
  livestream: '/livestream',
  livestream_sector: '/livestream/:sector',
  eventsarchive: '/eventsarchive',
  videoarchive: '/videoarchive',

  charts: '/charts',
  charts_id: '/charts/:id',

  floormaps: '/maps',
  floormaps_id: '/maps/:id',
  floormaps_id_edit: '/maps/:id/edit',

  reports: '/reports', // equal 'eventsarchive' exist to compatible with embedded frontend, used for permissions only
  archive: '/archive', // equal 'videoarchive' exist to compatible with embedded frontend, used for permissions only
  profiles: {
    groups: '/profiles/groups',
    groups_id: '/profiles/groups/:id',
    people: '/profiles/people',
    people_id: '/profiles/people/:id',
    cars: '/profiles/cars',
    cars_id: '/profiles/cars/:id',
  },
  settings: {
    cameras: '/settings/cameras',
    cameras_id: '/settings/cameras/:id',

    analytics: '/settings/analytics',
    // schemes: '/settings/schemes', // for permissions for frontend only
    analytics_id: '/settings/analytics/:id',

    notifications: '/settings/notifications',
    notifications_id: '/settings/notifications/:id',

    detectors: '/settings/detectors',
    detectors_id: '/settings/detectors/:id',

    devices: '/settings/devices',
    devices_id: '/settings/devices/:id',

    scripts: '/settings/scripts',
    scripts_id: '/settings/scripts/:id',

    exceptions: '/settings/exceptions',

    users: '/settings/users',
    users_id: '/settings/users/:id',

    themes: '/settings/themes',
    license: '/settings/license',

    packages: '/settings/packages',
    packages_name: '/settings/packages/:name',

    plugins: '/settings/plugins',

    journal: '/settings/journal',
    journal_section: '/settings/journal/:section',
  },

  pages: '/pages',
  pages_name: '/pages/:name',

  status: '/status',
  status_section: '/status/:section',
  health: '/health',

  terminal: '/terminal',

  changelog: '/changelog',
} as Record<string, any>

export default ROUTES

export function lookupRoute(path: string) {
  if (path === 'profiles') return ROUTES.profiles.people
  if (path === 'schemes') return ROUTES.settings.analytics

  let route = ROUTES[path]
  if (route) return route

  route = ROUTES.settings[path]
  if (route) return route

  route = ROUTES.profiles[path]
  if (route) return route

  return undefined
}

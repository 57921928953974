import { Icon } from '@rsuite/icons'
import i18n from 'i18next'
import React from 'react'
import { FaCalendarAlt, FaRegClock, FaTimes } from 'react-icons/fa'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Checkbox,
  DateRangePicker,
  IconButton,
  Input,
  InputGroup,
  Popover,
  Whisper,
} from 'rsuite'

interface Props {
  value: [Date, Date] | null
  onChange: (v: any) => void
  disabledDate?: (v: Date) => boolean
  placement?: string
}

interface State {
  value: [Date, Date] | null
  // open: boolean
}

export default class Calendar extends React.Component<Props, State> {
  state = {} as State

  componentDidUpdate() {
    const stateDate = this.state.value
    const propsDate = this.props.value

    if (stateDate == null && propsDate !== null) {
      this.setState({ value: propsDate })
      return
    }

    if (
      stateDate![0].toString() !== propsDate![0].toString() ||
      stateDate![1].toString() !== propsDate![1].toString()
    ) {
      this.setState({ value: propsDate })
    }
  }

  render() {
    return (
      <InputGroup className='content-calendar' inside>
        <InputGroup.Addon>
          <FaCalendarAlt />
        </InputGroup.Addon>
        <DateRangePicker
          placement={this.props.placement as any}
          onChange={this.props.onChange}
          // onOpen={() => this.setState({ open: true })}
          // onClose={() => this.setState({ open: false })}
          // defaultValue={this.props.value}
          value={this.state.value}
          shouldDisableDate={this.props.disabledDate}
          cleanable={false}
          locale={getLocale()}
          isoWeek={i18n.language == 'ru'}
          format='dd.MM.yyyy'
          showOneCalendar
          block
        />
      </InputGroup>
    )
  }
}

//
// TimeRange
//

interface TimeRangeProps {
  at: string | undefined
  to: string | undefined

  onChange?: (at?: string, to?: string, everyday?: boolean) => void
}

interface TimeRangeState {
  at?: string
  to?: string

  h1: string
  m1: string
  h2: string
  m2: string

  everyday?: boolean
}

export class TimeRange extends React.Component<TimeRangeProps, TimeRangeState> {
  state = {
    h1: '00',
    m1: '00',
    h2: '24',
    m2: '00',
  } as TimeRangeState

  whisper = React.createRef<any>()

  componentDidMount() {
    this.parseProps(this.props)
  }

  parseProps(props: TimeRangeProps) {
    const at = props.at
    const to = props.to

    if (!at || !to) return

    let hm1 = at.split(':')
    let hm2 = to.split(':')

    this.setState({
      at: at,
      to: to,
      h1: hm1[0] || '00',
      m1: hm1[1] || '00',
      h2: hm2[0] || '24',
      m2: hm2[1] || '00',
    })
  }

  handleTimeRange = (val: string, key: string) => {
    val = this.fixValue(val)

    const n = parseInt(val)

    if (key === 'h1' && n > 23) val = '23'
    if (key === 'h2' && n > 24) val = '24'

    if (n < 0) val = ''
    if (n > 59) val = '59'

    Object.assign(this.state, { [key]: val })

    let { h1, m1, h2, m2 } = this.state

    this.setState({ at: `${h1}:${m1}`, to: `${h2}:${m2}` })
  }

  fixValue(val: string): string {
    if (!val) val = '00'
    else if (val.length < 2) val = '0' + val
    else if (val.length > 2) val = val.substr(val.length - 2, 2)

    return val
  }

  handleApply = () => {
    const { onChange } = this.props
    const { at, to, everyday } = this.state

    this.whisper.current?.close()

    if (onChange) onChange(at, to, everyday)
  }

  handleCancel = () => {
    const { onChange } = this.props
    this.whisper.current?.close()
    if (onChange) onChange()

    this.setState({ at: undefined, to: undefined })
  }

  isHasValue = (): boolean => {
    return this.state.at !== undefined && this.state.to !== undefined
  }

  //
  // render
  //

  render() {
    const { at, to, h1, m1, h2, m2, everyday } = this.state

    return (
      <Whisper
        ref={this.whisper}
        speaker={
          <Popover className='time-picker'>
            <ButtonGroup block>
              <InputGroup>
                <Input
                  value={h1}
                  onChange={(v) => this.handleTimeRange(v, 'h1')}
                  onPressEnter={this.handleApply}
                />
                <InputGroup.Addon>:</InputGroup.Addon>
                <Input
                  value={m1}
                  onChange={(v) => this.handleTimeRange(v, 'm1')}
                  onPressEnter={this.handleApply}
                />
              </InputGroup>
              {` - `}
              <InputGroup>
                <Input
                  value={h2}
                  onChange={(v) => this.handleTimeRange(v, 'h2')}
                  onPressEnter={this.handleApply}
                />
                <InputGroup.Addon>:</InputGroup.Addon>
                <Input
                  value={m2}
                  onChange={(v) => this.handleTimeRange(v, 'm2')}
                  onPressEnter={this.handleApply}
                />
              </InputGroup>
            </ButtonGroup>

            <div>
              <Checkbox
                checked={everyday}
                onChange={(v) => this.setState({ everyday: !everyday })}>
                {i18n.t('reports.time_everyday')}
              </Checkbox>
            </div>

            <ButtonToolbar block justifyContent='flex-end'>
              <IconButton
                icon={<Icon as={FaTimes} />}
                onClick={this.handleCancel}
              />

              <Button appearance='primary' onClick={this.handleApply}>
                OK
              </Button>
            </ButtonToolbar>
          </Popover>
        }
        placement='bottom'
        trigger='click'
        enterable>
        {this.isHasValue() ? (
          <Button className='circle-btn'>
            <Icon as={FaRegClock} />
            {at} - {to}
          </Button>
        ) : (
          <IconButton className='circle-btn' icon={<Icon as={FaRegClock} />} />
        )}
      </Whisper>
    )
  }
}

//
// locale
//

export function getLocale() {
  return locales[i18n.language]
}

const locales = {
  ru: {
    sunday: 'Вс',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    ok: 'OK',
    today: 'Сегодня',
    yesterday: 'Вчера',
    last7Days: 'Неделя',
    hours: 'Часов',
    minutes: 'Минут',
    seconds: 'Секунд',
  },
  es: {
    sunday: 'Dmg',
    monday: 'Lns',
    tuesday: 'Mrt',
    wednesday: 'Mrl',
    thursday: 'Jv',
    friday: 'Vrs',
    saturday: 'San',
    ok: 'OK',
    today: 'Hoy',
    yesterday: 'Ayer',
    last7Days: 'Semana',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  },
} as Record<string, any>

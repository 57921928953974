import humanizeDuration from 'humanize-duration'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaEnvelope, FaPen } from 'react-icons/fa'
import {
  Badge,
  Button,
  ButtonToolbar,
  Col,
  Divider,
  FlexboxGrid,
  Form,
  Grid,
  IconButton,
  Input,
  InputPicker,
  MaskedInput,
  Modal,
  Row,
  Table,
  Tag,
  TagPicker,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite'

import {
  DelButton,
  DurationPicker,
  Spinner,
  alert,
  hasErrors,
  success,
} from 'content'
import PageTable from 'pagetable'
import RTMIP, {
  Notification,
  ReportsFilters,
  ReportsSending,
  ShortModel,
} from 'rtmip'

interface Props extends WithTranslation {
  filter: {
    names: ReportsFilters
    value: Record<string, any>
  }
}

interface State {
  list?: ReportsSending[] | undefined
  form?: ReportsSending
  reports?: Record<string, any>[]
  ntfs?: Notification[]

  show: {
    list: boolean
    form: boolean
  }
}

class ReportsSendings extends React.Component<Props, State> {
  state = {
    show: {
      list: false,
      form: false,
    },
    form: {} as ReportsSending,
  } as State

  componentDidMount() {
    this.loadList()
    this.loadExportNames()
    this.loadNotifications()
  }

  loadList = () => {
    RTMIP.reportsSendings()
      .then((list) => this.setState({ list }))
      .catch(alert)
  }

  loadExportNames = () => {
    RTMIP.reportsNames()
      .then((names: string[]) => {
        const reports = names.map((s) => ({
          value: s,
          label: s.toLocaleUpperCase(),
        }))

        this.setState({ reports })
      })
      .catch(alert)
  }

  loadNotifications = () => {
    RTMIP.notifications()
      .then((ntfs) => this.setState({ ntfs }))
      .catch(alert)
  }

  toggleWindow = () => {
    const show = this.state.show
    show.list = !show.list
    this.setState({ show, list: undefined })

    // reload list on open modal window
    if (show.list) this.loadList()
  }

  //
  // handlers
  //

  toggleReportSending = (rs: ReportsSending, v: any) => {
    rs.enabled = !rs.enabled
    this.setState({})

    RTMIP.changeReportsSending(rs.id, { enabled: rs.enabled } as ReportsSending)
      .then((resp) => {
        Object.assign(rs, resp)
        this.setState({})
      })
      .catch(alert)
  }

  handleForm = (p: Record<string, any>) => {
    const form = Object.assign(this.state.form as any, p)

    this.setState({ form })
  }

  handleApply = () => {
    const form = this.state.form as ReportsSending
    if (!form.name) return alert('Name required')
    if (!form.report) return alert('Required to select a report')
    if (!form.notify)
      return alert('Required to select at least one notification')

    let resp: Promise<ReportsSending>

    if (!form.id) {
      form.enabled = true
      form.filter = this.props.filter.value as ReportsFilters

      resp = RTMIP.createReportsSending(form)
    } else {
      resp = RTMIP.changeReportsSending(form.id, form)
    }

    resp
      .then((rs) => {
        success()
        this.loadList()
      })
      .catch(alert)
  }

  handleDelete = (rs: ReportsSending) => {
    RTMIP.deleteReportsSending(rs.id)
      .then(() => this.loadList())
      .catch(alert)
  }

  handleCheck = (rs: ReportsSending) => {
    RTMIP.checkReportsSending(rs.id)
      .then(() => success())
      .catch(alert)
  }

  handleChangeClock = (rs: ReportsSending | undefined, v: any) => {
    if (!rs || !v) return
    let [h, m] = v.split(':')

    if (!h.includes('_')) {
      h = parseInt(h)
      if (h > 23) h = 23
    }
    if (!m.includes('_')) {
      m = parseInt(m)
      if (m > 59) m = 59
    }

    rs.clock = `${h.toString().padStart(2, '0')}:${m
      .toString()
      .padStart(2, '0')}`

    this.setState({})
  }

  //
  // data
  //

  getApplyDisabled = (): boolean => {
    const { form } = this.state
    if (!form) return true

    return !form.name || !form.notify || !form.report
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { show, list, form } = this.state

    return (
      <div>
        <Whisper
          key='togglebtn'
          speaker={<Tooltip>{t('eventsarchive.reports_sending')}</Tooltip>}
          placement='bottom'>
          <div>
            <Badge content={list?.length || false}>
              <IconButton
                icon={<FaEnvelope />}
                onClick={this.toggleWindow}
                circle
              />
            </Badge>
          </div>
        </Whisper>
        {show.list && (
          <Modal
            key='reports-window-list'
            open={true}
            onClose={() => this.toggleWindow()}
            size='md'>
            <Modal.Header>
              <Modal.Title>{t('eventsarchive.reports_sending')}</Modal.Title>
            </Modal.Header>

            {this.renderList()}
            <Divider />
            <Modal.Footer>
              <Button onClick={this.toggleWindow}>{t('close')}</Button>
              <Button
                onClick={this.handleApply}
                appearance='primary'
                disabled={this.getApplyDisabled()}>
                {t(form?.id ? 'save' : 'create')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    )
  }

  renderList() {
    const { t, i18n } = this.props
    const { show, list, form, reports, ntfs } = this.state

    if (show.list && list === undefined) {
      return <Spinner small center />
    }

    const { Column, HeaderCell, Cell } = Table

    return (
      <div style={{ position: 'relative', marginTop: 15 }}>
        {list && (
          <div
            style={{
              position: 'relative',
            }}>
            <PageTable
              data={list}
              total={list.length}
              sort={{ col: 'name', type: 'asc' }}
              limit={10}>
              <Column width={60}>
                <HeaderCell> </HeaderCell>
                <Cell dataKey='enabled'>
                  {(rs: ReportsSending | any) => (
                    <Toggle
                      key={`toggle_${rs.id}`}
                      checked={rs.enabled}
                      data-errors={hasErrors(rs.errors)}
                      onChange={(v) => this.toggleReportSending(rs, v)}
                    />
                  )}
                </Cell>
              </Column>

              <Column flexGrow={2}>
                <HeaderCell>{t('name')}</HeaderCell>
                <Cell dataKey='name'></Cell>
              </Column>

              <Column width={60} align='center'>
                <HeaderCell>{t('eventsarchive.time')}</HeaderCell>
                <Cell dataKey='clock'></Cell>
              </Column>

              <Column width={60} align='center'>
                <HeaderCell>{t('charts.duration')}</HeaderCell>
                <Cell dataKey='duration'>
                  {(rs: ReportsSending | any) => {
                    const opt = {
                      largest: 2,
                      language: i18n.language,
                    }
                    return (
                      <div>
                        <Tag>
                          {humanizeDuration(rs.report_duration * 1000, opt)}
                        </Tag>
                        {rs.report_offset > 0 && (
                          <Tag>
                            {humanizeDuration(rs.report_offset * 1000, opt)}
                          </Tag>
                        )}
                      </div>
                    )
                  }}
                </Cell>
              </Column>

              <Column flexGrow={2} align='center'>
                <HeaderCell>{t('eventsarchive.report_name')}</HeaderCell>
                <Cell dataKey='report'></Cell>
              </Column>

              <Column flexGrow={1} align='center'>
                <HeaderCell>{t('notifications.title')}</HeaderCell>
                <Cell dataKey='notify'>
                  {(rs: ReportsSending | any) => {
                    return rs.notify.map((id, i) => {
                      const ntf = ntfs?.find((ntf) => ntf.id === id)
                      return <Tag key={i}>{ntf?.name || id}</Tag>
                    })
                  }}
                </Cell>
              </Column>

              <Column fixed='right' width={138}>
                <HeaderCell> </HeaderCell>
                <Cell className='actions-cell'>
                  {(rs: ReportsSending | any) => (
                    <ButtonToolbar>
                      {rs.enabled && (
                        <IconButton
                          appearance='subtle'
                          icon={<FaEnvelope />}
                          onClick={() => this.handleCheck(rs)}
                        />
                      )}
                      <IconButton
                        appearance='subtle'
                        icon={<FaPen />}
                        onClick={() => this.handleForm(rs)}
                      />

                      <DelButton onConfirm={() => this.handleDelete(rs)} />
                    </ButtonToolbar>
                  )}
                </Cell>
              </Column>
            </PageTable>
            <Divider />
          </div>
        )}

        <Form fluid>
          <Grid fluid>
            <Row gutter={15}>
              <Col md={24}>
                <Form.Group>
                  <Form.ControlLabel>{t('name')}*</Form.ControlLabel>
                  <Input
                    name='name'
                    value={form?.name || ''}
                    onChange={(v) => this.handleForm({ name: v })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col md={24}>
                <Form.Group>
                  <Form.ControlLabel>{t('desc')}</Form.ControlLabel>
                  <Input
                    name='desc'
                    value={form?.desc || ''}
                    onChange={(v) => this.handleForm({ desc: v })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col md={12}>
                <Form.Group>
                  <Form.ControlLabel>
                    {t('eventsarchive.report_name')}*
                  </Form.ControlLabel>
                  <InputPicker
                    data={reports || []}
                    value={form?.report}
                    onChange={(v) => this.handleForm({ report: v })}
                    cleanable={false}
                    block
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.ControlLabel>
                    {t('notifications.title')}*
                  </Form.ControlLabel>
                  <TagPicker
                    data={ntfs || []}
                    labelKey='name'
                    valueKey='id'
                    value={form?.notify}
                    onChange={(v) => this.handleForm({ notify: v })}
                    cleanable={false}
                    block
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col md={8}>
                <Form.Group>
                  <Form.ControlLabel>
                    {t('eventsarchive.time')}
                  </Form.ControlLabel>
                  <MaskedInput
                    style={{ textAlign: 'center' }}
                    value={form?.clock || ''}
                    onChange={(v) => this.handleChangeClock(form, v)}
                    mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                    placeholder='08:00'
                  />
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.ControlLabel>
                    {t('eventsarchive.report_duration')}
                  </Form.ControlLabel>
                  <DurationPicker
                    value={form?.report_duration || 86400}
                    min={3600}
                    onChange={(v) => this.handleForm({ report_duration: v })}
                    days
                    hours
                  />
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.ControlLabel>
                    {t('eventsarchive.report_offset')}
                  </Form.ControlLabel>
                  <DurationPicker
                    value={form?.report_offset || 0}
                    min={0}
                    onChange={(v) => this.handleForm({ report_offset: v })}
                    days
                    hours
                    minutes
                  />
                </Form.Group>
              </Col>
            </Row>
            {this.renderFilter()}
          </Grid>
        </Form>
      </div>
    )
  }

  renderFilter() {
    const { t, filter } = this.props

    const v = filter.value
    const n = filter.names

    if (
      !v.cameras &&
      !v.sectors &&
      !v.analytics &&
      !v.profiles &&
      !v.cars &&
      !v.groups &&
      !v.users
    )
      return null

    return (
      <Row gutter={15}>
        <Col md={24}>
          <Form.ControlLabel>{t('analytics.filter')}</Form.ControlLabel>
          <div className='eventsarchive-backpanel'>
            {this.renderFilterRow(v.cameras, n.cameras, 'eventsarchive.camera')}
            {this.renderFilterRow(
              v.sectors,
              n.sectors as ShortModel[],
              'eventsarchive.sector'
            )}
            {this.renderFilterRow(
              v.analytics,
              n.schemes,
              'eventsarchive.analytics'
            )}
            {this.renderFilterRow(v.groups, n.groups, 'eventsarchive.groups')}
            {this.renderFilterRow(
              v.profiles,
              n.profiles,
              'eventsarchive.profiles'
            )}
            {this.renderFilterRow(v.users, n.users, 'eventsarchive.users')}
          </div>
        </Col>
      </Row>
    )
  }

  renderFilterRow(value: any, names: ShortModel[], titlekey: string) {
    const { t } = this.props
    if (!value) return null

    if (!Array.isArray(value)) value = [value]

    return (
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={6}>{t(titlekey)}</FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={18}>
          <div>
            {value.map((v: any, i: number) => {
              let name = names.find((n: any) => n.id === v || n.name === v)
              return <Tag key={i}>{name?.name || v}</Tag>
            })}
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    )
  }
}

export default withTranslation()(ReportsSendings)

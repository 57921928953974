import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button, Header, Input, Message, Panel, Footer, Stack } from 'rsuite'
import moment from 'moment-timezone'

import RTMIP, { License } from 'rtmip'
import Content, {
  CenterRow,
  ContentState,
  HeaderTitle,
  setTitle,
  alert,
  success,
} from 'content'

import './license.less'

interface detector {
  name: string
  usage: number
  limit: number
}

interface Props extends WithTranslation {}
interface State extends ContentState {
  serial: string
  license: License
  detectors: detector[]
}

export class LicenseForm extends React.Component<Props, State> {
  state = {
    license: {} as License,
    detectors: [] as detector[],
  } as State

  componentDidMount() {
    setTitle('license.title')

    RTMIP.license().then(this.setLicense).catch(this.setError)
  }

  setLicense = (license: License) => {
    const detectors = [] as detector[]

    for (const p in license.permissions) {
      detectors.push({
        name: p,
        limit: license.permissions[p],
        usage: license.usage.detectors[p] || 0,
      })
    }

    this.setState({ license: license || {}, loaded: true, detectors })
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  isDemo(): boolean {
    const { license } = this.state
    return (
      license.cameras === 0 ||
      license.analytics === 0 ||
      license.detectors === 0
    )
  }

  isOutdated(): boolean {
    const { license } = this.state
    return license.time_to > 0 && moment().unix() > license.time_to
  }

  //
  // handlers
  //

  handleSerial = (serial: string) => {
    this.setState({ serial })
  }

  handleActivate = () => {
    const { t } = this.props
    const { serial } = this.state

    if (!serial || !serial.length) return

    RTMIP.activateLicense(serial)
      .then((license: License) => {
        success(t('license.success'))
        this.setLicense(license)
      })
      .catch(alert)
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, license, serial, detectors } = this.state

    const demo = this.isDemo()
    const outdated = this.isOutdated()

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <CenterRow>
          {demo && (
            <Message showIcon type='warning'>
              {t('license.demo')}
            </Message>
          )}
          {outdated && (
            <Message showIcon type='warning'>
              {t('license.outdated')}
            </Message>
          )}

          {(demo || outdated) && <br />}

          <Panel header={t('license.info')}>
            <div className='license-table'>
              {license.time_at > 0 && [
                <div key='time_at'>{t('license.time_at')}</div>,
                <div key='time_at_value'>
                  {moment.unix(license.time_at).format('D MMM YYYY')}
                </div>,
              ]}

              {license.time_to > 0 && [
                <div key='time_to'>{t('license.time_to')}</div>,
                <div key='time_to_value'>
                  {moment.unix(license.time_to).format('D MMM YYYY')}
                </div>,
              ]}

              <div>{t('cameras.title')}:</div>
              <div>
                {license.usage?.cameras || 0} / {license.cameras || 1}
              </div>

              {/* <div>{t('analytics.title')}:</div>
              <div>{license.analytics || 1}</div>

              <div>{t('detectors.title')}:</div>
              <div>{license.detectors || 1}</div> */}
              {detectors.map((d) => {
                return [
                  <div key={`${d.name}_key`}>
                    {t('analytics.detector')} "{d.name}":
                  </div>,
                  <div key={`${d.name}_value`}>
                    {d.usage} / {d.limit}
                  </div>,
                ]
              })}
            </div>
          </Panel>

          <Panel header={t('license.activation')}>
            <Input placeholder='serial number' onChange={this.handleSerial} />

            <Stack className='footer' justifyContent='center'>
              <Button
                appearance='primary'
                onClick={this.handleActivate}
                disabled={!serial || !serial.length}>
                {t('license.activate')}
              </Button>
            </Stack>
          </Panel>
        </CenterRow>
      </Content>
    )
  }

  renderHeader() {
    const { t } = this.props

    return (
      <Header>
        <HeaderTitle>{t('license.title')}</HeaderTitle>
      </Header>
    )
  }
}

export default withTranslation()(LicenseForm)

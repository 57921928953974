export interface Options {
  onFrame?: (frame: Blob | string | null) => void
  toBlob?: BlobCallback
}

export default function getFrame(
  video: HTMLVideoElement,
  canvas: HTMLCanvasElement,
  opt: Options
) {
  const ctx = canvas.getContext('2d')
  if (!ctx) return

  ctx.drawImage(video, 0, 0)

  if (opt.toBlob) {
    canvas.toBlob(opt.toBlob, 'image/jpeg', 98)
  }

  if (opt.onFrame) {
    const frame = canvas.toDataURL('image/jpeg').split(',')[1]

    if (frame.length) {
      opt.onFrame(frame)
    }
  }
}

import React from 'react'
import Webcam from 'react-webcam'

import getFrame from './getframe'

interface Props {
  className?: string
  onUserMedia?: () => void
}

const opt = {
  facingMode: 'user',
  width: { min: 640, ideal: 1280, max: 1920 },
  height: { min: 480, ideal: 720, max: 1080 },
}

export default class WEBCAM extends React.Component<Props, any> {
  webcam = React.createRef<any>()
  canvas: HTMLCanvasElement = document.createElement('canvas')

  getVideoElement = (): HTMLVideoElement | undefined => {
    return this.webcam.current?.video
  }

  getFrame = (callback: (frame: any) => void) => {
    const video = this.getVideoElement()

    if (video) {
      this.canvas.width = video.videoWidth
      this.canvas.height = video.videoHeight

      getFrame(video, this.canvas, {
        onFrame: callback,
      })
    }
  }

  getBlob = (callback: BlobCallback) => {
    const video = this.getVideoElement()

    if (video) {
      this.canvas.width = video.videoWidth
      this.canvas.height = video.videoHeight

      getFrame(video, this.canvas, {
        toBlob: callback,
      })
    }
  }

  render() {
    return (
      <Webcam
        ref={this.webcam}
        className={this.props.className}
        screenshotFormat='image/jpeg'
        videoConstraints={opt}
        audio={false}
        onUserMedia={this.props.onUserMedia}
        // mirrored
      />
    )
  }
}

import moment from 'moment-timezone'
import React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import {
  Button,
  IconButton,
  Loader,
  Message,
  Panel,
  Popover,
  RangeSlider,
  Slider,
  Tooltip,
  Whisper,
} from 'rsuite'

import { MJPEG, VIDEO } from 'components/players'
import { Spinner, alert, success } from 'content'
import { FaCut, FaPause, FaPlay } from 'react-icons/fa'
import RTMIP, {
  ArchiveCamera,
  ArchiveFile,
  Camera,
  Detected,
  DrawAndCutOpt,
  Event,
  eventToDetected,
} from 'rtmip'
import { addQuery, getQuery } from 'utils/query'
import CameraCanvas from './cameracanvas'
import CameraHeader from './cameraheader'

const PlaybackRates = [10, 5, 2, 1]

interface Props {
  t: (key: string) => string

  camera: ArchiveCamera
  file: ArchiveFile

  cut?: boolean
}

interface State {
  events: Event[]
  camera: ArchiveCamera
  file: ArchiveFile

  src: string
  progress: number
  paused: boolean
  speed: number
  expand: boolean

  analytics: Record<number, Event>
  iter: number
  canvas: { w: number; h: number }

  cutValues: [number, number]
  cutDisabled: boolean
}

export interface FrameEvents {
  name: string
  frame: any
  events: Event[]
}

export default class VideoArchivePlayer extends React.Component<Props, State> {
  state = {
    progress: 0,
    paused: false,
    speed: 1,
    canvas: { w: 0, h: 0 },
    analytics: {} as Record<number, Event>,
    iter: 0,
    cutValues: [0, 60],
  } as State

  video: any = React.createRef<HTMLVideoElement>()
  timeout: number = 0
  interval: number = 0
  setProgressInterval: number = 0

  componentDidMount() {
    const { camera, file } = this.props

    const q = getQuery()
    const progress = parseInt(q.progress) || 0
    const cutValues = [0, file.duration || 60] as [number, number]

    this.setState({ camera, file, progress, cutValues })
    this.buildSrc()

    this.loadEvents()

    if (progress > 0) {
      this.setProgressInterval = window.setInterval(() => {
        if (this.video.setOffset) {
          this.setProgress(progress)
          window.clearInterval(this.setProgressInterval)
        }
      }, 100)
    }

    this.interval = window.setInterval(this.progressMonitor, 1000)
  }

  componentDidUpdate(props: Props) {
    if (props.camera !== this.state.camera || props.file !== this.state.file) {
      this.setState({ camera: props.camera, file: props.file, progress: 0 })
      this.buildSrc()
      this.loadEvents()
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
    window.clearInterval(this.interval)
    window.clearInterval(this.setProgressInterval)
  }

  loadEvents() {
    const { camera, file } = this.props

    const filter = {
      camera_id: camera.id,
      camera: camera.name,
      time_at: file.etime.unix(),
      time_to: file.etime.unix() + file.duration,
    }

    RTMIP.events(filter).then(this.setEvents).catch(alert)
  }

  buildSrc = () => {
    const { camera, file } = this.props
    const timestamp = file.etime.unix() + this.state.progress

    let src = `/archive/${camera.name}/${file.name}`
    if (file.type === 'MJPEG') src += `?timestamp=${timestamp}`

    this.setState({ src })
  }

  setEvents = (events: Event[]) => {
    events.forEach((e) => {
      e.time_at
      e.time_to
    })
    this.setState({ events, iter: events.length - 1 })
  }

  onResize = (w?: number, h?: number) => {
    this.setState({ canvas: { w: w || 0, h: h || 0 } })
  }

  getFrameData = (): Promise<FrameEvents> => {
    const { camera, file } = this.props
    const { progress } = this.state

    const name = `${camera.name}_${moment(file.etime)
      .add(progress, 's')
      .format('YYYY-MM-DD_HH:mm:ss')}`

    return new Promise((resolve) => {
      const events = [] as Event[]
      for (let id in this.state.analytics) {
        events.push(this.state.analytics[id])
      }

      this.video.getFrame((frame: any) => {
        resolve({ name, frame, events })
      })
    })
  }

  progressMonitor = () => {
    let { progress, paused } = this.state

    if (this.video.getOffset) {
      progress = Math.round(this.video.getOffset() / 1000)
      addQuery({ progress }, true)
    }
    if (this.video.isPaused) {
      paused = this.video.isPaused()
    }

    const analytics = this.lookupAnalytics(progress)

    this.setState({ progress, paused, analytics })
  }

  getTimestamp = (): number => {
    return this.state.file.etime.unix() + Math.floor(this.state.progress)
  }

  lookupAnalytics = (progress: number): Record<number, Event> => {
    let { events, iter } = this.state

    const timestamp = this.getTimestamp()
    const analytics = {} as Record<number, Event>

    if (events) {
      for (let i = iter; i >= 0; i--) {
        const e = events[i]

        if (e.time_at === timestamp) {
          analytics[e.analytics_id] = e
          iter = i
          continue
        }

        if (!analytics[e.analytics_id]) {
          if (e.time_at > timestamp && e.time_to < timestamp + 3) {
            analytics[e.analytics_id] = e
          }
        }
      }
    }

    // eslint-disable-next-line
    this.state.iter = iter

    return analytics
  }

  // eventIsOutdated = (event: Event): boolean => {
  //   const timestamp = this.getTimestamp() - 2
  //   return event.time_to > timestamp
  // }

  convertAnalytics = (a: Record<number, Event>): Record<number, Detected> => {
    const resp = {} as Record<number, Detected>

    for (const id in a) {
      resp[id] = eventToDetected(a[id])
    }

    return resp
  }

  setProgress = (progress: number) => {
    if (this.video?.setOffset) this.video.setOffset(progress)
    if (this.state.events)
      this.setState({ progress, iter: this.state.events.length - 1 })

    addQuery({ progress }, true)
  }

  toggleState = () => {
    this.setState({ paused: this.video.playPause() })
  }

  setSpeed = (speed: number) => {
    this.video.setPlaybackRate(speed)
    this.setState({ speed })
  }

  handleCutChanges = (v: [number, number]) => {
    this.setState({ cutValues: v })
  }

  handleCut = () => {
    const { t, camera, file } = this.props
    const { cutValues } = this.state

    const opt = {
      draw: true,
      cut: cutValues,
    } as DrawAndCutOpt

    RTMIP.archiveDrawAndCut(camera.name, file.name, opt)
      .then((p) => {
        success(t('videoarchive.cut_process_started'))
      })
      .catch(alert)

    this.setState({ cutDisabled: true })
    window.setTimeout(() => {
      this.setState({ cutDisabled: false })
    }, 5000)
  }

  //
  //
  //

  render() {
    const { t, cut } = this.props
    const { events, file, progress, paused, speed, expand, cutValues } =
      this.state

    const c = moment.duration(progress, 's')

    return (
      <div className='content-panel videoarchive-camera' data-expand={expand}>
        <div className='camera' style={{ width: expand ? '100%' : 'auto' }}>
          <div className='camera-player-container'>
            <ReactResizeDetector
              handleWidth
              handleHeight
              onResize={this.onResize}>
              <Panel
                style={{ width: expand ? '100%' : 'auto' }}
                bodyFill
                shaded>
                {this.renderHeader()}
                {this.renderPlayer()}
                {this.renderCanvas()}
              </Panel>
            </ReactResizeDetector>

            <Panel bodyFill shaded>
              <div className='archive-video-controls'>
                <IconButton
                  onClick={this.toggleState}
                  icon={paused ? <FaPlay /> : <FaPause />}
                  size='xs'
                />
                <div style={{ position: 'relative' }}>
                  <Slider
                    className='archive-slider'
                    onChange={this.setProgress}
                    min={0}
                    max={file ? file.duration : 3600}
                    value={progress}
                    tooltip={false}
                    handleTitle={
                      <div className='archive-slider-handler'>
                        {this.renderSliderValue(c)}
                      </div>
                    }
                    progress
                  />
                  <div className='archive-events'>
                    {events && events.map(this.renderEvent)}
                  </div>
                </div>
                <Whisper
                  trigger='click'
                  placement='topEnd'
                  speaker={
                    <Popover>
                      {PlaybackRates.map((s: number) => (
                        <Button
                          key={s}
                          appearance={s === speed ? 'primary' : 'subtle'}
                          onClick={() => this.setSpeed(s)}
                          size='xs'
                          block>
                          {s}x
                        </Button>
                      ))}
                    </Popover>
                  }>
                  <Button size='xs'>{speed}x</Button>
                </Whisper>
              </div>
              {cut && (
                <div className='archive-video-controls'>
                  <div style={{ width: 24 }}></div>
                  <div className='archive-crop-slider'>
                    <RangeSlider
                      min={0}
                      max={file.duration || 3600}
                      value={cutValues}
                      onChange={this.handleCutChanges}
                      renderTooltip={(dur?: number) => {
                        const c = moment.duration(dur, 's')
                        return this.renderSliderValue(c)
                      }}
                    />
                  </div>
                  {this.state.cutDisabled ? (
                    <Loader size='xs' />
                  ) : (
                    <Whisper
                      placement='bottom'
                      speaker={<Tooltip>{t('videoarchive.cut_file')}</Tooltip>}>
                      <IconButton
                        onClick={this.handleCut}
                        icon={<FaCut />}
                        size='xs'
                      />
                    </Whisper>
                  )}
                </div>
              )}
            </Panel>
          </div>
        </div>
      </div>
    )
  }

  renderSliderValue(c: moment.Duration) {
    const h = c.hours()
    const m = c.minutes().toString().padStart(2, '0')
    const s = c.seconds().toString().padStart(2, '0')

    if (h === 1 && m === '00' && s === '00') return '60:00'
    if (h) return `${h.toString().padStart(2, '0')}:${m}:${s}`
    return `${m}:${s}`
  }

  renderHeader() {
    const { camera } = this.props
    const { expand } = this.state

    const cam = {
      id: camera.id,
      name: camera.name,
      enabled: camera.enabled,
    } as Camera

    return (
      <CameraHeader
        camera={cam}
        options={{ edit: camera.id > 0, open: camera.enabled, events: true }}
        onExpand={() => this.setState({ expand: !expand })}
        expanded={expand}
      />
    )
  }

  renderPlayer() {
    const { t, file } = this.props
    const { src } = this.state

    if (!src) return <Spinner className='spinner-small spinner-center' />

    switch (file.type) {
      case 'MJPEG':
        return (
          <MJPEG
            ref={(ref) => (this.video = ref)}
            src={src}
            className='camera-player'
          />
        )
      case 'MDASH':
      case 'MP4':
        return (
          <VIDEO
            ref={(ref) => (this.video = ref)}
            src={src}
            className='camera-player'
            controls={false}
          />
        )
      default:
        return <Message type='error'>{t('errors.unsupported_player')}</Message>
    }
  }

  renderCanvas() {
    const { analytics, canvas } = this.state

    return (
      <CameraCanvas
        analytics={this.convertAnalytics(analytics)}
        width={canvas.w}
        height={canvas.h}
      />
    )
  }

  renderEvent = (e: Event) => {
    const { file } = this.props
    const timeAt = moment.unix(e.time_at)
    const timeTo = moment.unix(e.time_to)

    const left =
      (timeAt.diff(file.etime, 'seconds') / file.duration) * 100 + '%'
    const width = (timeTo.diff(timeAt, 'seconds') / file.duration) * 100 + '%'

    const progress = e.time_at - file.etime.unix()

    return (
      <div
        key={e.id}
        className='archive-event'
        style={{ left: left, width: width }}
        onClick={() => this.setProgress(progress)}
        data-time_at={e.time_at}
        data-progress={progress}
      />
    )
  }
}

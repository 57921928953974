import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonToolbar,
  Checkbox,
  Col,
  Divider,
  Footer,
  Form,
  Grid,
  InputPicker,
  Panel,
  Row,
  SelectPicker,
  TagPicker,
} from 'rsuite'

import Content, {
  CenterRow,
  ContentState,
  Header,
  HeaderLeft,
  HeaderTitle,
  Help,
  setTitle,
  success,
} from 'content'
import ROUTES from 'routes'
import RTMIP, {
  Camera,
  Dashboard,
  Device,
  Group,
  Profile,
  Role,
  User,
  checkAccess,
} from 'rtmip'

interface RouteParams {
  id?: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  inSave: boolean
  inCancel: boolean
  user: User
  initial: string
  roles: Role[]
  cameras: Camera[]
  devices: Device[]
  groups: Group[]

  self: User

  profiles: Profile[]
  dashboards: Dashboard[]
}

class UserForm extends React.Component<Props, State> {
  state = {
    user: { username: '', password: '', name: '', email: '' } as User,
  } as State

  constructor(props: Props) {
    super(props)
    this.state.user.id = parseInt(props.match.params.id || 'new') || 0
  }

  componentDidMount() {
    const { t } = this.props

    setTitle('users.title')

    if (this.state.user.id > 0) {
      this.loadUser()
    } else {
      this.setState({
        title: t('users.add_user'),
        loaded: true,
      })
    }

    RTMIP.user().then(this.setLoggedUser)
    this.loadDashboards()
  }

  loadUser = () => {
    RTMIP.user(this.state.user.id).then(this.setUser).catch(this.setError)
  }

  setUser = (u: User) => {
    console.log(u)
    this.setState({
      user: u,
      initial: JSON.stringify(u),
      title: u.name || u.username,
      loaded: true,
    })
  }

  setLoggedUser = (u: User) => {
    this.setState({ self: u })

    if (checkAccess(u, '/profiles', 1)) {
      RTMIP.profiles()
        .then((profiles) => {
          profiles?.forEach((p) => {
            const name = [] as string[]
            if (p.firstname) name.push(p.firstname)
            if (p.lastname) name.push(p.lastname)
            if (p.middlename) name.push(p.middlename)
            p.name = name.join(' ')
          })

          this.setState({ profiles: profiles || [] })
        })
        .catch(alert)
    }

    if (checkAccess(u, '/users', 1)) {
      RTMIP.roles()
        .then((roles) => this.setState({ roles }))
        .catch(alert)

      RTMIP.groups()
        .then((groups) => this.setState({ groups }))
        .catch(alert)

      RTMIP.cameras()
        .then((cameras) => this.setState({ cameras }))
        .catch(alert)

      RTMIP.devices()
        .then((devices) => this.setState({ devices }))
        .catch(alert)
    }
  }

  loadDashboards = () => {
    RTMIP.dashboards().then((dashboards) => {
      this.setState({ dashboards })
    })
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  //
  // handlers
  //

  isChanged = (): boolean => {
    return JSON.stringify(this.state.user) !== this.state.initial
  }

  handleForm = (p: Record<string, any>, e: any) => {
    Object.assign(this.state.user, p)
    this.setState({})
  }

  handleInput = (val: any, key: string) => {
    Object.assign(this.state.user, { [key]: val })
    this.setState({})
  }

  handleSave = () => {
    const { t } = this.props
    const { user } = this.state

    console.log(user)

    let resp: Promise<User>
    if (user.id > 0) resp = RTMIP.changeUser(user.id, user)
    else resp = RTMIP.createUser(user)

    resp
      .then((u: User) => {
        success(t('success'))
        const url = ROUTES.settings.users + '/' + u.id
        window.history.pushState('', u.username, url)
        this.setUser(u)
      })
      .catch(alert)
  }

  //
  // render
  //

  render() {
    const { loaded, error } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <CenterRow>
          <Panel>{this.renderForm()}</Panel>
        </CenterRow>
      </Content>
    )
  }

  renderHeader() {
    const { title } = this.state

    return (
      <Header left={<HeaderLeft back={ROUTES.settings.users} />}>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
    )
  }

  renderForm() {
    const { t } = this.props
    const { self, user, profiles } = this.state

    return (
      <Form
        formValue={user}
        onChange={this.handleForm}
        autoComplete='off'
        fluid>
        <Grid fluid>
          <Row gutter={15}>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>{t('users.username')}</Form.ControlLabel>
                <Form.Control name='username' />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>{t('users.password')}</Form.ControlLabel>
                <Form.Control name='password' type='password' />
              </Form.Group>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>{t('name')}</Form.ControlLabel>
                <Form.Control name='name' />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>{t('email')}</Form.ControlLabel>
                <Form.Control name='email' />
              </Form.Group>
            </Col>
          </Row>

          {checkAccess(self, '/profiles', 1) && [
            <Divider key='divider' />,
            <Row key='row' gutter={15}>
              <Col md={24}>
                <Form.Group>
                  <Form.ControlLabel>{t('users.profile')}</Form.ControlLabel>
                  <InputPicker
                    name='profile_id'
                    value={user.profile_id}
                    data={profiles}
                    onChange={(v) => this.handleInput(v || 0, 'profile_id')}
                    valueKey='id'
                    labelKey='name'
                    block
                  />
                </Form.Group>
              </Col>
            </Row>,
          ]}

          {checkAccess(self, '/users', 1) && this.renderProtected()}
        </Grid>

        {this.renderFooter()}
      </Form>
    )
  }

  renderProtected() {
    const { t } = this.props
    const { user, roles, cameras, devices, groups, dashboards } = this.state

    return (
      <div>
        <Divider />
        <Row gutter={15}>
          <Col md={12}>
            <Form.Group>
              <Form.ControlLabel>{t('users.role')}</Form.ControlLabel>
              <TagPicker
                name='role'
                value={user.roles_id || []}
                data={roles || []}
                onChange={(v) => this.handleInput(v || [], 'roles_id')}
                valueKey='id'
                labelKey='title'
                block
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.ControlLabel>{t('dashboard.title')}</Form.ControlLabel>
              <InputPicker
                name='dashboard_id'
                value={user.dashboard_id}
                data={dashboards || []}
                onChange={(v) => this.handleInput(v || 0, 'dashboard_id')}
                onOpen={this.loadDashboards}
                valueKey='id'
                labelKey='name'
                block
              />
            </Form.Group>
          </Col>
        </Row>

        <Divider />

        <Row gutter={15}>
          <Col md={8}>
            <Form.Group>
              <Form.ControlLabel>
                {t('cameras.title')}
                <Help>{t('users.help.cameras')}</Help>
              </Form.ControlLabel>
              <TagPicker
                value={user.cameras_id}
                data={cameras || []}
                onChange={(v) => this.handleInput(v, 'cameras_id')}
                valueKey='id'
                labelKey='name'
                block
              />
            </Form.Group>
          </Col>

          <Col md={8}>
            <Form.Group>
              <Form.ControlLabel>
                {t('devices.title')}
                <Help>{t('users.help.devices')}</Help>
              </Form.ControlLabel>
              <TagPicker
                value={user.devices_id}
                data={devices || []}
                onChange={(v) => this.handleInput(v, 'devices_id')}
                valueKey='id'
                labelKey='name'
                block
              />
            </Form.Group>
          </Col>

          <Col md={8}>
            <Form.Group>
              <Form.ControlLabel>
                {t('profiles.groups.title')}
                <Help>{t('users.help.groups')}</Help>
              </Form.ControlLabel>
              <TagPicker
                value={user.groups_id}
                data={groups || []}
                onChange={(v) => this.handleInput(v, 'groups_id')}
                valueKey='id'
                labelKey='name'
                block
              />
            </Form.Group>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col md={12}>
            <Form.Group>
              <Checkbox
                checked={user.self_events}
                onChange={() =>
                  this.handleInput(!user.self_events, 'self_events')
                }>
                {t('users.self_events')}
                <Help>{t('users.help.self_events')}</Help>
              </Checkbox>
            </Form.Group>
          </Col>
        </Row>

        <Divider />
        <Row gutter={15}>
          <Col md={8}>
            <Form.Group>
              <Form.ControlLabel>
                {t('users.new_profiles_group')}
                <Help>{t('users.help.new_profiles_group')}</Help>
              </Form.ControlLabel>
              <SelectPicker
                value={user.new_profiles_group}
                data={groups || []}
                onChange={(v) => this.handleInput(v || 0, 'new_profiles_group')}
                valueKey='id'
                labelKey='name'
                block
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    )
  }

  renderFooter() {
    const { t } = this.props
    const { user } = this.state
    return (
      <Footer className='footer-buttons'>
        <ButtonToolbar align='left'></ButtonToolbar>
        <ButtonToolbar justifyContent='flex-end'>
          {user.id > 0 && (
            <Button
              disabled={!this.isChanged()}
              onClick={this.loadUser}
              loading={this.state.inCancel}>
              {t('cancel')}
            </Button>
          )}

          <Button
            appearance='primary'
            type='submit'
            disabled={!user.username || (!user.id && !user.password)}
            onClick={this.handleSave}
            loading={this.state.inSave}>
            {t('save')}
          </Button>
        </ButtonToolbar>
      </Footer>
    )
  }
}

export default withTranslation()(withRouter(UserForm))

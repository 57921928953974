import React from 'react'
import Moveable from 'react-moveable'
import { Uploader } from 'rsuite'

import { IMAGE } from 'components/players'
import { trans } from 'i18n'
import ResizeDetector from 'react-resize-detector'
import RTMIP, { FloorMapObject, Item, Point } from 'rtmip'
import { CanvasProps } from './floormapcanvas'
import ObjectCamera from './objectcamera'
import ObjectDevice from './objectdevice'
import { OBJ_CAMERA, OBJ_DEVICE } from './utils'

interface Props extends CanvasProps {}

interface State {
  selected?: {
    fobj: FloorMapObject
    elem: EventTarget
    area?: HTMLDivElement
  }
  drag?: boolean
}

export default class CanvasImage extends React.Component<Props, State> {
  state = {} as State

  refCanvas = React.createRef<any>()
  refArea = React.createRef<any>()

  //
  // external
  //

  getNewObjectPosition(): Point {
    return [0.5, 0.5]
  }

  //
  // handlers
  //

  handleResize = () => {
    this.setState({})
  }

  handleSelect = (fobj?: FloorMapObject, elem?: EventTarget) => {
    if (fobj && elem) {
      const area = (elem as any).querySelector('.floormaps-area')
      this.setState({ selected: { fobj, elem, area } })
    } else {
      this.setState({ selected: undefined })
    }

    const { onSelect } = this.props
    if (onSelect) onSelect(fobj)
  }

  handleDoubleClick = (fobj: FloorMapObject) => {
    const { onObjectDoubleClick } = this.props
    if (onObjectDoubleClick) onObjectDoubleClick(fobj)
  }

  handleDrag = (data: any) => {
    const image = this.refCanvas.current
    if (!image) return

    const { selected } = this.state
    if (!selected) return

    selected.fobj.position[0] = data.left / image.clientWidth
    selected.fobj.position[1] = data.top / image.clientHeight

    this.setState({ drag: true })
  }

  handleRotate = (data: any) => {
    const { selected } = this.state
    if (!selected) return

    selected.fobj.rotation = data.absoluteRotate
    this.setState({ drag: true })
  }

  handleDragEnd = () => {
    this.setState({ drag: false })
  }

  handleResizeArea = (data: any) => {
    const image = this.refCanvas.current
    if (!image) return

    const { selected } = this.state
    if (!selected) return

    selected.fobj.area = [
      data.width / image.clientWidth,
      data.height / image.clientHeight,
    ]

    if (selected.area) {
      selected.area.style.top = 19 - data.height / 2 + 'px'
      selected.area.style.width = data.width + 'px'
      selected.area.style.height = data.height + 'px'
    }

    this.setState({})
  }

  handleUpload = (resp: any) => {
    const { fmap } = this.props
    resp.map_image += '?' + new Date().getTime()

    Object.assign(fmap, resp)
    this.setState({})
  }

  //
  // data
  //

  getEventsItems = (id: number): Item[] | undefined => {
    const { events } = this.props
    if (!events) return undefined

    const items = [] as Item[]

    events.forEach((e) => {
      if (e.camera_id !== id || !e.items) return
      items.push(...e.items)
    })

    return items
  }

  //
  // render
  //

  render() {
    const { fmap, editable, size } = this.props
    const { selected, drag } = this.state

    if (!fmap.map_image) {
      return (
        <Uploader
          className='floormaps-uploader-area'
          action={RTMIP.url('/api/floormaps/' + fmap.id + '/upload')}
          headers={RTMIP.authHeaders()}
          onSuccess={this.handleUpload}
          onError={(r) => alert(r.response.error)}
          accept='image/*'
          fileListVisible={false}
          draggable>
          <div className='floormaps_dnd'>{trans('floormaps.upload_image')}</div>
        </Uploader>
      )
    }

    return (
      <ResizeDetector handleWidth handleHeight onResize={this.handleResize}>
        <div id='floormaps_canvas' ref={this.refCanvas}>
          <div onClick={() => this.handleSelect()}>
            <IMAGE
              className='floormaps-image'
              src={fmap.map_image}
              alt='floormap image'
              height={size?.h}
            />
          </div>
          {fmap.objects && fmap.objects.map(this.renderObject)}
          {editable && (
            <div>
              {selected && (
                <Moveable
                  target={selected.elem as any}
                  container={this.refCanvas.current}
                  onDrag={this.handleDrag}
                  onRotate={this.handleRotate}
                  onDragEnd={this.handleDragEnd}
                  onRotateEnd={this.handleDragEnd}
                  draggable
                  rotatable
                  rotationPosition='right'
                  throttleRotate={5}
                  throttleDrag={1}
                  resizable={false}
                />
              )}
              {selected && selected.area && !drag && (
                <Moveable
                  ref={this.refArea}
                  target={selected.area as any}
                  container={this.refCanvas.current}
                  onResize={this.handleResizeArea}
                  renderDirections={['ne', 'e', 'se']}
                  resizable
                  origin={false}
                />
              )}
            </div>
          )}
        </div>
      </ResizeDetector>
    )
  }

  renderObject = (o: FloorMapObject) => {
    const { fmap, showArea, onDrawFOVItems } = this.props
    const { selected } = this.state

    let isSelected = false
    if (selected)
      isSelected = selected.fobj.type === o.type && selected.fobj.id === o.id

    const countId = `${o.type}_${o.id}_count`

    return [
      <div
        key={o.type + o.id}
        className='floormaps-object-moveable'
        style={{
          left: `${o.position[0] * 100}%`,
          top: `${o.position[1] * 100}%`,
          transform: `rotate(${o.rotation}deg)`,
        }}
        onClick={(e) => this.handleSelect(o, e.target)}
        onDoubleClick={() => this.handleDoubleClick(o)}>
        {o.type === OBJ_CAMERA && (
          <ObjectCamera
            fmapid={fmap.id}
            object={o}
            selected={isSelected}
            countTarget={countId}
            showArea={showArea || isSelected}
            showItems={true}
            items={this.getEventsItems(o.id)}
            onDrawFOVItems={onDrawFOVItems}
            container='.floormaps-image'
          />
        )}
        {o.type === OBJ_DEVICE && (
          <ObjectDevice object={o} selected={isSelected} />
        )}
      </div>,
      <span
        key={o.type + o.id + 'title'}
        className='floormaps-object_title'
        style={{
          left: `${o.position[0] * 100}%`,
          top: `${o.position[1] * 100}%`,
        }}>
        {o.name}
      </span>,
      <span
        key={o.type + o.id + 'count'}
        className='floormaps-object_count'
        id={countId}
        style={{
          left: `${o.position[0] * 100}%`,
          top: `${o.position[1] * 100}%`,
        }}></span>,
    ]
  }
}

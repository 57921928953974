import Cookies from 'js-cookie'
import moment from 'moment-timezone'
import urlpath from 'path'
import url from 'url'

import queryString from 'query-string'
import {
  ActivityData,
  ArchiveCamera,
  ArchiveDrawAndCutProcess,
  ArchiveFile,
  AuthSettings,
  Camera,
  CameraActivity,
  CameraForm,
  CameraStatus,
  Car,
  Chart,
  ChartData,
  Dashboard,
  Detected,
  Detector,
  DetectorLog,
  DetectorParseFrameResponse,
  DetectorStatus,
  Device,
  DeviceHandlerForm,
  DeviceState,
  DrawAndCutOpt,
  Event,
  Exception,
  ExtScript,
  FloorMap,
  FloorMapObject,
  Founded,
  Frame,
  Group,
  Health,
  ImgSize,
  Item,
  JournalSettings,
  License,
  Line,
  Notification,
  ONVIFprofile,
  ONVIFvideoConfig,
  PTZdata,
  Package,
  Page,
  Permission,
  Plugin,
  Point,
  Profile,
  RTMIPTime,
  Region,
  ReportsConfig,
  ReportsFilters,
  ReportsSending,
  Repository,
  Role,
  Scheme,
  Script,
  ScriptLog,
  SearchData,
  SettingsField,
  Shell,
  StatusModel,
  Success,
  ThemesSettings,
  User,
  Widget,
} from './entities'
import { WS } from './ws'

interface resp {
  data: any
  ok: boolean
}

interface uploadResp {
  id: number
  filename: string
}

export interface profilePhotoOpt {
  nodetect: boolean
  emotions: boolean
  get_quality: boolean
}

const TOKEN_KEY = 'token'
const HEADERS_AUTHORIZATION = 'Authorization'

declare global {
  interface Window {
    RTMIP: RTMIP
  }
}

export interface ExportOpt {
  filetype?: string
  filename?: string
}

export interface ExportFile {
  filename: string
  disposition: string
  type: string
  blob: Blob
}

export class RTMIP {
  addr: string = ''

  // current token
  token: string = ''

  // headers uses for each requests to the RTMIP api
  headers: any = {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    // 'Access-Control-Expose-Headers': 'Content-Disposition',
  }

  // on api initialized check a local storage on token exists and add it to headers
  constructor(addr: string) {
    this.addr = addr
    this.setToken(localStorage.getItem(TOKEN_KEY) || '')

    /* tslint:disable-next-line */
    window.RTMIP = this
  }

  // check if token exists and set it to the authorization headers field
  setToken(token: string) {
    if (token) {
      this.token = token

      this.headers[HEADERS_AUTHORIZATION] = 'Bearer ' + token
      Cookies.set('jwt', token, {
        path: '/',
        expires: moment().add(6, 'months').toDate(),
        sameSite: window.location.protocol === 'https' ? 'None' : 'Lax',
        secure: window.location.protocol === 'https' || undefined,
      })
    }
  }

  authHeaders(): Record<string, string> {
    return {
      [HEADERS_AUTHORIZATION]: this.headers[HEADERS_AUTHORIZATION],
    }
  }

  ws(): WS {
    return new WS(this.addr, this.token)
  }

  // return an url by rtmip address and specified url path
  url(path: string): string {
    return url.resolve(this.addr, path)
  }

  urlauth(path: string): string {
    if (path.includes('?')) path += '&jwt=' + this.token
    else path += '?jwt=' + this.token

    return this.url(path)
  }

  urlauth2(path: string): string {
    if (this.addr == '/') return this.url(path)
    else return this.urlauth(path)
  }

  // req is universal function for do 'fetch' requests to the RTMIP backend
  req(method: string, path: string, data: any, signal?: AbortSignal) {
    let u = this.url(urlpath.join('/api', path))

    if (data && ['GET', 'DELETE'].includes(method)) {
      // INFO: IMPORTANT! comma because `echo` incorrectly parses query arrays
      const q = queryString.stringify(data, { arrayFormat: 'comma' })
      u += '?' + q
    }

    return fetch(u, {
      method,
      signal,
      headers: this.headers,
      body: ['POST', 'PUT', 'PATCH'].includes(method)
        ? JSON.stringify(data)
        : null,
    })
      .then((resp) => {
        return resp.json().then((data) => ({ data: data, ok: resp.ok } as resp))
      })
      .then((resp: resp) => {
        if (resp.ok) return resp.data

        const msg = resp.data.error || resp.data.message || resp.data
        console.error(method, u, msg)

        throw new Error(msg)
      })
  }

  GET(path: string, data?: any) {
    return this.req('GET', path, data)
  }

  POST(path: string, data?: any, signal?: AbortSignal) {
    return this.req('POST', path, data, signal)
  }

  PATCH(path: string, data?: any) {
    return this.req('PATCH', path, data)
  }

  DELETE(path: string) {
    return this.req('DELETE', path, null)
  }

  FILE(path: string, name: string, file: Blob, filename?: string) {
    const u = this.url(urlpath.join('/api', path))
    const headers = this.authHeaders()

    let formdata = new FormData()
    formdata.append(name, file, filename || 'frame.jpg')

    return fetch(u, {
      method: 'POST',
      headers: headers,
      body: formdata,
    })
      .then((resp) => {
        return resp.json().then((data) => ({ data: data, ok: resp.ok } as resp))
      })
      .then((resp: resp) => {
        if (resp.ok) return resp.data
        throw new Error(resp.data.error || resp.data.message || resp.data)
      })
  }

  EXPORT(path: string, data?: any, opt?: ExportOpt) {
    let u = this.url(urlpath.join('/api', path))

    const headers = this.headers
    const method = data ? 'POST' : 'GET'
    const body = data ? JSON.stringify(data) : undefined

    return fetch(u, { method, headers, body })
      .then(async (resp) => {
        if (resp.ok)
          return {
            filename: resp.headers.get('filename'),
            disposition: resp.headers.get('Content-Disposition'),
            type: resp.headers.get('content-type'),
            blob: await resp.blob(),
          } as ExportFile

        return resp.json().then((err) => {
          throw new Error(err.error || err.message)
        })
      })
      .then((file: ExportFile) => {
        const blob = new Blob([file.blob], {
          type: file.type || opt?.filetype,
        })

        const obj = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = obj
        a.download = file.filename || opt?.filename || ''
        a.click()

        setTimeout(() => {
          window.URL.revokeObjectURL(obj)
        }, 250)

        return file
      })
  }

  // API methods

  health(): Promise<Health> {
    return this.GET('/health')
  }

  healthChart(): Promise<Health[]> {
    return this.GET('/health/chart')
  }

  // authorization request, returns token
  auth(username: string, password: string, frame?: string): Promise<Detected> {
    return this.POST('/auth', {
      username: username,
      password: password,
      frame: frame,
    }).then((resp) => {
      if (resp.token) {
        this.setToken(resp.token)
        localStorage.setItem(TOKEN_KEY, resp.token)
      }

      return resp
    })
  }

  // remove a token from local storage and request headers
  logout() {
    return this.POST('/auth/logout')
      .then(() => {
        delete this.headers[HEADERS_AUTHORIZATION]
        localStorage.removeItem(TOKEN_KEY)
        Cookies.remove('jwt', { path: '/' })
      })
      .catch((err) => {
        delete this.headers[HEADERS_AUTHORIZATION]
        localStorage.removeItem(TOKEN_KEY)
        Cookies.remove('jwt', { path: '/' })
        return err
      })
  }

  authSettings(): Promise<AuthSettings> {
    return this.GET('/auth/settings')
  }

  changeAuthSettings(s: AuthSettings): Promise<AuthSettings> {
    return this.POST('/auth/settings', s)
  }

  faceAuth(photo: Blob): Promise<Detected> {
    return this.FILE('/auth/faceauth', 'photo', photo).then((resp) => {
      if (resp.token) {
        this.setToken(resp.token)
        localStorage.setItem(TOKEN_KEY, resp.token)
      }

      return resp
    })
  }

  authExtSettings(): Promise<SettingsField[]> {
    return this.GET('/auth/external')
  }

  changeAuthExtSettings(data: Record<string, any>): Promise<SettingsField[]> {
    return this.POST('/auth/external', data)
  }

  //
  // users
  //

  user(id?: number) {
    return this.GET(id ? `/users/${id}` : `/user`)
  }

  // change password of current user
  userSetPassword = (password: string) => {
    return this.POST('/user', { password: password })
  }

  changeUser = (id: number, user: User) => {
    return this.POST('/users/' + id, user)
  }

  createUser = (user: User) => {
    return this.POST('/users', user)
  }

  deleteUser = (id: number) => {
    return this.DELETE('/users/' + id)
  }

  // receive users list
  users() {
    return this.GET('/users')
  }

  // receive roles list
  roles(): Promise<Role[]> {
    return this.GET('/roles')
  }

  createRole(title: string): Promise<Role> {
    return this.POST('/roles', { title: title })
  }

  changeRole(id: number, role: any): Promise<Role> {
    return this.POST('/roles/' + id, role)
  }

  deleteRole(id: number): Promise<Success> {
    return this.DELETE('/roles/' + id)
  }

  setRolePermission(id: number, p: Permission): Promise<Role> {
    return this.PATCH('/roles/' + id, p)
  }

  //
  //
  //

  timeOffset: number = 0
  timeZone: string = ''

  time(): Promise<RTMIPTime> {
    return this.GET('/time').then((time) => {
      this.timeZone = time.zone
      this.timeOffset = time.offset

      moment.tz.setDefault(time.zone)
      return time
    })
  }

  //
  // dashboard
  //

  dashboards(): Promise<Dashboard[]> {
    return this.GET('/dashboards')
  }

  dashboard(id?: any): Promise<Dashboard> {
    return this.GET(`/dashboards/${id || 'default'}`)
  }

  createDashboard(d: Dashboard): Promise<Dashboard> {
    return this.POST(`/dashboards`, d)
  }

  changeDashboard(id: number, d: Dashboard): Promise<Dashboard> {
    return this.POST(`/dashboards/${id}`, d)
  }

  deleteDashboard(id: number): Promise<Success> {
    return this.DELETE(`/dashboards/${id}`)
  }

  setDashboardLayouts(id: number, layouts: any) {
    return this.POST(`/dashboards/${id}/layouts`, layouts)
  }

  addWidget(id: number, type: string): Promise<Widget> {
    return this.POST(`/dashboards/${id}/widgets`, { type: type })
  }

  widget(id: number, wgtid: string): Promise<Widget> {
    return this.GET(`/dashboards/${id}/widgets/${wgtid}`)
  }

  delWidget(id: number, wgtid: string) {
    return this.DELETE(`/dashboards/${id}/widgets/${wgtid}`)
  }

  setWidget(id: number, wgtid: string, data: any): Promise<Widget> {
    return this.POST(`/dashboards/${id}/widgets/${wgtid}`, data)
  }

  widgetData(id: number, wgtid: string): Promise<any> {
    return this.GET(`/dashboards/${id}/widgets/${wgtid}/data`)
  }

  widgetParams(id: number, wgtid: string): Promise<SettingsField[]> {
    return this.GET(`/dashboards/${id}/widgets/${wgtid}/params`)
  }

  //
  // cameras
  //

  camerasScanner(): Promise<Founded[]> {
    return this.GET('/cameras/scanner')
  }

  cameraHandlers(): Promise<string[]> {
    return this.GET('/cameras/handlers').catch((err) => {
      err.message = `failed to load cameras types: ${err.message}`
      throw new Error(err)
    })
  }

  cameraHandlerForm(name: string): Promise<CameraForm> {
    return this.GET('/cameras/handlers/' + name + '/form').catch((err) => {
      err.message = `failed to load camera handler '${name}' form: ${err.message}`
      throw new Error(err)
    })
  }

  // receive cameras list
  cameras(): Promise<Camera[]> {
    return this.GET('/cameras').catch((err) => {
      err.message = `failed to load cameras: ${err.message}`
      throw new Error(err)
    })
  }

  // receive a camera by specified id
  camera(id: number): Promise<Camera> {
    return this.GET('/cameras/' + id).catch((err) => {
      err.message = `failed to load camera: ${err.message}`
      throw new Error(err)
    })
  }

  cameraStatus(id: number): Promise<CameraStatus> {
    return this.GET('/cameras/' + id + '/status').catch((err) => {
      err.message = `failed to load camera status: ${err.message}`
      throw new Error(err)
    })
  }

  cameraFrameSize(id: number): Promise<ImgSize> {
    return this.GET('/cameras/' + id + '/framesize')
  }

  cameraDetected(id: number, offset: number): Promise<Detected[]> {
    return this.GET(
      '/cameras/' + id + '/detected',
      offset && { offset: offset }
    )
  }

  sendFrame(id: number, img: any, offset?: number): Promise<Detected[]> {
    const frame = { img, offset }

    return this.POST('/cameras/' + id + '/parseframe', frame)
  }

  getDetected(
    id: number,
    opt: { offset?: number; timems?: number }
  ): Promise<Detected[]> {
    return this.GET('/cameras/' + id + '/detected', opt)
  }

  // create new camera
  createCamera(cam: Camera): Promise<Camera> {
    return this.POST('/cameras', cam)
  }

  // change camera data by id
  changeCamera(id: number, cam: Camera): Promise<Camera> {
    return this.POST('/cameras/' + id, cam)
  }

  // enable/disable camera
  toggleCamera(id: number) {
    return this.POST('/cameras/' + id + '/toggle')
  }

  // delete camera by id
  deleteCamera(id: number) {
    return this.DELETE('/cameras/' + id)
  }

  // recieve cameras sectors list
  sectors(): Promise<string[]> {
    return this.GET('/cameras/sectors')
  }

  camerasRegions(): Promise<Region[]> {
    return this.GET('/cameras/regions')
  }

  camerasLines(): Promise<Line[]> {
    return this.GET('/cameras/lines')
  }

  cameraPlayer(id: number): Promise<string> {
    let u = this.url('/api/cameras/' + id + '/player')

    return fetch(u, {
      method: 'GET',
      headers: this.headers,
    })
      .then((resp) => {
        return resp.text().then((data) => ({ data: data, ok: resp.ok } as resp))
      })
      .then((resp: resp) => {
        if (resp.ok) return resp.data

        const msg = resp.data.error || resp.data.message || resp.data

        throw new Error(msg)
      })
  }

  sectionStatus(section: string): Promise<StatusModel[]> {
    return this.GET(`/${section}/status`)
  }

  // load heatmap by camera in specified time range, and filters
  // if filter or time range not specified, heatmap was be build by last 1000 events
  heatmap(id: number, opt?: any): Promise<Blob> {
    let u = this.url('/api/cameras/' + id + '/heatmap')

    if (opt) u += `?${new URLSearchParams(opt).toString()}`

    return fetch(u, {
      method: 'GET',
      headers: this.authHeaders(),
    }).then((resp) => resp.blob())
  }

  //
  // ptz
  //

  cameraPTZ(id: number, ptz: PTZdata): Promise<Success> {
    return this.POST('/cameras/' + id + '/ptz', ptz)
  }

  cameraExportCached(id: number): Promise<Success> {
    return this.POST('/cameras/' + id + '/export')
  }

  //
  // onvif
  //

  ONVIFprofiles(
    addr: string,
    auth?: { user: string; pass: string }
  ): Promise<ONVIFprofile[]> {
    addr = addr.replace('rtsp://', '')
    addr = addr.split('/')[0]

    let url = `/onvif/profiles?addr=${addr}`
    if (auth && auth.user) url += `&user=${auth.user}&pass=${auth.pass}`

    return this.GET(url)
  }

  changeONVIFprofile(
    addr: string,
    conf: ONVIFvideoConfig,
    auth?: { user: string; pass: string }
  ) {
    addr = addr.replace('rtsp://', '')

    let url = `/onvif/profiles?addr=${addr}`
    if (auth) url += `&user=${auth.user}&pass=${auth.pass}`

    return this.POST(url, conf)
  }

  //
  // archive
  //

  archive(dateAt: string, dateTo: string): Promise<ArchiveCamera[]> {
    return this.GET(`/archive?date_at=${dateAt}&date_to=${dateTo}`)
  }

  archiveFiles(cam: string): Promise<ArchiveFile[]> {
    return this.GET(`/archive/${cam}/files`)
  }

  archiveDrawAndCut(
    cam: string,
    file: string,
    opt: DrawAndCutOpt
  ): Promise<ArchiveDrawAndCutProcess> {
    return this.POST(`/archive/${cam}/${file}`, opt)
  }

  archiveDrawAndCutProcesses(): Promise<ArchiveDrawAndCutProcess[]> {
    return this.GET('/archive/draw_processes')
  }

  deleteArchiveDrawAndCutProcesses(
    id: string
  ): Promise<ArchiveDrawAndCutProcess[]> {
    return this.DELETE('/archive/draw_processes/' + id)
  }

  //
  // analytics
  //

  schemes(): Promise<Scheme[]> {
    return this.GET('/schemes')
  }

  scheme(id: number): Promise<Scheme> {
    return this.GET('/schemes/' + id).catch((err) => {
      err.message = `failed to load scheme: ${err.message}`
      throw new Error(err)
    })
  }

  createScheme(scheme: Scheme): Promise<Scheme> {
    return this.POST('/schemes/', scheme)
  }

  changeScheme(id: number, scheme: Scheme): Promise<Scheme> {
    return this.POST('/schemes/' + id, scheme)
  }

  deleteScheme(id: number) {
    return this.DELETE('/schemes/' + id)
  }

  toggleScheme(id: number) {
    return this.POST(`/schemes/${id}/toggle`)
  }

  extscripts(): Promise<ExtScript[]> {
    return this.GET('/schemes/extscripts')
  }

  extscript(name: string): Promise<ExtScript> {
    return this.GET('/schemes/extscripts/' + name)
  }

  schemeParseFrame(id: number, file: Blob): Promise<Frame> {
    return this.FILE(`/schemes/${id}/parseframe?notsave=true`, 'frame', file)
  }

  schemeActivity(id: number): Promise<CameraActivity[]> {
    return this.GET(`/schemes/${id}/activity`)
  }

  //
  // notifications
  //

  // receive notifications list
  notifications() {
    return this.GET('/notifications')
  }

  // receive notification by id
  notification(id: number) {
    return this.GET('/notifications/' + id)
  }

  // create new notification
  createNotification(ntf: Notification) {
    return this.POST('/notifications', ntf)
  }

  // change notification by id or name
  changeNotification(id: number, ntf: Notification) {
    return this.POST('/notifications/' + id, ntf)
  }

  // enable/disable notification
  toggleNotification(id: number) {
    return this.POST('/notifications/' + id + '/toggle')
  }

  checkNotification(id: number): Promise<Success> {
    return this.POST('/notifications/' + id + '/check')
  }

  // delete notification
  deleteNotification(id: number) {
    return this.DELETE('/notifications/' + id)
  }

  //
  // events
  //

  // recieve events
  events(opt: any): Promise<Event[]> {
    return this.GET('/events', opt)
  }

  createEvent(e: Event) {
    return this.POST('/events', e)
  }

  deleteEvents(id: number[] | string[]) {
    const q = new URLSearchParams()
    id.forEach((id: any) => q.append('id', id))

    return this.DELETE('/events?' + q.toString())
  }

  confirmEvent(e: Event) {
    return this.POST(`/events/${e.id}/confirm`)
  }

  //
  // metrics
  //

  // metrics() {
  //   return this.GET('/metrics')
  // }

  // createMetric(metric) {
  //   return this.POST('/metrics', metric)
  // }

  // metric(id) {
  //   return this.GET('/metrics/' + id)
  // }

  // changeMetric(id, metric) {
  //   return this.POST('/metrics/' + id, metric)
  // }

  // deleteMetric(id) {
  //   return this.DELETE('/metrics/' + id)
  // }

  //
  // profiles
  //

  profiles(): Promise<Profile[]> {
    return this.GET('/profiles')
  }

  profile(id: number): Promise<Profile> {
    return this.GET('/profiles/' + id)
  }

  profilePhotos(id: number): Promise<string[]> {
    return this.GET('/profiles/' + id + '/photos')
  }

  changeProfile(id: number, p: Profile): Promise<Profile> {
    return this.POST('/profiles/' + id, p)
  }

  createProfile(p: Profile): Promise<Profile> {
    return this.POST('/profiles', p)
  }

  deleteProfile(id: number) {
    return this.DELETE('/profiles/' + id)
  }

  deleteProfilePhoto(id: number, src: string) {
    return this.DELETE('/profiles/' + id + '/photos/' + src)
  }

  deleteProfilePhotos(id: number) {
    return this.DELETE('/profiles/' + id + '/photos')
  }

  deleteAllProfilesPhotos() {
    return this.DELETE('/profiles/photos')
  }

  uploadProfilePhoto(
    id: number,
    file: Blob,
    opt: profilePhotoOpt
  ): Promise<uploadResp> {
    const q = new URLSearchParams(opt as any).toString()
    return this.FILE('/profiles/' + id + '/photos?' + q, 'photo', file)
  }

  findFace(file: Blob) {
    return this.FILE('/detectors/findface', 'frame', file, '')
  }

  profilesFields(): Promise<SettingsField[]> {
    return this.GET('/profiles/fields')
  }

  profilesImport(file: any): Promise<Success> {
    return this.FILE('/profiles/import', 'file', file.blobFile, file.name)
  }

  profilesExport() {
    const url = this.url('/api/profiles/export')
    const headers = this.authHeaders()

    return fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then(async (resp) => {
        if (resp.status !== 200) {
          const err = await resp.json()
          throw new Error(err.error || err.message)
        }

        return {
          filename: resp.headers.get('Filename'),
          disposition: resp.headers.get('Content-Disposition'),
          type: resp.headers.get('Content-Type'),
          blob: await resp.blob(),
        }
      })
      .then((file) => {
        const blob = new Blob([file.blob], {
          type: file.type || 'text/csv',
        })

        const obj = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = obj
        a.download = file.filename || 'profiles.csv'
        a.click()

        setTimeout(() => {
          window.URL.revokeObjectURL(obj)
        }, 250)

        return file
      })
  }

  //
  // cars
  //

  cars(): Promise<Car[]> {
    return this.GET('/cars')
  }

  car(id: number): Promise<Car> {
    return this.GET('/cars/' + id)
  }

  changeCar(id: number, p: Car): Promise<Car> {
    return this.POST('/cars/' + id, p)
  }

  createCar(p: Car): Promise<Car> {
    return this.POST('/cars', p)
  }

  deleteCar(id: number) {
    return this.DELETE('/cars/' + id)
  }

  carPhoto(id: number) {
    return this.GET('/cars/' + id + '/photo')
  }

  deleteCarPhoto(id: number) {
    return this.POST('/cars/' + id + '/photos')
  }

  uploadCarPhoto(id: number, file: Blob): Promise<uploadResp> {
    return this.FILE('cars/' + id + '/photos', 'photo', file)
  }

  carsImport(file: any): Promise<Success> {
    return this.FILE('/cars/import', 'file', file.blobFile, file.name)
  }

  //
  // groups
  //

  groups(): Promise<Group[]> {
    return this.GET('/groups')
  }

  deleteGroup(id: number): Promise<any> {
    return this.DELETE('/groups/' + id)
  }

  createGroup(name: string): Promise<Group> {
    const data = { name } as Group
    return this.POST('/groups', data)
  }

  renameGroup(id: number, name: string): Promise<Group> {
    const data = { name } as Group
    return this.POST('/groups/' + id, data)
  }

  //
  // detectors
  //

  detectors(): Promise<Detector[]> {
    return this.GET('/detectors')
  }

  detector(id: number): Promise<Detector> {
    return this.GET('/detectors/' + id)
  }

  detectorParams(id: number): Promise<SettingsField[]> {
    return this.GET('/detectors/' + id + '/params')
  }

  createDetector(det: Detector): Promise<Detector> {
    return this.POST('/detectors/', det)
  }

  changeDetector(id: number, det: Detector): Promise<Detector> {
    return this.POST('/detectors/' + id, det)
  }

  toggleDetector(id: number): Promise<Detector> {
    return this.POST('/detectors/' + id + '/toggle')
  }

  deleteDetector(id: number): Promise<Detector> {
    return this.DELETE('/detectors/' + id)
  }

  detectorLog(id: number): Promise<DetectorLog> {
    return this.GET('/detectors/' + id + '/log')
  }

  detectorsTypes(): Promise<string[]> {
    return this.GET('detectors/output_types')
  }

  detectorStatus(id: number): Promise<DetectorStatus> {
    return this.GET('/detectors/' + id + '/status')
  }

  detectorParseFrame(
    id: number,
    file: Blob
  ): Promise<DetectorParseFrameResponse> {
    return this.FILE('/detectors/' + id + '/parseframe', 'frame', file)
  }

  detectorsScanner(): Promise<DetectorStatus[]> {
    return this.GET('/detectors/scanner')
  }

  //
  // devices
  //

  devicesScanner(): Promise<Founded[]> {
    return this.GET('/devices/scanner')
  }

  deviceHandlers(): Promise<DeviceHandlerForm[]> {
    return this.GET('/devices/handlers').catch((err) => {
      err.message = `failed to load devices handlers: ${err.message}`
      throw new Error(err)
    })
  }

  devices(): Promise<Device[]> {
    return this.GET('/devices')
  }

  device(id: number): Promise<Device> {
    return this.GET('/devices/' + id)
  }

  createDevice(dev: Device): Promise<Device> {
    return this.POST('/devices', dev)
  }

  changeDevice(id: number, dev: Device): Promise<Device> {
    return this.POST('/devices/' + id, dev)
  }

  deleteDevice(id: number): Promise<Device> {
    return this.DELETE('/devices/' + id)
  }

  toggleDevice(id: number): Promise<Device> {
    return this.POST('/devices/' + id + '/toggle')
  }

  openDevice(id: number): Promise<{ pass: boolean }> {
    return this.POST('/devices/' + id + '/open')
  }

  deviceState(id: number): Promise<DeviceState> {
    return this.GET('/devices/' + id + '/state')
  }

  //
  // scripts
  //

  scripts(): Promise<Script[]> {
    return this.GET('/scripts')
  }

  script(id: number): Promise<Script> {
    return this.GET('/scripts/' + id).catch((err) => {
      err.message = `failed to load script: ${err.message}`
      throw new Error(err)
    })
  }

  createScript(script: Script): Promise<Script> {
    return this.POST('/scripts', script)
  }

  changeScript(id: number, script: Script): Promise<Script> {
    return this.POST('/scripts/' + id, script)
  }

  deleteScript(id: number): Promise<Script> {
    return this.DELETE('/scripts/' + id)
  }

  scriptLog(id: number): Promise<ScriptLog[]> {
    return this.GET('/scripts/' + id + '/log')
  }

  scriptCompletions(source: string, pos: any) {
    const data = { source, pos }
    return this.POST('/scripts/completions', data)
  }

  //
  // charts
  //

  charts(): Promise<Chart[]> {
    return this.GET('/charts')
  }

  chart(id: number): Promise<Chart> {
    return this.GET('/charts/' + id)
  }

  createChart(chart: Chart): Promise<Chart> {
    return this.POST('/charts', chart)
  }

  changeChart(id: number, chart: Chart): Promise<Chart> {
    return this.POST('/charts/' + id, chart)
  }

  deleteChart(id: number): Promise<Chart> {
    return this.DELETE('/charts/' + id)
  }

  chartData(id: number, data?: Record<string, any>): Promise<ChartData> {
    return this.GET('/charts/' + id + '/data', data)
  }

  //
  // reports
  //

  reportsEvents(
    filter: Record<string, any>,
    chart_id?: number,
    signal?: AbortSignal
  ): Promise<any> {
    let url = '/api/reports/events'
    if (chart_id) url += '?chart_id=' + chart_id

    // return this.POST(url, filter, signal)

    const headers = Object.assign(this.headers, {
      Accept: 'application/stream+json',
    })

    return fetch(this.url(url), {
      method: 'POST',
      body: JSON.stringify(filter),
      headers,
      signal,
    })
      .then(async (resp) => {
        if (resp.status !== 200) {
          const err = await resp.json()
          throw new Error(err.message || err.error)
        }

        return resp.body
      })
      .then((body) => body?.getReader())
  }

  reportsNames(): Promise<string[]> {
    return this.GET('/reports/exportnames')
  }

  reportsExport(name: string, filter: Record<string, any>) {
    let u = this.url('/api/reports/export/' + name)

    return fetch(u, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(filter),
    })
      .then(async (resp) => {
        if (resp.status !== 200) {
          const err = await resp.json()
          throw new Error(err.message || err.error)
        }

        return {
          filename: resp.headers.get('filename'),
          disposition: resp.headers.get('Content-Disposition'),
          type: resp.headers.get('content-type'),
          blob: await resp.blob(),
        }
      })
      .then((file) => {
        const blob = new Blob([file.blob], {
          type: file.type || 'application/pdf',
        })

        const obj = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = obj
        a.download = file.filename || 'report.pdf'
        a.click()

        setTimeout(() => {
          window.URL.revokeObjectURL(obj)
        }, 250)

        return file
      })
  }

  reportsFilters(): Promise<ReportsFilters> {
    return this.GET('/reports/filters')
  }

  reportsConfig(): Promise<ReportsConfig> {
    return this.GET('/reports/config')
  }

  changeReportsConfig(conf: ReportsConfig): Promise<ReportsConfig> {
    return this.POST('/reports/config', conf)
  }

  //
  // reports sendings
  //

  reportsSendings(): Promise<ReportsSending[]> {
    return this.GET('/reports/sending')
  }

  reportsSending(id: any): Promise<ReportsSending> {
    return this.GET(`/reports/sending/${id}`)
  }

  createReportsSending(rs: ReportsSending): Promise<ReportsSending> {
    return this.POST(`/reports/sending`, rs)
  }

  changeReportsSending(
    id: number,
    rs: ReportsSending
  ): Promise<ReportsSending> {
    return this.POST(`/reports/sending/${id}`, rs)
  }

  deleteReportsSending(id: number): Promise<ReportsSending> {
    return this.DELETE(`/reports/sending/${id}`)
  }

  checkReportsSending(id: number): Promise<ReportsSending> {
    return this.POST(`/reports/sending/${id}/check`)
  }

  //
  // floormaps
  //

  floormaps(): Promise<FloorMap[]> {
    return this.GET('/floormaps')
  }

  floormap(id: number): Promise<FloorMap> {
    return this.GET('/floormaps/' + id)
  }

  createFloormap(fmap: FloorMap): Promise<FloorMap> {
    return this.POST('/floormaps', fmap)
  }

  changeFloormap(id: number, fmap: FloorMap): Promise<FloorMap> {
    return this.POST('/floormaps/' + id, fmap)
  }

  deleteFloormap(id: number): Promise<FloorMap> {
    return this.DELETE('/floormaps/' + id)
  }

  floormapUploadImage(id: number, file: Blob): Promise<FloorMap> {
    return this.FILE(`/floormaps/${id}/upload`, 'file', file)
  }

  floormapPositions(
    id: number,
    o: FloorMapObject,
    items: Item[]
  ): Promise<{ points: Point[]; items: Item[] }> {
    return this.POST(`/floormaps/${id}/positions`, {
      fov_map: o.fov_map,
      fov_cam: o.fov_cam,
      items,
    })
  }

  //
  // themes
  //

  themes(): Promise<string[]> {
    return this.GET('/themes')
  }

  changeTheme(src: string) {
    return this.PATCH('/themes', { default: src })
  }

  deleteTheme(src: string) {
    const filename = src.split('/').pop()
    return this.DELETE('/themes/' + filename)
  }

  uploadThemeFile(file: Blob, name: string) {
    return this.FILE('/themes', name, file)
  }

  themesSettings(): Promise<ThemesSettings> {
    return this.GET(`/themes/settings`)
  }

  changeThemesSettings(s: ThemesSettings): Promise<null> {
    return this.POST(`/themes/settings`, s)
  }

  //
  // search
  //

  search(s: string): Promise<SearchData> {
    return this.GET('/search?search=' + s)
  }

  //
  //
  //

  drawFileEvents(filename: string, events: Event[], name: string) {
    const body = JSON.stringify({ filename, events })
    return this._handleDraw(body, name)
  }

  drawFrameEvents(frame: string, events: Event[], name: string) {
    const body = JSON.stringify({ frame, events })
    return this._handleDraw(body, name)
  }

  _handleDraw(body: string, name: string) {
    return fetch(this.url('/api/events/draw'), {
      method: 'POST',
      headers: this.headers,
      body: body,
    })
      .then(async (resp) => await resp.blob())
      .then((data: any) => {
        const blob = new Blob([data], { type: 'image/jpeg' })

        const obj = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = obj
        a.target = '_blank'
        a.download = name + '.jpg'
        a.click()

        setTimeout(() => {
          window.URL.revokeObjectURL(obj)
        }, 250)
      })
  }

  //
  // exceptions
  //

  countExceptions(camid?: number): Promise<number> {
    let url = '/schemes/exceptions/count'
    if (camid) url += `?camid=${camid}`

    return this.GET(url)
  }

  exceptions(): Promise<Exception[]> {
    return this.GET('/schemes/exceptions')
  }

  createException(e: Exception): Promise<Success> {
    return this.POST('/schemes/exceptions', e)
  }

  changeException(e: Exception): Promise<Success> {
    return this.POST('/schemes/exceptions/' + e.name, e)
  }

  deleteException(name: string): Promise<Success> {
    return this.DELETE('/schemes/exceptions/' + name)
  }

  //
  // license
  //

  license(): Promise<License> {
    return this.GET('/license')
  }

  activateLicense(serial: string): Promise<License> {
    return this.POST('/license', { serial })
  }

  licensePermission(name: string): Promise<boolean> {
    return this.GET(`/license/permission/${name}`).then((resp) => resp.allow)
  }

  //
  // custom pages
  //

  pages(): Promise<Page[]> {
    return this.GET('/pages')
  }

  page(name: string): Promise<Page> {
    return this.GET('/pages/' + name)
  }

  //
  // packages and repositories
  //

  listRepositories(): Promise<Repository[]> {
    return this.GET('/repository')
  }

  package(rep: string, pkg: string): Promise<Package> {
    return this.GET(`/repository/${rep}/${pkg}`)
  }

  installPacakge(rep: string, pkg: string, auth?: string): Promise<Package> {
    let url = `/repository/${rep}/${pkg}`
    if (auth) url += `?auth=${auth}`

    return this.POST(url)
  }

  cancelInstallPackage(rep: string, pkg: string): Promise<Success> {
    return this.POST(`/repository/${rep}/${pkg}/cancel`)
  }

  uninstallPackage(rep: string, pkg: string): Promise<Success> {
    return this.DELETE(`/repository/${rep}/${pkg}`)
  }

  importPackage(file: Blob): Promise<Package> {
    return this.FILE('/repository/import', 'file', file)
  }

  createPackage(pkg: Package): Promise<Package> {
    return this.POST('/repository/create', pkg)
  }

  exportPackage(pkg: Package): Promise<ExportFile> {
    return this.EXPORT('/repository/export', pkg, {
      filetype: 'vnd/yaml',
      filename: 'package.yaml',
    })
  }

  repository(rep: string): Promise<Repository> {
    return this.GET(`/repository/${rep}`)
  }

  pullRepository(rep: string): Promise<Repository> {
    return this.PATCH(`/repository/${rep}`)
  }

  addRepository(rep: Repository): Promise<Repository> {
    return this.POST('/repository', rep)
  }

  deleteRepository(rep: string): Promise<Success> {
    return this.DELETE(`/repository/${rep}`)
  }

  //
  // journal
  //

  journal(opt: JournalReqOpt) {
    const q = queryString.stringify(opt)
    return this.GET(`/journal?${q}`)
  }

  journalTotal(opt: JournalReqOpt) {
    const q = queryString.stringify(opt)
    return this.GET(`/journal/total?${q}`)
  }

  journalSettings(): Promise<JournalSettings> {
    return this.GET('/journal/settings')
  }

  changeJournalSettings(s: JournalSettings): Promise<JournalSettings> {
    return this.POST('/journal/settings', s)
  }

  //
  // plugins
  //

  plugins(): Promise<Plugin[]> {
    return this.GET('/plugins')
  }

  setPluginSettings(file: string, data: any) {
    return this.POST(`/plugins/${file}`, data)
  }

  //
  // shell
  //

  shells(): Promise<Shell[]> {
    return this.GET('/shell')
  }

  createShell(): Promise<Shell> {
    return this.POST('/shell')
  }

  shell(name: string): Promise<Shell> {
    return this.GET('/shell/' + name)
  }

  deleteShell(name: string): Promise<Shell> {
    return this.DELETE('/shell/' + name)
  }

  shellCmd(name: string, cmd: string) {
    return this.POST('/shell/' + name, { cmd: cmd })
  }

  interruptShell(name: string) {
    return this.POST('/shell/' + name + '/interrupt')
  }

  //
  // changelog
  //

  changelog(lang: string): Promise<string> {
    let u = this.url('/changelog') + '?lang=' + lang

    return fetch(u, {
      method: 'GET',
      headers: this.authHeaders(),
    }).then(async (resp) => {
      if (resp.ok) return await resp.text()
      return await resp.json().then((e) => {
        throw new Error(e.message)
      })
    })
  }

  //
  // activity
  //

  loadActivity(
    section: string,
    id: number,
    date?: string
  ): Promise<ActivityData> {
    let u = `/activity/${section}/${id}`
    if (date) u += `?date=${date}`

    return this.GET(u)
  }

  loadActivityMany(
    section: string,
    id: number[]
  ): Promise<Record<number, ActivityData>> {
    const q = queryString.stringify({ id })
    return this.GET(`/activity/${section}?${q}`)
  }
}

export interface JournalReqOpt {
  offset: number
  limit?: number
  search?: string
  section?: string
}

export interface ReqFrameResponse {
  frame: string
  timems: number
}

import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FaCheck,
  FaCog,
  FaDotCircle,
  FaEyeSlash,
  FaFileUpload,
  FaMap,
  FaPlug,
  FaTimes,
  FaTrash,
  FaVectorSquare,
  FaVideo,
} from 'react-icons/fa'
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Dropdown,
  Footer,
  Form,
  Grid,
  IconButton,
  Input,
  Message,
  Panel,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  Stack,
  Tooltip,
  Uploader,
  Whisper,
} from 'rsuite'

import { CameraPlayer, Canvas } from 'components/cameras'
import { DevicePanel } from 'components/devices'
import Content, {
  ContentState,
  Header,
  HeaderLeft,
  HeaderTitle,
  Toolbar,
  alert,
  setTitle,
} from 'content'
import ResizeDetector from 'react-resize-detector'
import ROUTES from 'routes'
import RTMIP, {
  Camera,
  Device,
  FloorMap,
  FloorMapObject,
  Point,
  Region,
  User,
} from 'rtmip'
import FloorMapCanvas from './floormapcanvas'
import {
  ITEMSVIEW_DOT,
  ITEMSVIEW_HIDE,
  OBJ_CAMERA,
  OBJ_DEVICE,
  TYPE_IMAGE,
  TYPE_MAP,
  settings,
} from './utils'

interface RouteParams {
  id?: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  inSave: boolean
  inCancel: boolean

  fmap: FloorMap
  user: User
  initial: string

  selected?: FloorMapObject
  redirect?: string

  cameras: Camera[]
  devices: Device[]
  openCameras: Record<number, Camera>
  openDevices: Record<number, Device>

  expanded?: Camera

  camSize?: { w: number; h: number }
}

const TYPES = [TYPE_MAP, TYPE_IMAGE]

class FloorMapForm extends React.Component<Props, State> {
  state = {
    fmap: { name: '', desc: '', objects: [] as FloorMapObject[] } as FloorMap,
    openCameras: {},
    openDevices: {},
  } as State

  interval = 0
  // refImage = React.createRef<any>()
  refCanvas = React.createRef<any>()
  refCameras = React.createRef<any>()
  refDevices = React.createRef<any>()

  timeZone = 0

  constructor(props: Props) {
    super(props)

    this.state.fmap.id = parseInt(props.match.params.id || 'new') || 0
    document.addEventListener('keydown', this.hotkeys)
  }

  componentDidMount() {
    const { t } = this.props

    setTitle('floormaps.title')

    if (this.state.fmap.id > 0) {
      this.loadFloormap()
    } else {
      this.setState({
        title: t('floormaps.add'),
        loaded: true,
      })
    }

    this.loadCameras()
    this.loadDevices()

    RTMIP.user().then((user) => this.setState({ user }))
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
    document.removeEventListener('keydown', this.hotkeys)
  }

  hotkeys = (e: any) => {
    const { selected } = this.state

    if (selected && (e.key === 'Backspace' || e.key === 'Delete')) {
      this.handleRemove(selected)
      return false
    }

    return true
  }

  loadFloormap = () => {
    RTMIP.floormap(this.state.fmap.id)
      .then(this.setFloormap)
      .catch(this.setError)
  }

  setFloormap = (fmap: FloorMap) => {
    setTitle('floormaps.title', fmap.name)

    if (!fmap.objects) fmap.objects = []

    this.setState({
      fmap: fmap,
      initial: JSON.stringify(fmap),
      title: fmap.name,
      loaded: true,
    })
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  loadCameras = () => {
    RTMIP.cameras()
      .then((cameras) => this.setState({ cameras }))
      .catch(alert)
  }

  loadDevices = () => {
    RTMIP.devices()
      .then((devices) => this.setState({ devices }))
      .catch(alert)
  }

  //
  // handlers
  //

  isChanged = (): boolean => {
    return (
      JSON.stringify(this.state.fmap, (key, val) => {
        if (key === 'refCount') return
        return val
      }) !== this.state.initial
    )
  }

  handleForm = (p: Record<string, any>, e: any) => {
    Object.assign(this.state.fmap, p)
    this.setState({})
  }

  handleInput = (val: any, key: string) => {
    Object.assign(this.state.fmap, { [key]: val })
    this.setState({})
  }

  handleSave = () => {
    const { fmap } = this.state

    if (fmap.id > 0) {
      RTMIP.changeFloormap(fmap.id, fmap).then(this.setFloormap).catch(alert)
    } else {
      RTMIP.createFloormap(fmap)
        .then((fmap) => {
          const urlpath = path.join(ROUTES.floormaps, fmap.id.toString())
          window.history.pushState(fmap, 'floormaps', urlpath)

          this.setFloormap(fmap)
        })
        .catch(alert)
    }
  }

  handleUploadImage = (resp: any) => {
    const { fmap } = this.state
    resp.map_image += '?' + new Date().getTime()

    Object.assign(fmap, resp)
    this.setState({ fmap })
  }

  handleSelect = (o?: FloorMapObject) => {
    const { selected, fmap } = this.state
    fmap.objects?.forEach((o) => {
      if (o !== selected) o.edit = false
    })

    this.setState({ selected: o })
  }

  handleObjectDoubleClick = (o: FloorMapObject) => {
    switch (o.type) {
      case OBJ_CAMERA:
        return this.handleOpenCamera(o.id)
      case OBJ_DEVICE:
        return this.handleOpenDevice(o.id)
    }
  }

  handleOpenCamera = (id: number) => {
    const { openCameras, cameras } = this.state

    if (openCameras[id]) {
      delete openCameras[id]
    } else {
      const cam = cameras.find((cam) => cam.id === id)
      if (cam) openCameras[id] = cam
    }

    this.setState({ openCameras })
  }

  handleOpenDevice = (id: number) => {
    const { openDevices, devices } = this.state

    if (openDevices[id]) {
      delete openDevices[id]
    } else {
      const dev = devices.find((dev) => dev.id === id)
      if (dev) openDevices[id] = dev
    }

    this.setState({ openDevices })
  }

  handleEdit = (o?: FloorMapObject) => {
    const { objects } = this.state.fmap
    objects.forEach((_o) => {
      if (o && o != _o) _o.edit = false
    })

    if (!o) return
    o.edit = !o.edit
    this.setState({})
  }

  handleRemove = (o: FloorMapObject) => {
    const { fmap } = this.state

    const i = fmap.objects.findIndex(
      (fo) => fo.type === o.type && fo.id === o.id
    )
    if (i > -1) fmap.objects.splice(i, 1)

    this.setState({ selected: undefined })
  }

  handleAddBlock = (type: string, id: number, name: string) => {
    const { fmap } = this.state

    const position = this.refCanvas.current.getNewObjectPosition()

    const obj = {
      id,
      name,
      type,
      position,
      rotation: 0,
    } as FloorMapObject

    fmap.objects.push(obj)

    this.setState({})
  }

  handleClickCameras = () => {
    this.refCameras.current.open()
  }

  handleAddCamera = (id: any) => {
    const { cameras } = this.state
    const cam = cameras.find((cam: Camera) => cam.id === id)
    if (!cam) return

    this.handleAddBlock('camera', id, cam.name)
  }

  handleClickDevices = () => {
    this.refDevices.current.open()
  }

  handleAddDevice = (id: any) => {
    const { devices } = this.state
    const dev = devices.find((dev: Device) => dev.id === id)
    if (!dev) return

    this.handleAddBlock('device', id, dev.name)
  }

  handleCameraSize = (w?: number, h?: number) => {
    if (!w || !h) this.setState({ camSize: undefined })
    else this.setState({ camSize: { w, h } })
  }

  handleDrawFoVOnMap = (points: Point[]) => {
    const { selected } = this.state
    if (!selected) return

    selected.fov_map = points
    this.setState({ selected })
  }

  handleDrawFoVOnCam = (points: Point[]) => {
    const { selected } = this.state
    if (!selected) return

    selected.fov_cam = points
    this.setState({ selected })
  }

  handleExpandCamera = (cam?: Camera) => {
    const { expanded } = this.state

    if (expanded === cam) this.setState({ expanded: undefined })
    else this.setState({ expanded: cam })
  }

  //
  // data
  //

  getCameras = (): Camera[] => {
    const { fmap, cameras } = this.state
    if (!fmap.objects || !cameras) return cameras || ([] as Camera[])

    const resp =
      cameras.filter((cam) => {
        return !fmap.objects.some(
          (o) => o.type === OBJ_CAMERA && o.id === cam.id
        )
      }) || []

    return resp
  }

  getDevices = (): Device[] => {
    const { fmap, devices } = this.state
    if (!fmap.objects || !devices) return devices || ([] as Camera[])

    return (
      devices.filter((dev) => {
        return !fmap.objects.some(
          (o) => o.type === OBJ_DEVICE && o.id === dev.id
        )
      }) || []
    )
  }

  toRegion = (points?: Point[], edit?: boolean) => {
    return {
      id: 'fov_cam',
      points: points || [],
      color: [104, 97, 181, 0.47],
      edit,
    } as Region
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, fmap, redirect } = this.state

    if (redirect) return <Redirect push to={redirect} />

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <Panel className='content-panel script-panel'>
          <Form>
            <Grid fluid>
              <Row gutter={10}>
                <Col md={6}>
                  <Form.Group>
                    <Form.ControlLabel>{t('name')}</Form.ControlLabel>
                    <Input
                      value={fmap.name || ''}
                      placeholder={t('name')}
                      onChange={(v) => this.handleInput(v, 'name')}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={18}>
                  <Form.Group>
                    <Form.ControlLabel>{t('desc')}</Form.ControlLabel>
                    <Input
                      value={fmap.desc || ''}
                      placeholder={t('desc')}
                      onChange={(v) => this.handleInput(v, 'desc')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row gutter={10}>
                <Col md={24}>
                  <Form.Group>
                    <Form.ControlLabel>{t('type')}</Form.ControlLabel>
                    <RadioGroup
                      as={Stack}
                      justifyContent='center'
                      className='form-type-selector'
                      appearance='picker'
                      inline
                      onChange={(v) => this.handleInput(v, 'type')}
                      value={fmap.type || 'img'}>
                      {TYPES.map((type) => (
                        <Radio key={type} value={type}>
                          {type}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row gutter={10} style={{ position: 'relative', zIndex: 300 }}>
                <Col md={24}>{this.renderMenu()}</Col>
              </Row>

              <Row gutter={10}>
                <Col md={24}>{this.renderMap()}</Col>
              </Row>
            </Grid>
            {this.renderFooter()}
          </Form>
        </Panel>

        {this.renderWidgets()}
      </Content>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { title, fmap } = this.state

    return (
      <Header
        left={<HeaderLeft back={ROUTES.floormaps}></HeaderLeft>}
        right={
          <ButtonGroup>
            <Link to={path.join(ROUTES.floormaps, fmap.id.toString())}>
              <Whisper
                trigger='hover'
                placement='bottomEnd'
                speaker={<Tooltip>{t('floormaps.view_mode')}</Tooltip>}>
                <IconButton
                  icon={<FaMap />}
                  disabled={!fmap.map_image}
                  circle
                />
              </Whisper>
            </Link>
          </ButtonGroup>
        }>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
    )
  }

  renderMap() {
    const { t } = this.props
    const { fmap, selected } = this.state

    if (!fmap.id) {
      return <Message showIcon>{t('floormaps.init_message')}</Message>
    }

    return (
      <FloorMapCanvas
        subref={this.refCanvas}
        fmap={fmap}
        onSelect={this.handleSelect}
        onObjectDoubleClick={this.handleObjectDoubleClick}
        onDrawFOVOnMap={
          selected && selected.edit ? this.handleDrawFoVOnMap : undefined
        }
        selected={selected}
        editable={selected !== undefined}
      />
    )
  }

  renderMenu() {
    const { t } = this.props
    const { fmap, selected, cameras, devices } = this.state

    return (
      <div style={{ zIndex: 99, position: 'relative' }}>
        <Toolbar
          label={t('floormaps.place')}
          right={
            <>
              <ButtonGroup>
                {selected &&
                  selected.type === OBJ_CAMERA &&
                  fmap.type === TYPE_IMAGE && (
                    <Whisper
                      trigger='hover'
                      speaker={<Tooltip>{t('floormaps.edit_fov')}</Tooltip>}>
                      <IconButton
                        key='edit'
                        appearance={selected.edit ? 'primary' : 'default'}
                        onClick={() => this.handleEdit(selected)}
                        icon={<FaVectorSquare />}
                      />
                    </Whisper>
                  )}
                {selected && (
                  <IconButton
                    key='delete'
                    appearance='primary'
                    color='red'
                    onClick={() => this.handleRemove(selected)}
                    icon={<FaTrash />}
                  />
                )}
              </ButtonGroup>
              {fmap.type === TYPE_IMAGE && (
                <ButtonGroup>
                  {this.renderViewSettings()}
                  {(fmap.type === TYPE_IMAGE || !fmap.type) && (
                    <Uploader
                      className='uploader-toolbar-lastbtn'
                      action={RTMIP.url(`/api/floormaps/${fmap.id}/upload`)}
                      headers={RTMIP.authHeaders()}
                      onSuccess={this.handleUploadImage}
                      onError={(r) => alert(r.response.error)}
                      accept='image/*'
                      fileListVisible={false}>
                      <Whisper
                        trigger='hover'
                        placement='top'
                        speaker={
                          <Tooltip>{t('floormaps.upload_image')}</Tooltip>
                        }>
                        <IconButton icon={<FaFileUpload />} />
                      </Whisper>
                    </Uploader>
                  )}
                </ButtonGroup>
              )}
            </>
          }>
          <ButtonGroup>
            <Whisper
              trigger='hover'
              placement='top'
              speaker={<Tooltip>{t('floormaps.place_camera')}</Tooltip>}>
              <IconButton
                icon={<FaVideo />}
                onClick={this.handleClickCameras}
                disabled={
                  !cameras ||
                  !cameras.length ||
                  (fmap.type === TYPE_IMAGE && !fmap.map_image)
                }
              />
            </Whisper>
            <SelectPicker
              ref={this.refCameras}
              className='floormaps-picker'
              data={this.getCameras()}
              labelKey='name'
              valueKey='id'
              placement='bottom'
              cleanable={false}
              value={0}
              onChange={this.handleAddCamera}
            />

            <SelectPicker
              ref={this.refDevices}
              className='floormaps-picker'
              data={this.getDevices()}
              labelKey='name'
              valueKey='id'
              placement='bottom'
              cleanable={false}
              value={0}
              onChange={this.handleAddDevice}
            />
            <Whisper
              trigger='hover'
              placement='top'
              speaker={<Tooltip>{t('floormaps.place_device')}</Tooltip>}>
              <IconButton
                icon={<FaPlug />}
                onClick={this.handleClickDevices}
                disabled={
                  !devices ||
                  !devices.length ||
                  (fmap.type === TYPE_IMAGE && !fmap.map_image)
                }
              />
            </Whisper>
          </ButtonGroup>
        </Toolbar>
      </div>
    )
  }

  renderFooter() {
    const { t } = this.props
    const { fmap } = this.state

    return (
      <Footer className='footer'>
        <ButtonToolbar justifyContent='flex-end'>
          {fmap.id > 0 && (
            <Button
              disabled={!this.isChanged()}
              onClick={this.loadFloormap}
              loading={this.state.inCancel}>
              {t('cancel')}
            </Button>
          )}

          <Button
            appearance='primary'
            type='submit'
            disabled={!fmap.name}
            onClick={this.handleSave}
            loading={this.state.inSave}>
            {t('save')}
          </Button>
        </ButtonToolbar>
      </Footer>
    )
  }

  //
  // view settings
  //

  toggleCamerasView = () => {
    settings.toggleCamerasView()
    this.setState({})
  }

  toggleItemsView = () => {
    settings.toggleItemsView()
    this.setState({})
  }

  renderViewSettings = () => {
    const { t } = this.props
    const camview = settings.getCamerasView()

    return (
      <Dropdown
        placement='bottomEnd'
        renderToggle={(props) => <IconButton {...props} icon={<FaCog />} />}>
        <Dropdown.Item
          onClick={this.toggleCamerasView}
          icon={camview ? <FaCheck /> : <FaEyeSlash />}>
          {t('floormaps.camerasview')}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={this.toggleItemsView}
          icon={this.renderItemViewIcon()}>
          {t('floormaps.itemsview')}
        </Dropdown.Item>
      </Dropdown>
    )
  }

  renderItemViewIcon = () => {
    const itemview = settings.getItemsView()
    if (itemview === ITEMSVIEW_HIDE) return <FaEyeSlash />
    if (itemview === ITEMSVIEW_DOT) return <FaDotCircle />
    return <FaVectorSquare />
  }

  //
  // render objects
  //

  renderWidgets() {
    const { openCameras, openDevices, expanded } = this.state

    if (expanded) {
      return (
        <div className='floormaps-widgets'>{this.renderCamera(expanded)}</div>
      )
    }

    return (
      <div
        className='floormaps-widgets'
        data-devices={Object.keys(openDevices).length > 0}
        data-cameras={Object.keys(openCameras).length}>
        <div className='floormaps-cameras'>
          {Object.values(openCameras).map(this.renderCamera)}
        </div>
        <div>{Object.values(openDevices).map(this.renderDevice)}</div>
      </div>
    )
  }

  renderCamera = (cam: Camera) => {
    const { selected, camSize, expanded } = this.state

    return (
      <div key={cam.id} className='floormap-wgt'>
        <div className='camera'>
          <ResizeDetector
            onResize={this.handleCameraSize}
            handleWidth
            handleHeight>
            <CameraPlayer
              camera={cam}
              header={{ edit: true, open: true, events: true }}
              expanded={expanded === cam}
              onExpand={() => this.handleExpandCamera(cam)}
            />
          </ResizeDetector>
          {selected &&
            selected.type === OBJ_CAMERA &&
            selected.id === cam.id &&
            camSize && (
              <div
                className='camera-canvas'
                style={{ zIndex: selected.edit ? 99 : 1 }}
                data-interaction={selected.edit}>
                <Canvas
                  key='fov_canvas'
                  width={camSize.w}
                  height={camSize.h}
                  onDraw={selected.edit ? this.handleDrawFoVOnCam : undefined}
                  regions={[this.toRegion(selected.fov_cam, selected.edit)]}
                  showLabels
                />
              </div>
            )}
        </div>

        <IconButton
          className='floormap-wgt-close-btn'
          appearance='subtle'
          icon={<FaTimes />}
          onClick={() => this.handleOpenCamera(cam.id)}
          size='xs'
          circle
        />
      </div>
    )
  }

  renderDevice = (dev: Device) => {
    return (
      <div key={dev.id} className='floormap-wgt floormap-wgt_device'>
        <DevicePanel {...dev} />
        <IconButton
          className='floormap-wgt-close-btn'
          icon={<FaTimes />}
          onClick={() => this.handleOpenDevice(dev.id)}
          size='xs'
          circle
        />
      </div>
    )
  }
}

export default withTranslation()(withRouter(FloorMapForm))

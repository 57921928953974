import { Icon } from '@rsuite/icons'
import path from 'path'
import React from 'react'
import { BlockPicker } from 'react-color'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaCheck,
  FaCircle,
  FaCopy,
  FaPlus,
  FaTable,
  FaTimes,
} from 'react-icons/fa'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Checkbox,
  Col,
  Footer,
  Form,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputPicker,
  Panel,
  Radio,
  RadioGroup,
  Row,
  Stack,
  Tag,
  TagGroup,
  TagPicker,
} from 'rsuite'
import { v4 as uuidv4 } from 'uuid'

import Content, {
  CenterRow,
  CheckButtonGroup,
  ContentState,
  DurationPicker,
  Header,
  HeaderLeft,
  Help,
  alert,
  setTitle,
} from 'content'
import ROUTES from 'routes'
import RTMIP, { Camera, Chart, LabelColor, Scheme, Script } from 'rtmip'
import './charts.less'
import ChartWidget, { colorPalette } from './chartwidget'

export const TYPE_LINE = 'line'
export const TYPE_BAR = 'bar'
export const TYPE_PIE = 'pie'
export const TYPE_TABLE = 'table'

const CAST_SUM = 'sum'
const CAST_AVG = 'avg'
const CAST_MAX = 'max'
const CAST_SCRIPT = 'script'

const cast_script_example = `function cast(data, label) {
  var values = []
  
  for (var i in data) {
    var sum = 0
    for (var j in data[i]) sum += data[i][j]
    values.push(sum)
  }
  
  return values
}`

interface RouteParams {
  id?: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  inSave: boolean
  inCancel: boolean

  chart: Chart
  initial: string

  types: { type: string }[]
  cameras: Camera[]
  sectors: { name: string }[]
  scripts: Script[]
  schemes: Scheme[]

  editColor?: number
}

class ChartForm extends React.Component<Props, State> {
  state = {
    chart: {
      name: '',
      type: TYPE_LINE,
      duration: 86400,
      interval: 3600,
      rate: 600,
      cast: CAST_MAX,
    } as Chart,
  } as State
  interval = 0

  refChart = React.createRef<any>()

  constructor(props: Props) {
    super(props)
    this.state.chart.id = parseInt(props.match.params.id || 'new') || 0
  }

  componentDidMount() {
    const { t } = this.props

    setTitle('charts.title')

    if (this.state.chart.id > 0) {
      this.loadChart()
    } else {
      this.setState({
        title: t('charts.add_chart'),
        loaded: true,
      })
    }

    this.loadTypes()
    this.loadCameras()
    this.loadSectors()
    this.loadScripts()
    this.loadSchemes()
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadChart = () => {
    RTMIP.chart(this.state.chart.id).then(this.setChart).catch(this.setError)
  }

  setChart = (c: Chart) => {
    setTitle('charts.title', c.name)

    this.setState({
      chart: c,
      initial: JSON.stringify(c),
      title: c.name,
      loaded: true,
    })

    this.loadTypes()
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  // load data

  loadTypes = () => {
    RTMIP.detectorsTypes()
      .then((types) => {
        if (!types) types = []

        const { chart } = this.state
        if (chart.types) {
          types = Array.from(new Set(chart.types.concat(types)))
        }
        this.setState({ types: types.map((t) => ({ type: t })) })
      })
      .catch(alert)
  }

  loadScripts = () => {
    RTMIP.scripts()
      .then((scripts) => {
        this.setState({ scripts: scripts || [] })
      })
      .catch(alert)
  }

  loadSectors = () => {
    RTMIP.sectors()
      .then((sectors) =>
        this.setState({ sectors: sectors?.map((s) => ({ name: s })) })
      )
      .catch(alert)
  }

  loadCameras = () => {
    RTMIP.cameras()
      .then((cameras) => {
        this.setState({ cameras: cameras || [] })
      })
      .catch(alert)
  }

  loadSchemes = () => {
    RTMIP.schemes()
      .then((schemes) => {
        this.setState({ schemes: schemes || [] })
      })
      .catch(alert)
  }

  //
  // handlers
  //

  isChanged = (): boolean => {
    return JSON.stringify(this.state.chart) !== this.state.initial
  }

  handleForm = (p: Record<string, any>, e: any) => {
    Object.assign(this.state.chart, p)
    this.setState({})
  }

  handleInput = (val: any, key: string) => {
    Object.assign(this.state.chart, { [key]: val })
    this.setState({})
  }

  handleSave = () => {
    const { chart } = this.state

    chart.script_id = parseInt(chart.script_id as any) || 0

    if (chart.id > 0) {
      RTMIP.changeChart(chart.id, chart)
        .then((c) => {
          this.setChart(c)
          if (this.refChart.current) this.refChart.current.loadData()
        })
        .catch(alert)
    } else {
      RTMIP.createChart(chart)
        .then((c: Chart) => {
          const urlpath = path.join(ROUTES.charts, c.id.toString())
          window.history.pushState(c, 'charts', urlpath)

          this.setChart(c)
        })
        .catch(alert)
    }
  }

  handleCopy = () => {
    const { t } = this.props
    const { chart } = this.state
    chart.id = 0
    chart.uuid = uuidv4()

    this.setState({
      chart,
      title: t('charts.add_chart'),
    })

    const urlpath = path.join(ROUTES.charts, 'new')
    window.history.pushState(chart, 'charts', urlpath)
  }

  // palette

  createPaletteColor = () => {
    const { chart } = this.state

    if (!chart.colors) chart.colors = [...colorPalette]

    const color = Math.floor(Math.random() * 16777215).toString(16)
    chart.colors.push('#' + color)

    this.setState({})
  }

  changePaletteColor = (i: number) => {
    const { editColor } = this.state

    this.setState({ editColor: editColor === i ? undefined : i })
  }

  handleChangePaletteColor = (c: string, i: number) => {
    const { chart } = this.state
    if (!chart.colors) chart.colors = [...colorPalette]

    chart.colors[i] = c
    this.setState({ editColor: undefined })
  }

  // label_colors

  createLabelColors = () => {
    const { chart, types } = this.state
    if (!chart.label_colors) chart.label_colors = []

    let label = 'label'
    if (chart.types?.length) {
      let type = chart.types.find(
        (color) => !chart.label_colors.some((c) => c.color === color)
      )
      if (type) label = type
    }

    if (types?.length && label === 'label') {
      label = types[Math.floor(Math.random() * types.length)].type
    }

    chart.label_colors.push({
      label: label,
      color: str2color(label),
    } as LabelColor)

    this.setState({})
  }

  editLabelColors = (c: LabelColor) => {
    const { chart } = this.state

    chart.label_colors.forEach((color) => {
      if (color !== c) color._edit = false
    })
    c._edit = !c._edit

    this.setState({})
  }

  changeLabelColors = (c: LabelColor, data: Record<string, string>) => {
    Object.assign(c, data)
    this.setState({})
  }

  removeLabelColor = (i: number) => {
    const { chart } = this.state
    chart.label_colors.splice(i, 1)

    this.setState({})
  }

  //
  // render
  //

  render() {
    const { loaded, error, chart } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <CenterRow>
          {chart.id > 0 && (
            <Panel>
              <ChartWidget
                ref={this.refChart}
                height={300}
                btns={{ save: true }}
                {...chart}
              />
            </Panel>
          )}
          <Panel>{this.renderForm()}</Panel>
        </CenterRow>
      </Content>
    )
  }

  renderHeader() {
    const { title } = this.state

    return (
      <Header left={<HeaderLeft back={ROUTES.charts}></HeaderLeft>}>
        <h4 className='content-title'>{title}</h4>
      </Header>
    )
  }

  renderForm() {
    const { t } = this.props
    const { chart, types, scripts, cameras, sectors, schemes } = this.state

    return (
      <Form
        formValue={chart}
        onChange={this.handleForm}
        autoComplete='off'
        fluid>
        <Grid fluid>
          <Row>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('name')}</Form.ControlLabel>
                <Form.Control name='name' />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('type')}</Form.ControlLabel>
                <RadioGroup
                  as={Stack}
                  value={chart.type || ''}
                  name='type'
                  appearance='picker'
                  justifyContent='center'
                  className='chart-radio-type'
                  onChange={(v) => this.handleInput(v, 'type')}
                  inline>
                  <Radio value={TYPE_LINE}>
                    <Icon as={FaChartLine} />
                  </Radio>
                  <Radio value={TYPE_BAR}>
                    <Icon as={FaChartBar} />
                  </Radio>
                  <Radio value={TYPE_PIE}>
                    <Icon as={FaChartPie} />
                  </Radio>
                  <Radio value={TYPE_TABLE}>
                    <Icon as={FaTable} />
                  </Radio>
                </RadioGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.duration')}</Form.ControlLabel>
                <DurationPicker
                  value={chart.duration}
                  onChange={(v) => this.handleInput(v, 'duration')}
                  min={3600}
                  days
                  hours
                />
              </Form.Group>
            </Col>

            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.interval')}</Form.ControlLabel>
                <DurationPicker
                  value={chart.interval}
                  onChange={(v) => this.handleInput(v, 'interval')}
                  min={60}
                  max={86400}
                  hours
                  minutes
                />
              </Form.Group>
            </Col>

            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.rate')}</Form.ControlLabel>
                <DurationPicker
                  value={chart.rate}
                  onChange={(v) => this.handleInput(v, 'rate')}
                  min={60}
                  hours
                  minutes
                />
              </Form.Group>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>
                  {t('charts.cast')}
                  <Help>{t('charts.help.cast')}</Help>
                </Form.ControlLabel>
                <CheckButtonGroup
                  data={chart}
                  name='cast'
                  onClick={this.handleInput}
                  buttons={[
                    { value: CAST_SUM, text: CAST_SUM.toUpperCase() },
                    { value: CAST_AVG, text: CAST_AVG.toUpperCase() },
                    { value: CAST_MAX, text: CAST_MAX.toUpperCase() },
                    { value: CAST_SCRIPT, text: CAST_SCRIPT.toUpperCase() },
                  ]}
                />
                {chart.cast === CAST_SCRIPT && (
                  <div className='chart-cast_script-example'>
                    {t('charts.cast_example')}
                    <pre>{cast_script_example}</pre>
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.options')}</Form.ControlLabel>
                <Checkbox
                  inline
                  checked={chart.daily_limit}
                  onChange={(_, c) => this.handleInput(c, 'daily_limit')}>
                  {t('charts.daily_limit')}
                </Checkbox>
                <Checkbox
                  inline
                  checked={chart.sum_values}
                  onChange={(_, c) => this.handleInput(c, 'sum_values')}>
                  {t('charts.sum_values')}
                </Checkbox>
                <Checkbox
                  inline
                  checked={chart.split_values}
                  onChange={(_, c) => this.handleInput(c, 'split_values')}>
                  {t('charts.split_values')}
                </Checkbox>
                {[TYPE_LINE, TYPE_BAR, TYPE_PIE].includes(chart.type) && (
                  <Checkbox
                    inline
                    checked={chart.legend}
                    onChange={(_, c) => this.handleInput(c, 'legend')}>
                    {t('charts.show_legend')}
                  </Checkbox>
                )}
                {[TYPE_LINE, TYPE_BAR].includes(chart.type) && (
                  <Checkbox
                    inline
                    checked={chart.stacked}
                    onChange={(_, c) => this.handleInput(c, 'stacked')}>
                    {t('charts.stacked')}
                  </Checkbox>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.types')}</Form.ControlLabel>
                <TagPicker
                  value={chart.types}
                  data={types}
                  labelKey='type'
                  valueKey='type'
                  onChange={(v) => this.handleInput(v, 'types')}
                  onOpen={this.loadTypes}
                  creatable
                  block
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.ControlLabel>
                  {t('charts.script')}
                  <Help>{t('charts.help.script')}</Help>
                </Form.ControlLabel>
                <InputPicker
                  value={chart.script_id}
                  data={scripts || []}
                  labelKey='name'
                  valueKey='id'
                  onChange={(v) => this.handleInput(v || 0, 'script_id')}
                  onOpen={this.loadScripts}
                  block
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.sectors')}</Form.ControlLabel>
                <TagPicker
                  value={chart.sectors}
                  data={sectors}
                  labelKey='name'
                  valueKey='name'
                  onChange={(v) => this.handleInput(v, 'sectors')}
                  onOpen={this.loadSectors}
                  block
                />
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.cameras')}</Form.ControlLabel>
                <TagPicker
                  value={chart.cameras_id}
                  data={cameras}
                  labelKey='name'
                  valueKey='id'
                  onChange={(v) => this.handleInput(v, 'cameras_id')}
                  onOpen={this.loadCameras}
                  block
                />
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.analytics')}</Form.ControlLabel>
                <TagPicker
                  value={chart.analytics_id}
                  data={schemes}
                  labelKey='name'
                  valueKey='id'
                  onChange={(v) => this.handleInput(v, 'analytics_id')}
                  onOpen={this.loadSchemes}
                  block
                />
              </Form.Group>
            </Col>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('charts.sql')}</Form.ControlLabel>
                <Input
                  as='textarea'
                  rows={3}
                  placeholder='profile_id != 0 OR car_id != 0'
                  value={chart.sql}
                  onChange={(v) => this.handleInput(v, 'sql')}
                />
              </Form.Group>
            </Col>
          </Row>

          {this.renderPalette()}
          {this.renderLabelColors()}
        </Grid>

        {this.renderFooter()}
      </Form>
    )
  }

  renderPalette() {
    const { t } = this.props
    const { chart, editColor } = this.state
    if (chart.type === TYPE_TABLE) return

    const colors = chart.colors || colorPalette

    return (
      <Row>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('charts.palette')}</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Button onClick={this.createPaletteColor}>
                <FaPlus />
              </InputGroup.Button>
              <TagGroup className='chart-colors'>
                {colors.map((c: string, i: number) => (
                  <div
                    key={i}
                    style={{ position: 'relative', display: 'inline-block' }}>
                    <Icon
                      as={FaCircle}
                      className='chart-color'
                      style={{
                        color: c,
                        cursor: 'pointer',
                      }}
                      onClick={() => this.changePaletteColor(i)}
                    />
                    {editColor === i && (
                      <BlockPicker
                        className='cameras-regioncolor'
                        color={c}
                        colors={[
                          '#FFFFFF',
                          '#377CD7',
                          '#F65151',
                          '#F6D765',
                          '#61AB4F',
                        ]}
                        onChange={(c) =>
                          this.handleChangePaletteColor(c.hex, i)
                        }
                      />
                    )}
                  </div>
                ))}
              </TagGroup>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderLabelColors() {
    const { t } = this.props
    const { chart } = this.state
    if (chart.type === TYPE_TABLE) return

    return (
      <Row>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('charts.label_colors')}</Form.ControlLabel>
            <InputGroup>
              <InputGroup.Button onClick={this.createLabelColors}>
                <FaPlus />
              </InputGroup.Button>
              <TagGroup className='chart-colors'>
                {chart.label_colors?.map((c: LabelColor, i: number) => (
                  <Tag
                    key={i}
                    className='chart-label_color'
                    data-edit={c._edit ? 'true' : 'false'}>
                    <Icon
                      as={FaCircle}
                      style={{
                        color: c.color,
                        cursor: 'pointer',
                      }}
                      onClick={() => this.editLabelColors(c)}
                    />
                    {c._edit && (
                      <BlockPicker
                        className='cameras-regioncolor'
                        color={c.color}
                        colors={[
                          '#FFFFFF',
                          '#377CD7',
                          '#F65151',
                          '#F6D765',
                          '#61AB4F',
                        ]}
                        onChange={(v) =>
                          this.changeLabelColors(c, { color: v.hex })
                        }
                      />
                    )}
                    {c._edit ? (
                      <Input
                        className='tag-input'
                        value={c.label}
                        onChange={(v: string) =>
                          this.changeLabelColors(c, { label: v })
                        }
                      />
                    ) : (
                      <span
                        className='tag-input'
                        onClick={() => this.editLabelColors(c)}>
                        {c.label}
                      </span>
                    )}

                    {c._edit ? (
                      <Icon
                        className='icon-check'
                        as={FaCheck}
                        onClick={() => this.editLabelColors(c)}
                      />
                    ) : (
                      <Icon
                        className='icon-close'
                        as={FaTimes}
                        onClick={() => this.removeLabelColor(i)}
                      />
                    )}
                  </Tag>
                ))}
              </TagGroup>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderFooter() {
    const { t } = this.props
    const { chart } = this.state
    return (
      <Footer className='footer'>
        <ButtonToolbar justifyContent='flex-end'>
          {chart.id > 0 && (
            <Button
              disabled={!this.isChanged()}
              onClick={this.loadChart}
              loading={this.state.inCancel}>
              {t('cancel')}
            </Button>
          )}

          <ButtonGroup>
            <Button
              appearance='primary'
              type='submit'
              disabled={!chart.name}
              onClick={this.handleSave}
              loading={this.state.inSave}>
              {t('save')}
            </Button>
            <IconButton onClick={this.handleCopy} icon={<FaCopy />} />
          </ButtonGroup>
        </ButtonToolbar>
      </Footer>
    )
  }
}

export default withTranslation()(withRouter(ChartForm))

function str2color(s: string) {
  let hash = 0
  for (let i = 0; i < s.length; i++) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash)
  }

  let c = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    c += ('00' + value.toString(16)).substr(-2)
  }
  return c
}

import path from 'path'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Stack } from 'rsuite'

interface Props {
  to?: any[]
  children: string
  disabled?: boolean
}

export default class TableFooter extends React.Component<Props> {
  render() {
    const { to, children, disabled } = this.props

    let url = path.join(window.location.pathname, 'new')
    if (to) url = path.join(...to)

    return (
      <Stack className='footer' justifyContent='center'>
        <Button as={Link} to={url} appearance='primary' disabled={disabled}>
          {children}
        </Button>
      </Stack>
    )
  }
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import path from 'path'

import CONFIG from 'config'

export default i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: false,
    // fallbackLng: 'en',
    supportedLngs: CONFIG.LANGUAGES,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: path.join(
        process.env.PUBLIC_URL,
        `/locales/{{lng}}.json?version=${new Date().valueOf()}`
      ),
    },
  })

export function trans(key: string, prefix?: string): string {
  if (prefix) {
    const prefixKey = `${prefix}.${key}`
    return i18n.exists(prefixKey) ? i18n.t(prefixKey) : key
  }

  return i18n.exists(key) ? i18n.t(key) : key
}

const TRANSLATION_ITEMS_PREFIX = 'items'

export function itemsTranslate(s: string): string {
  if (!s) return ''

  const key = `${TRANSLATION_ITEMS_PREFIX}.${s.toLowerCase()}`
  return i18n.exists(key) ? i18n.t(key) : s
}

export class Translate {
  prefix? = ''

  constructor(prefix?: string) {
    this.prefix = prefix
  }

  lang = (): string => {
    return i18n.language
  }

  t = (key: string): string => {
    if (this.prefix) return i18n.t(this.prefix + '.' + key)
    return i18n.t(key)
  }

  exists = (key: string): boolean => {
    if (this.prefix) return i18n.exists(this.prefix + '.' + key)
    return i18n.exists(key)
  }
}

declare global {
  interface Window {
    i18n: Translate
  }
}

window.i18n = new Translate()

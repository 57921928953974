import { Icon } from '@rsuite/icons'
import SpinnerIcon from '@rsuite/icons/legacy/Spinner'
import moment from 'moment-timezone'
import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCopy, FaSearch } from 'react-icons/fa'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Divider,
  Footer,
  Form,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  Panel,
  Popover,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  Stack,
  Tag,
  Whisper,
} from 'rsuite'

import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/ext-beautify'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-searchbox'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-xml'
import 'ace-builds/src-noconflict/theme-monokai'

import Content, {
  CenterRow,
  CheckButtonGroup,
  ContentState,
  Errors,
  Header,
  HeaderLeft,
  HeaderTitle,
  Help,
  SettingsInput,
  StatPanel,
  alert,
  info,
  setTitle,
  success,
} from 'content'
import ROUTES from 'routes'
import RTMIP, { Device, DeviceHandlerForm, DeviceInfo, Founded } from 'rtmip'
import './devices.less'

const TYPE_HTTP = 'HTTP'
const TYPE_CMD = 'CMD'

const POST = 'POST'
const GET = 'GET'
const PUT = 'PUT'
const PATCH = 'PATCH'

const CONTENT_TYPE_FORM = 'application/x-www-form-urlencoded'
const CONTENT_TYPE_JSON = 'application/json'
const CONTENT_TYPE_XML = 'application/xml'

const AUTH_BASIC = 'Basic'
const AUTH_BEARER = 'Bearer'
const AUTH_DIGEST = 'Digest'

interface RouteParams {
  id?: string
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}

interface State extends ContentState {
  inSave: boolean
  inCancel: boolean
  inOpen: boolean

  device: Device
  initial: string

  handlers: DeviceHandlerForm[]

  openState: boolean

  inScan: boolean
  founded: Founded[]
}

class DeviceForm extends React.Component<Props, State> {
  state = {
    device: {
      name: '',
      type: TYPE_HTTP,
      desc: '',
      addr: '',
      info: {} as DeviceInfo,
      handler_data: {
        method: POST,
      } as Record<string, any>,
    } as Device,
    handlers: [{ name: TYPE_HTTP, form: [] } as DeviceHandlerForm],
  } as State

  stateInterval = 0
  infoInterval = 0

  refScan = React.createRef<any>()

  constructor(props: Props) {
    super(props)
    this.state.device.id = parseInt(props.match.params.id || 'new') || 0
  }

  componentDidMount() {
    const { t } = this.props

    setTitle('devices.title')

    if (this.state.device.id > 0) {
      this.loadDevice()
    } else {
      this.setState({
        title: t('devices.add_device'),
        loaded: true,
      })
    }

    this.loadHandlers()

    this.stateInterval = window.setInterval(this.loadState, 1000)
  }

  componentDidUpdate() {
    const sid = window.location.pathname.split('/').pop()
    if (!sid || sid === 'new') return

    const id = parseInt(sid)
    if (this.state.device.id !== id) {
      this.state.device.id = id
      this.loadDevice()
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.stateInterval)
  }

  loadDevice = () => {
    RTMIP.device(this.state.device.id).then(this.setDevice).catch(this.setError)
  }

  loadHandlers = () => {
    RTMIP.deviceHandlers()
      .then((handlers) => this.setState({ handlers }))
      .catch(alert)
  }

  loadState = () => {
    const { device } = this.state
    if (!device.id) return

    RTMIP.deviceState(device.id)
      .then((state: any) => {
        // eslint-disable-next-line
        this.state.device.info = state.info
        // eslint-disable-next-line
        this.state.device.errors = state.errors

        this.setState({ openState: state.open })
      })
      .catch(console.error)
  }

  setDevice = (dev: Device) => {
    setTitle('devices.title', dev.name)

    this.setState({
      device: dev,
      initial: this.stringify(dev),
      title: dev.name,
      loaded: true,
    })
  }

  stringify(dev: Device) {
    const s = Object.assign({}, dev) as Record<string, any>
    delete s.errors
    delete s.info

    return JSON.stringify(s)
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  //
  // handlers
  //

  isChanged = (): boolean => {
    return this.stringify(this.state.device) !== this.state.initial
  }

  handleForm = (p: Record<string, any>, e: any) => {
    Object.assign(this.state.device, p)
    this.setState({})
  }

  handleInput = (val: any, key: string) => {
    Object.assign(this.state.device, { [key]: val })
    this.setState({})
  }

  handleHandlerData = (val: any, key: string) => {
    const { device } = this.state

    if (!device.handler_data) device.handler_data = {} as Record<string, any>
    device.handler_data[key] = val

    this.setState({})
  }

  handleSave = () => {
    const { device } = this.state

    const { id, info, errors, ...data } = device

    if (device.id > 0) {
      RTMIP.changeDevice(device.id, data as Device)
        .then(this.setDevice)
        .catch(alert)
    } else {
      device.enabled = true
      data.enabled = true
      RTMIP.createDevice(data as Device)
        .then((dev: Device) => {
          const urlpath = path.join(ROUTES.settings.devices, dev.id.toString())
          window.history.pushState(dev, dev.name, urlpath)

          this.setDevice(dev)
        })
        .catch(alert)
    }
  }

  handleCopy = () => {
    const { t } = this.props
    const { device } = this.state
    device.id = 0
    device.name = ''
    device.open_time = ''
    device.errors = null

    this.setState({
      device,
      title: t('devices.add_device'),
    })

    const urlpath = path.join(ROUTES.settings.devices, 'new')
    window.history.pushState(device, 'devices', urlpath)
  }

  toggleDevice = () => {
    const { device } = this.state
    device.enabled = !device.enabled

    RTMIP.changeDevice(device.id, device).then(this.setDevice).catch(alert)
  }

  openDevice = () => {
    const { device } = this.state
    this.setState({ inOpen: true })

    RTMIP.openDevice(device.id)
      .then((v: any) => {
        if (v.pass) success('success')
        else info(JSON.stringify(v))
      })
      .catch(alert)
      .then(() => this.setState({ inOpen: false }))
  }

  handleScanner = () => {
    this.setState({ inScan: true })

    RTMIP.devicesScanner()
      .then((founded) => {
        this.setState({ founded })
        this.refScan.current.open()
      })
      .catch(alert)
      .then(() => this.setState({ inScan: false }))
  }

  handleToggleScanner = (e: any) => {
    if (e.type === 'focus') this.refScan.current.open()
  }

  //
  // render
  //

  render() {
    const { loaded, error, device } = this.state

    const devError = getError(device)

    return (
      <Content
        loaded={loaded}
        error={error || devError}
        header={this.renderHeader()}>
        <CenterRow right={this.renderState()}>
          <Panel>{this.renderForm()}</Panel>
          {this.renderInfo()}
        </CenterRow>
      </Content>
    )
  }

  renderHeader() {
    const { title, device } = this.state

    const devError = getError(device)

    return (
      <Header left={<HeaderLeft back={ROUTES.settings.devices}></HeaderLeft>}>
        <HeaderTitle {...device} errors={devError ? device.errors : undefined}>
          {title}
        </HeaderTitle>
      </Header>
    )
  }

  renderForm() {
    const { t } = this.props
    const { device, handlers, inScan, founded } = this.state

    return (
      <Form
        formValue={device}
        onChange={this.handleForm}
        autoComplete='off'
        fluid>
        <Grid fluid>
          <Row gutter={15}>
            <Col md={10}>
              <Form.Group>
                <Form.ControlLabel>{t('name')}</Form.ControlLabel>
                <Form.Control name='name' />
              </Form.Group>
            </Col>
            <Col md={14}>
              <Form.Group>
                <Form.ControlLabel>{t('desc')}</Form.ControlLabel>
                <Form.Control name='desc' />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('type')}</Form.ControlLabel>
                <RadioGroup
                  as={Stack}
                  className='form-type-selector'
                  appearance='picker'
                  justifyContent='center'
                  onChange={(v) => this.handleInput(v, 'type')}
                  value={device.type}
                  inline>
                  {handlers.map((h: DeviceHandlerForm) => (
                    <Radio key={h.name} value={h.name}>
                      {h.name}
                    </Radio>
                  ))}
                </RadioGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('addr')}</Form.ControlLabel>
                <InputGroup>
                  <Whisper
                    ref={this.refScan}
                    placement='bottom'
                    trigger={founded ? 'focus' : 'none'}
                    enterable
                    speaker={
                      <Popover className='scannermenu' full>
                        {this.renderScannerMenu()}
                      </Popover>
                    }>
                    <Form.Control name='addr' />
                  </Whisper>

                  <InputGroup.Button
                    disabled={inScan}
                    onClick={this.handleScanner}>
                    {inScan ? <SpinnerIcon spin /> : <FaSearch />}
                  </InputGroup.Button>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          {this.renderHandler()}

          <Row gutter={15}>
            <Col md={16}>
              <Form.Group>
                <Form.ControlLabel>
                  {t('devices.resp_path')}
                  <Help>{t('devices.help.resp_path')}</Help>
                </Form.ControlLabel>
                <Form.Control name='resp_path' placeholder='relay.2.state' />
              </Form.Group>
            </Col>

            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>
                  {t('devices.duration')}
                  <Help>{t('devices.help.duration')}</Help>
                </Form.ControlLabel>
                <InputNumber
                  value={device.duration || 0}
                  onChange={(v: any) =>
                    this.handleInput(parseInt(v), 'duration')
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Grid>
        {this.renderFooter()}
      </Form>
    )
  }

  renderHandler() {
    const { device } = this.state

    switch (device.type) {
      case TYPE_HTTP:
        return this.renderFromHTTP()
      case TYPE_CMD:
        return null
    }

    return this.renderHandlerForm()
  }

  renderFromHTTP() {
    const { t } = this.props
    const { device } = this.state

    const data = device.handler_data || ({} as Record<string, any>)

    return (
      <>
        <Divider />
        <Row>
          <Col md={24}>
            <Form.Group>
              <Form.ControlLabel>{t('devices.method')}</Form.ControlLabel>
              <CheckButtonGroup
                data={data}
                name='method'
                onClick={this.handleHandlerData}
                buttons={[
                  { value: GET },
                  { value: POST },
                  { value: PUT },
                  { value: PATCH },
                ]}
              />
            </Form.Group>
          </Col>
        </Row>

        {data.method &&
          data.method !== GET && [
            <Row key='content_type'>
              <Col md={24}>
                <Form.Group>
                  <Form.ControlLabel>
                    {t('devices.content_type')}
                  </Form.ControlLabel>
                  <InputPicker
                    data={[
                      { label: 'FORM', value: CONTENT_TYPE_FORM },
                      { label: 'XML', value: CONTENT_TYPE_XML },
                      { label: 'JSON', value: CONTENT_TYPE_JSON },
                    ]}
                    value={data.content_type}
                    onChange={(v) => this.handleHandlerData(v, 'content_type')}
                    renderMenuItem={(label, item) => (
                      <div>
                        {item.value}
                        {label !== item.value && <Tag>{label}</Tag>}
                      </div>
                    )}
                    creatable
                    block
                  />
                </Form.Group>
              </Col>
            </Row>,

            <Row key='body'>
              <Col md={24}>
                <Form.Group>
                  <Form.ControlLabel>{t('devices.body')}</Form.ControlLabel>

                  <AceEditor
                    value={data.body}
                    onChange={(v) => this.handleHandlerData(v, 'body')}
                    mode='javascript'
                    theme='monokai'
                    width='100%'
                    height='100px'
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      useWorker: false,

                      showLineNumbers: false,
                      showGutter: false,
                      wrap: false,
                      highlightActiveLine: true,
                      showPrintMargin: false,

                      tabSize: 2,
                      fontSize: 12,
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>,
          ]}

        <Row>
          <Col md={24}>
            <Form.Group>
              <Form.ControlLabel>
                {t('devices.authorization')}
              </Form.ControlLabel>
              <InputPicker
                name='auth_method'
                value={data.auth_method}
                data={[AUTH_BASIC, AUTH_DIGEST, AUTH_BEARER].map((v) => ({
                  name: v,
                }))}
                onChange={(v) => this.handleHandlerData(v, 'auth_method')}
                labelKey='name'
                valueKey='name'
                cleanable={true}
                block
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {data.auth_method === AUTH_BEARER && (
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('devices.token')}</Form.ControlLabel>
                <Form.Control name='authorization' placeholder={'TOKEN'} />
              </Form.Group>
            </Col>
          )}

          {(data.auth_method === AUTH_BASIC ||
            data.auth_method === AUTH_DIGEST) && (
            <Form.Group>
              <Col md={12}>
                <Form.ControlLabel>{t('devices.username')}</Form.ControlLabel>
                <Input
                  name='auth_username'
                  placeholder='username'
                  value={data.auth_username}
                  onChange={(v) => this.handleHandlerData(v, 'auth_username')}
                />
              </Col>

              <Col md={12}>
                <Form.ControlLabel>{t('devices.password')}</Form.ControlLabel>
                <Input
                  name='auth_password'
                  placeholder='password'
                  type='password'
                  value={data.auth_password}
                  onChange={(v) => this.handleHandlerData(v, 'auth_password')}
                />
              </Col>
            </Form.Group>
          )}
        </Row>

        <Divider />
      </>
    )
  }

  renderHandlerForm() {
    const { device, handlers } = this.state

    const h = handlers.find((h) => h.name === device.type)
    if (!h) return null

    const data = device.handler_data || {}

    return (
      <Row gutter={15}>
        {h.form.map((f) => (
          <Col md={f.width || 12}>
            <SettingsInput
              {...f}
              i18nPrefix='devices'
              value={data[f.name]}
              onChange={this.handleHandlerData}
            />
          </Col>
        ))}
      </Row>
    )
  }

  renderScannerMenu() {
    const { device, founded } = this.state
    if (!founded) return null

    const data = founded.filter((f) => f.type === device.type)

    return (
      <SelectPicker
        open={true}
        value={device.addr || ''}
        data={data || []}
        onSelect={(v) => this.handleInput(v, 'addr')}
        valueKey='addr'
        labelKey='addr'
        renderMenuItem={(addr: any, f: any) => {
          if (f.type !== device.type) return null

          return (
            <div key={f.addr} className='scannermenu-item'>
              {f.addr} <Tag>{f.type}</Tag>
            </div>
          )
        }}
        block
      />
    )
  }

  renderFooter() {
    const { t } = this.props
    const { device } = this.state

    return (
      <Footer className='footer-buttons'>
        <ButtonToolbar align='left'>
          {device.id > 0 && (
            <Button
              appearance='primary'
              onClick={this.toggleDevice}
              color={device.enabled ? 'orange' : 'green'}>
              {device.enabled ? t('disable') : t('enable')}
            </Button>
          )}
        </ButtonToolbar>

        <ButtonToolbar justifyContent='flex-end'>
          {device.id > 0 && (
            <Button
              disabled={this.isChanged()}
              onClick={this.openDevice}
              loading={this.state.inOpen}>
              {t('devices.open')}
            </Button>
          )}

          {device.id > 0 && (
            <Button
              disabled={!this.isChanged()}
              onClick={this.loadDevice}
              loading={this.state.inCancel}>
              {t('cancel')}
            </Button>
          )}

          <ButtonGroup>
            <Button
              appearance='primary'
              type='submit'
              disabled={!device.name || !device.addr || !device.type}
              onClick={this.handleSave}
              loading={this.state.inSave}>
              {t('save')}
            </Button>
            <IconButton onClick={this.handleCopy} icon={<Icon as={FaCopy} />} />
          </ButtonGroup>
        </ButtonToolbar>
      </Footer>
    )
  }

  renderState() {
    const { t } = this.props
    const { device, openState } = this.state
    if (device.id === 0) return

    return (
      <StatPanel name={t('devices.state')} state={openState ? 'pass' : ''}>
        {openState ? t('devices.opened') : t('devices.closed')}
      </StatPanel>
    )
  }

  renderInfo() {
    const { t } = this.props
    const { device } = this.state

    if (!device?.info?.time) return null

    const reqTime = moment(device?.info?.time)
    if (!reqTime.isValid() || reqTime.year() === 1) return null

    let color = 'green'
    if (device.info.latency > 50) color = 'yellow'
    if (device.info.latency > 100) color = 'red'

    return (
      <>
        <Panel
          header={
            <div>
              {t('devices.status')}{' '}
              <Tag color={color as any}>{device.info.latency}ms</Tag>
              <Tag>{reqTime.format('HH:mm:ss LL')}</Tag>
            </div>
          }>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <h6>{t('devices.request')}</h6>
                <pre className='log'>{device.info.request}</pre>
              </Col>

              <Col md={12}>
                <h6>{t('devices.response')}</h6>
                <pre className='log'>{device.info.response}</pre>
              </Col>
            </Row>
          </Grid>
        </Panel>

        {device.errors && device.errors.length > 0 && (
          <Errors
            type='error'
            title={t('devices.errors')}
            value={device.errors}
          />
        )}
      </>
    )
  }
}

export default withTranslation()(withRouter(DeviceForm))

export function getError(dev: Device): string | undefined {
  if (!dev.errors || !dev.errors.length) return undefined

  var time = moment(dev.errors[0].time)
  if (moment().unix() - time.unix() < 60) {
    return dev.errors[0].message
  }

  return undefined
}

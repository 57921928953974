import InnerHTML from 'dangerously-set-html-content'
import moment from 'moment-timezone'
import Mustache from 'mustache'
import React from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import ReactResizeDetector from 'react-resize-detector'
import { Message, Panel } from 'rsuite'

import {
  CACHE,
  HLS,
  MDASH,
  SmartMJPEG,
  SmartVIDEO,
  VIDEO,
  WEBCAM,
} from 'components/players'
import { alert } from 'content'
import RTMIP, {
  Camera,
  CameraIMAGE,
  CameraMDASH,
  CameraMJPEG,
  CameraVIDEO,
  Detected,
  IpcamOpt,
  Line,
  Point,
  Region,
  WS,
} from 'rtmip'
import CameraCanvas from './cameracanvas'
import CameraHeader, { CameraHeaderOptions } from './cameraheader'
import CameraPTZ from './cameraptz'
import Heatmap from './heatmap'

export const TYPE_MDASH: string = 'MDASH'
export const TYPE_HLS: string = 'HLS'
export const TYPE_MJPEG: string = 'MJPEG'
export const TYPE_IMAGE: string = 'IMAGE'
export const TYPE_VIDEO: string = 'VIDEO'
export const TYPE_CACHE: string = 'CACHE'
export const TYPE_WEBCAM: string = 'WEBCAM'

export const TYPES: string[] = [
  TYPE_MDASH,
  TYPE_HLS,
  TYPE_MJPEG,
  TYPE_IMAGE,
  TYPE_VIDEO,
  TYPE_CACHE,
  TYPE_WEBCAM,
]

export const PLAYER_TYPE_IMAGE: string = 'img'
export const PLAYER_TYPE_MDASH: string = 'mdash'
export const PLAYER_TYPE_VIDEO: string = 'video'

const CLASSNAME = 'camera-player'

const MAX_TIMEOUT = 5000
const DEF_TIMEOUT = 1000

interface Props extends WithTranslation {
  camera: Camera
  ref?: React.RefObject<CameraPlayer> // crunch

  onDraw?: (points: Point[]) => void
  onDetected?: (d: Detected) => boolean
  onResize?: (w: number, h: number) => void
  onExpand?: () => void
  expanded?: boolean

  header?: CameraHeaderOptions
  withHeatmap?: boolean
}

interface State {
  camera: Camera
  error?: Error
  analytics: Record<number, Detected>
  canvas: {
    w: number
    h: number
  }
  playerHTML?: string
  heatmap: boolean
}

export interface VideoPlayer {
  getOffset: () => number
  getFrame: (frame: string) => void
  getBlob: BlobCallback
}

declare global {
  interface Window {
    unmountPlayers: Record<any, () => void>
  }
}

class CameraPlayer extends React.Component<Props, State> {
  state = {
    analytics: {} as Record<number, Detected>,
    canvas: {
      w: 0,
      h: 0,
    },
  } as State

  intervals = [] as any
  timeout = 0

  player = React.createRef<any>()
  mounted = false
  ws?: WS = undefined

  constructor(props: Props) {
    super(props)

    this.state.camera = this.props.camera

    if (!window.unmountPlayers) window.unmountPlayers = {}
  }

  componentDidMount() {
    const interval = window.setInterval(this.loadCamera, 20000)
    this.intervals.push(interval)

    this.initialize(this.state.camera)
  }

  // componentDidUpdate() {
  //   const { camera } = this.props

  //   if (camera.started_at !== this.state.camera.started_at) {
  //     console.log('UPDATE', camera.started_at, this.state.camera.started_at)
  //     this.setState({ camera })

  //     this.unmountModule()

  //     // if (!camera.started_at.startsWith('0001')) {
  //     if (camera.module) this.loadPlayer(camera)
  //     else this.setState({ playerHTML: undefined })
  //     // }
  //   }
  // }

  componentWillUnmount() {
    this.intervals.forEach((interval: any) => window.clearInterval(interval))
    this.intervals = []

    window.clearTimeout(this.timeout)
    this.mounted = false

    this.ws?.unsubscribe({ camera_id: this.props.camera.id })
    this.ws?.close()

    this.unmountModule()
  }

  unmountModule() {
    const unmountCallback = window.unmountPlayers[this.props.camera.id]
    if (unmountCallback) {
      unmountCallback()
      delete window.unmountPlayers[this.props.camera.id]
    }
  }

  initialize = (cam: Camera) => {
    if (this.mounted) this.componentWillUnmount()
    this.mounted = true

    // const { id, type, schemes_id } = camera
    if (!cam.schemes_id?.length || !cam.enabled) return

    const rate = this.getRate()
    const params = this.state.camera.params || {}

    switch (cam.type) {
      case TYPE_MDASH:
      case TYPE_HLS:
      case TYPE_CACHE:
        // request analytics data by video progress
        this.ws = RTMIP.ws()
        this.ws
          .camerasDetected(this.setAnalyticsResults)
          .then(() => {
            const interval = window.setInterval(this.reqDetected, rate)
            this.intervals.push(interval)
          })
          .catch(alert)

        break

      case TYPE_VIDEO:
        if (!(params as CameraVIDEO).cache) {
          this.timeout = window.setTimeout(this.sendFrame, rate)
        } else {
          // no need to do analytics for the camera type VIDEO, as it do it self
        }

        break

      case TYPE_WEBCAM:
        // send frame from video and receive analytics data
        this.timeout = window.setTimeout(this.sendFrame, rate)

        break

      case TYPE_IMAGE:
      case TYPE_MJPEG:
      default:
        // recieve real time analytics data over webscoket
        this.ws = RTMIP.ws()
        this.ws
          .camerasSubscribe(this.setAnalyticsResult)
          .then((ws) => ws.subscribe({ camera_id: cam.id }))
          .catch(alert)

        break
    }

    if (cam.module) this.loadPlayer(cam)
    else this.setState({ playerHTML: undefined })
  }

  loadPlayer = (cam: Camera) => {
    this.setState({ playerHTML: undefined })

    RTMIP.cameraPlayer(cam.id).then((player) => {
      const data = {
        PATH: RTMIP.url(`/api/cameras/${cam.id}/player`),
        HOST: RTMIP.addr,
        camera: cam,
      }
      this.setState({ playerHTML: Mustache.render(player, data) })
    })
  }

  getRate = () => {
    const { params } = this.props.camera

    const framerate = (params as any)?.framerate
    return framerate ? 1000 / framerate : DEF_TIMEOUT
  }

  loadCamera = () => {
    RTMIP.camera(this.props.camera.id).then(this.setCamera).catch(alert)
  }

  setCamera = (camera: Camera) => {
    const { schemes_id, type, started_at } = this.state.camera

    const analytics = {} as Record<number, Detected>

    camera.schemes_id?.forEach((id: number) => {
      const d = this.state.analytics[id]
      if (d) analytics[id] = d
    })

    this.setState({ camera, analytics })

    // reinitialize
    if (
      camera.type !== type ||
      schemes_id?.toString() !== camera.schemes_id?.toString()
    ) {
      this.initialize(camera)
    }

    if (started_at !== camera.started_at) {
      this.unmountModule()

      if (camera.module) this.loadPlayer(camera)
      else this.setState({ playerHTML: undefined })
    }
  }

  reqDetected = () => {
    const { camera } = this.state
    const player = this.player.current
    if (!player) {
      console.log('player is null', player)
      return
    }

    let data = {
      camera_id: camera.id,
      offset: 0, //mm
      timems: 0, //mm
    }

    if (
      this.props.camera.type === TYPE_MDASH ||
      this.props.camera.type === TYPE_HLS
    ) {
      data.timems = player.getTimeMS()
    } else {
      data.offset = player.getOffset()
    }

    if (this.ws) this.ws.send(data)
  }

  sendFrame = () => {
    if (!this.mounted) return

    if (this.mounted) clearTimeout(this.timeout)
    this.timeout = window.setTimeout(this.sendFrame, MAX_TIMEOUT)

    const player = this.player.current
    if (!player) {
      console.warn('player not exist')
      return
    }

    const rate = this.getRate()
    const at = moment().valueOf() + rate

    if (!player.getFrame) return

    player.getFrame((frame: string) => {
      RTMIP.sendFrame(this.props.camera.id, frame)
        .then((d) => {
          window.clearTimeout(this.timeout)

          this.setAnalyticsResults(d)

          const diff = at - moment().valueOf()

          if (diff > 30) {
            this.timeout = window.setTimeout(this.sendFrame, diff)
          } else {
            this.sendFrame()
          }
        })
        .catch(this.catchError)
    })
  }

  setAnalyticsResults = (d: Detected[]) => {
    if (d) d.forEach(this.setAnalyticsResult)
  }

  setAnalyticsResult = (d: Detected) => {
    const { onDetected } = this.props
    const { analytics } = this.state

    if (onDetected && !onDetected(d)) {
      delete analytics[d.analytics.id]
    } else {
      analytics[d.analytics.id] = d
    }

    this.setState({ analytics, error: undefined })
  }

  catchError = (err: Error) => {
    this.setState({ error: err })
  }

  onResize = (w?: number, h?: number) => {
    this.setState({ canvas: { w: w || 0, h: h || 0 } })
    if (this.props.onResize) this.props.onResize(w || 0, h || 0)
  }

  toggleHeatmap = () => {
    this.setState({ heatmap: !this.state.heatmap })
  }

  getRegionsAndLines = () => {
    const { camera } = this.props
    const { analytics } = this.state

    // copy regions array
    let regions = [] as Region[]
    if (camera.analytics_regions) regions = [...camera.analytics_regions]

    // copy lines array
    let lines = [] as Line[]
    if (camera.analytics_lines) lines = [...camera.analytics_lines]

    Object.values(analytics).forEach((a) => {
      a.regions?.forEach((region) => {
        const r = regions.find((r) => r.id == region.id)
        if (r && JSON.stringify(r) === JSON.stringify(region)) return
        region.id += '_analytics'
        regions.push(region)
      })
      a.lines?.forEach((line) => {
        const l = lines.find((l) => l.id == line.id)
        if (l && JSON.stringify(l) === JSON.stringify(line)) return
        line.id += '_analytics'
        lines.push(line)
      })
    })

    return { regions, lines }
  }

  //
  // render
  //

  render() {
    const { header, withHeatmap, onExpand, expanded } = this.props
    const { camera, analytics, canvas, error, heatmap } = this.state

    const { regions, lines } = this.getRegionsAndLines()

    return (
      <div className='camera-player-container'>
        {header && this.props.onDraw === undefined && (
          <CameraHeader
            camera={camera}
            options={header}
            onHeatmap={withHeatmap ? this.toggleHeatmap : undefined}
            heatmap={heatmap}
            onExpand={onExpand}
            expanded={expanded}
          />
        )}

        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
          <Panel bodyFill shaded>
            {camera.enabled ? (
              this.renderPlayer()
            ) : (
              <div className='camera-player'>
                <div className='camera-state'>
                  <Trans i18nKey='errors.camera_disabled' name={camera.name}>
                    Camera "{camera.name}" disabled
                  </Trans>
                </div>
              </div>
            )}

            <img
              alt='watermark'
              className='watermark'
              src='/assets/img/logo.png'
            />
            {heatmap && <Heatmap id={camera.id} />}
            {this.renderPTZ(camera.params as IpcamOpt)}

            {camera.enabled && (
              <CameraCanvas
                analytics={analytics}
                width={canvas.w}
                height={canvas.h}
                regions={regions}
                lines={lines}
                onDraw={this.props.onDraw}
              />
            )}
            {error && (
              <Message showIcon className='camera-error' type='error'>
                {error.message}
              </Message>
            )}
          </Panel>
        </ReactResizeDetector>
      </div>
    )
  }

  renderPTZ(p: IpcamOpt) {
    if (!p?.ptz?.enabled) return
    const { camera } = this.state

    return <CameraPTZ cameraid={camera.id} data={p.ptz.data} />
  }

  renderPlayer() {
    if (!this.mounted) return

    const { t } = this.props
    const { camera, playerHTML } = this.state
    const params = camera.params || {}

    switch (camera.type) {
      //
      // by progress types
      case TYPE_MDASH:
        return (
          <MDASH
            ref={this.player}
            src={camera.stream_path}
            className={CLASSNAME}
            syncTime={(params as CameraMDASH).sync_time || 0}
          />
        )
      case TYPE_HLS:
        return (
          <HLS
            ref={this.player}
            src={camera.stream_path}
            className={CLASSNAME}
            syncTime={(params as CameraMDASH).sync_time || 0}
          />
        )
      case TYPE_CACHE:
        return (
          <CACHE
            ref={this.player}
            src={camera.stream_path}
            className={CLASSNAME}
          />
        )

      //
      // by frame types
      case TYPE_VIDEO:
        if (!(params as CameraVIDEO).cache)
          return (
            <VIDEO
              ref={this.player}
              src={camera.stream_path}
              className={CLASSNAME}
              loop
              controls
            />
          )
        else
          return (
            <SmartVIDEO
              ref={this.player}
              camera={camera}
              rate={(params as CameraVIDEO).framerate || 1}
              src={camera.stream_path}
              className={CLASSNAME}
            />
          )
      case TYPE_WEBCAM:
        return <WEBCAM ref={this.player} className={CLASSNAME} />

      //
      // realtime types
      case TYPE_MJPEG:
        return (
          <SmartMJPEG
            camera_id={camera.id}
            className={CLASSNAME}
            rate={(params as CameraMJPEG).decode?.framerate || 1}
          />
        )

      case TYPE_IMAGE:
        return (
          <SmartMJPEG
            camera_id={camera.id}
            className={CLASSNAME}
            rate={(params as CameraIMAGE).framerate || 1}
          />
        )

      //
      // for custom types
      default:
        if (playerHTML) {
          return (
            <InnerHTML html={playerHTML} data-started_at={camera.started_at} />
          )
        }

        if (camera.stream_path) {
          return (
            <SmartMJPEG
              camera_id={camera.id}
              className={CLASSNAME}
              rate={(params as CameraMJPEG).decode?.framerate || 1}
            />
          )
        }

        return (
          <Message type='error' className={CLASSNAME} title={t('errors.title')}>
            <Trans>"{camera.type}" is unsupported type</Trans>
          </Message>
        )
    }
  }
}

export default withTranslation('', { withRef: true })(CameraPlayer)

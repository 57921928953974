import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Sidebar, Header, IconButton, Whisper, Tooltip } from 'rsuite'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import PerfectScrollbar from 'react-perfect-scrollbar'

import EventsTimeline from './events'
import { User } from 'rtmip'

interface Props extends WithTranslation {
  expand: boolean
  onToggle: () => void
  user?: User
}

class EventsBar extends React.Component<Props> {
  render() {
    const { t, expand, onToggle, user } = this.props

    return (
      <Sidebar data-expand={expand} width='auto' collapsible>
        <Header className='eventsbar-header'>
          <h3 className='eventsbar-title'>{t('events')}</h3>
          <Whisper
            placement='left'
            trigger='hover'
            speaker={<Tooltip>{t('tooltip_toggle_eventsbar')}</Tooltip>}>
            <IconButton
              className='togglebar-btn'
              icon={expand ? <FaChevronRight /> : <FaChevronLeft />}
              onClick={onToggle}
              size='xs'
              circle
            />
          </Whisper>
        </Header>
        {expand && (
          <PerfectScrollbar className='eventsbar-content'>
            <EventsTimeline user={user} />
          </PerfectScrollbar>
        )}
      </Sidebar>
    )
  }
}

export default withTranslation()(EventsBar)

import React from 'react'

import { SelectPicker, IconButton } from 'rsuite'
import { FaVideo } from 'react-icons/fa'

interface Props {
  value?: any
  data: any[]
  onSelect?: (value: any, item: any) => void
}
interface State {
  open: boolean
}

export default class SelectWithIcon extends React.Component<Props, State> {
  state = {} as State

  toggleOpen = (open: boolean) => {
    this.setState({ open })
  }

  render() {
    const { value, data, onSelect } = this.props
    const { open } = this.state

    return (
      <div className='selectwithicon'>
        <IconButton icon={<FaVideo />} active={open} />
        <SelectPicker
          value={value?.id}
          data={data || []}
          valueKey='id'
          labelKey='name'
          onOpen={() => this.toggleOpen(true)}
          onClose={() => this.toggleOpen(false)}
          onSelect={onSelect}
          placement='bottom'
        />
      </div>
    )
  }
}

import React from 'react'

import WSFRAMES from './wsframes'
import FRAMES from './frames'
import SimpleMJPEG from './simplemjpeg'

interface Props {
  camera_id: number
  rate?: number
  className?: string

  onError?: () => boolean
  timeOffset?: number
}

interface State {
  player: Player
}

enum Player {
  MJPEG = 0,
  WSFRAMES = 1,
  FRAMES = 2,
}

export default class SmartMJPEG extends React.Component<Props, State> {
  state = {
    player: Player.WSFRAMES,
  } as State

  getPlayerProps = () => {
    const { onError, ...props } = this.props
    return props
  }

  handleSwitchPlayer = (): boolean => {
    switch (this.state.player) {
      case Player.MJPEG:
        this.setState({ player: Player.WSFRAMES })
        break
      case Player.WSFRAMES:
        this.setState({ player: Player.FRAMES })
        break
      case Player.FRAMES:
        this.setState({ player: Player.MJPEG })
        break
    }

    return false
  }

  //
  // render
  //

  render() {
    const { player } = this.state

    return <div className='smartmjpeg'>{this.renderPlayer(player)}</div>
  }

  renderPlayer(player: Player) {
    switch (player) {
      case Player.MJPEG:
        return [
          <SimpleMJPEG
            key='mjpeg'
            {...this.getPlayerProps()}
            src={`/api/cameras/${this.props.camera_id}/stream`}
            onError={this.handleSwitchPlayer}
            framerate={this.props.rate}
          />,
          <div
            key='selector'
            className='camera-smartmjpeg_label'
            onClick={() => this.handleSwitchPlayer()}>
            MJPEG
          </div>,
        ]
      case Player.WSFRAMES:
        return [
          <WSFRAMES
            key='wsframes'
            {...this.getPlayerProps()}
            onError={this.handleSwitchPlayer}
          />,
          <div
            key='selector'
            className='camera-smartmjpeg_label'
            onClick={() => this.handleSwitchPlayer()}>
            WEBSOCKET
          </div>,
        ]
      case Player.FRAMES:
        return [
          <FRAMES
            key='frames'
            {...this.getPlayerProps()}
            onError={this.handleSwitchPlayer}
          />,
          <div
            key='selector'
            className='camera-smartmjpeg_label'
            onClick={() => this.handleSwitchPlayer()}>
            FRAMES
          </div>,
        ]
    }
  }
}

import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Panel, Tag } from 'rsuite'

import RTMIP, { Camera, Exception } from 'rtmip'
import Content, {
  HeaderSearch,
  ContentState,
  setTitle,
  alert,
  DelButton,
} from 'content'
import ReactResizeDetector from 'react-resize-detector'
import moment from 'moment-timezone'

import './exceptions.less'
import { IMAGE } from 'components/players'

interface Props extends WithTranslation {}
interface State extends ContentState {
  cameras: Camera[]
  exceptions: Exception[]
  selected: Record<string, Exception>
  size: {
    w: number
    h: number
    cols: number
  }
}

class Exceptions extends React.Component<Props, State> {
  state = {
    size: { cols: 4 },
    selected: {} as Record<string, Exception>,
  } as State

  componentDidMount() {
    setTitle('analytics.exceptions')

    const q = new URLSearchParams(window.location.search)
    const search = q.get('search')
    if (search) {
      this.setState({ search })
    }

    RTMIP.cameras()
      .then((cameras) => {
        this.setState({ cameras })

        RTMIP.exceptions()
          .then(this.setExceptions)
          .catch(alert)
          .then(() => this.setState({ loaded: true }))
      })
      .catch(alert)
  }

  setExceptions = (exceptions: Exception[]) => {
    const { cameras } = this.state

    if (cameras) {
      exceptions.forEach((e) => {
        const cam = cameras.find((cam) => e.camera_id === cam.id)
        if (cam) e.camera = cam.name
      })
    }

    this.setState({ exceptions })
  }

  //
  // handlers
  //

  handleSearch = (search: string) => {
    this.setState({ search })

    let url = ''
    if (search) url = '?search=' + search
    else url = window.location.pathname

    window.history.replaceState('', 'cameras', url)
  }

  handleSelect = (e: Exception) => {
    const { selected } = this.state

    selected[e.name] ? delete selected[e.name] : (selected[e.name] = e)
    this.setState({ selected })
  }

  handleDelete = () => {
    const { exceptions, selected } = this.state

    Object.keys(selected).forEach((name) => {
      RTMIP.deleteException(name).catch(alert)
    })

    this.setState({
      exceptions: exceptions.filter((e) => selected[e.name] === undefined),
      selected: {},
    })
  }

  handleResize = (w?: number, h?: number) => {
    if (!w) return

    const cols = Number(Math.round(w / 260))
    this.setState({ size: { w: w || 0, h: h || 0, cols } })
  }

  //
  // data
  //

  getData(): Exception[] {
    const { search, exceptions } = this.state

    if (!search) return exceptions || []

    const s = search.toLocaleLowerCase()
    return (
      exceptions?.filter((e) => {
        return (
          e.type.toLocaleUpperCase().includes(s) ||
          e.camera?.toLocaleLowerCase().includes(s)
        )
      }) || []
    )
  }

  //
  // render
  //
  render() {
    const { loaded, size } = this.state

    const data = this.getData()

    return (
      <Content loaded={loaded} header={this.renderHeader()}>
        {data && (
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this.handleResize}>
            <div className='exc-columns' style={{ columnCount: size.cols }}>
              {data.map(this.renderException)}
            </div>
          </ReactResizeDetector>
        )}
      </Content>
    )
  }

  renderException = (e: Exception, i: number) => {
    const { selected } = this.state

    const date = moment(e.time).format('DD.MM.YYYY')

    return (
      <Panel
        key={e.name}
        className='exc-panel'
        data-selected={selected[e.name] !== undefined || false}
        onClick={() => this.handleSelect(e)}
        bodyFill>
        <div className='exc-header'>
          <h4>{e.camera || `camera: ${e.camera_id}`}</h4>
          <Tag>{date}</Tag>
          <Tag>{e.type}</Tag>
        </div>
        <IMAGE className='exc-image' src={`/exceptions/${e.name}.jpg`} />
      </Panel>
    )
  }

  renderHeader() {
    const { search, selected } = this.state

    const disabled = Object.keys(selected || {}).length === 0

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={<DelButton onConfirm={this.handleDelete} disabled={disabled} />}
      />
    )
  }
}

export default withTranslation()(Exceptions)

import { Icon } from '@rsuite/icons'
import SpinnerIcon from '@rsuite/icons/legacy/Spinner'
import moment from 'moment-timezone'
import path from 'path'
import React from 'react'
import { BlockPicker, ColorResult } from 'react-color'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FaArrowsAltH,
  FaArrowsAltV,
  FaCheck,
  FaCircle,
  FaCopy,
  FaPlus,
  FaQuestionCircle,
  FaRedoAlt,
  FaRegObjectUngroup,
  FaRegSquare,
  FaSave,
  FaSearch,
  FaTimes,
  FaUpload,
  FaVideo,
} from 'react-icons/fa'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Checkbox,
  Col,
  Divider,
  Footer,
  Form,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  Message,
  Panel,
  Popover,
  Progress,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  Stack,
  Tag,
  TagGroup,
  TagPicker,
  Tooltip,
  Uploader,
  Whisper,
} from 'rsuite'
import shortid from 'shortid'
import { v4 as uuidv4 } from 'uuid'

import Content, {
  Activity,
  CenterRow,
  ContentState,
  Errors,
  Header,
  HeaderLeft,
  HeaderTitle,
  Help,
  SettingsInput,
  StatPanel,
  alert,
  setTitle,
} from 'content'
import InputAddress from 'content/inputaddr'
import ROUTES from 'routes'
import RTMIP, {
  ArchiveOpt,
  Camera,
  CameraCACHE,
  CameraIMAGE,
  CameraMDASH,
  CameraMJPEG,
  CameraStatus,
  CameraSupport,
  CameraVIDEO,
  CameraWEBCAM,
  DecodeOpt,
  Device,
  DirectionNone,
  Founded,
  IpcamOpt,
  Line,
  PTZ,
  Point,
  Region,
  Scheme,
  SettingsField,
  TransposeOpt,
  checkAccess,
} from 'rtmip'
import * as STATUS from 'status'
import CameraPlayer, {
  TYPE_CACHE,
  TYPE_HLS,
  TYPE_IMAGE,
  TYPE_MDASH,
  TYPE_MJPEG,
  TYPE_VIDEO,
  TYPE_WEBCAM,
} from './cameraplayer'
import ONVIF from './onvif'
import SelectSector from './selectsector'

interface RouteParams {
  id?: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  inSave: boolean
  inCancel: boolean
  camera: Camera
  initial: string
  status: CameraStatus
  schemes: Scheme[]
  drawRegion?: Region | Line

  form?: SettingsField[]
  support?: CameraSupport

  uploadProgress: number
  devices: Device[]
  types: string[]

  founded?: Founded[]
  inScan: boolean
  scanOpen: boolean

  exceptions: number

  readonly: boolean
}

class CameraForm extends React.Component<Props, State> {
  state = {
    status: {} as CameraStatus,
    camera: {
      name: '',
      desc: '',
      addr: '',
      type: '',
      params: { rtsp_tcp: true } as Record<string, any>,
    } as Camera,
    types: ['MDASH', 'MJPEG', 'CACHE', 'VIDEO', 'IMAGE', 'WEBCAM'],
    readonly: false,
  } as State

  statusInterval: number = 0
  refCamera = React.createRef<any>()
  refContent = React.createRef<any>()
  refUpload = React.createRef<any>()
  refScan = React.createRef<any>()

  constructor(props: Props) {
    super(props)
    this.state.camera.id = parseInt(props.match.params.id || 'new') || 0
  }

  componentDidMount() {
    setTitle('cameras.title')

    const { t } = this.props
    const { camera } = this.state

    if (camera.id > 0) {
      this.loadCamera()
      this.loadExceptions(camera.id)
    } else {
      this.setState({
        title: t('cameras.add_camera'),
        loaded: true,
      })
    }

    this.loadSchemes()
    this.loadDevices()
    this.loadCameraHandlers()
    this.loadUser()

    this.loadCameraStatus()
    this.statusInterval = window.setInterval(this.loadCameraStatus, 5000)
  }

  componentDidUpdate() {
    const sid = window.location.pathname.split('/').pop()
    if (!sid || sid === 'new') return

    const id = parseInt(sid)
    if (this.state.camera.id !== id) {
      this.state.camera.id = id
      this.loadCamera()
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.statusInterval)
  }

  loadCamera = () => {
    this.setState({ inCancel: true })
    RTMIP.camera(this.state.camera.id).then(this.setCamera).catch(this.setError)
  }

  setCamera = (cam: Camera) => {
    setTitle('cameras.title', cam.name)

    if (!cam.params) cam.params = {} as any

    this.setState({
      title: cam.name,
      camera: cam,
      initial: JSON.stringify(cam),
      loaded: true,
      inSave: false,
      inCancel: false,
    })

    this.refCamera.current?.loadCamera()

    window.history.replaceState(
      cam,
      cam.name,
      path.join(ROUTES.settings.cameras, cam.id.toString())
    )

    RTMIP.cameraHandlerForm(cam.type)
      .then((resp) => this.setState({ form: resp.form, support: resp.support }))
      .catch(alert)
  }

  loadCameraStatus = () => {
    if (this.state.camera.id > 0) {
      RTMIP.cameraStatus(this.state.camera.id)
        .then((status: CameraStatus) => {
          const { camera } = this.state

          camera.enabled = status.enabled
          camera.errors = status.errors
          camera.status = status.status

          this.setState({ status })
        })
        .catch(alert)
    }
  }

  loadSchemes = () => {
    RTMIP.schemes()
      .then((schemes) => this.setState({ schemes: schemes || [] }))
      .catch(alert)
  }

  loadDevices = () => {
    RTMIP.devices()
      .then((devices) => {
        if (devices && devices.length) this.setState({ devices })
      })
      .catch(console.error)
  }

  loadCameraHandlers = () => {
    RTMIP.cameraHandlers()
      .then((types) => this.setState({ types: types || [] }))
      .catch(alert)
  }

  loadExceptions = (id: number) => {
    RTMIP.countExceptions(id)
      .then((exceptions) => this.setState({ exceptions }))
      .catch(alert)
  }

  loadUser = () => {
    RTMIP.user().then((user) => {
      const w = checkAccess(user, '/cameras', 1)
      this.setState({ readonly: !w })
    })
  }

  setError = (err: Error) => {
    const { t } = this.props
    this.setState({
      title: t('errors.title'),
      error: err.message,
      loaded: true,
    })
  }

  //
  // actions
  //

  saveCamera = () => {
    this.setState({ inSave: true })

    const { camera, schemes } = this.state

    if (camera.name.includes('/\\<>?!:+=*')) {
      alert(new Error('camera name contains invalid symbols'))
      return
    }

    // remove non exists schemes
    if (schemes && camera.schemes_id) {
      if (!camera.schemes_id) camera.schemes_id = []
      camera.schemes_id = camera.schemes_id.filter((id) =>
        schemes.some((scm) => scm.id === id)
      )
    }

    delete camera.errors
    delete (camera.params as CameraMJPEG).input_formats

    camera.analytics_regions?.forEach((r) => {
      r.points?.forEach((p) => {
        p[0] = Number(p[0].toFixed(3))
        p[1] = Number(p[1].toFixed(3))
      })
    })

    camera.analytics_lines?.forEach((l) => {
      l.a[0] = Number(l.a[0].toFixed(3))
      l.a[1] = Number(l.a[1].toFixed(3))
      l.b[0] = Number(l.b[0].toFixed(3))
      l.b[1] = Number(l.b[1].toFixed(3))
    })

    // auto enable new cameras
    if (!camera.id) camera.enabled = true

    const resp = camera.id
      ? RTMIP.changeCamera(camera.id, camera)
      : RTMIP.createCamera(camera)

    resp
      .then(this.setCamera)
      .catch(alert)
      .then(() => this.setState({ inSave: false }))
  }

  handleCopy = () => {
    const { t } = this.props
    const { camera } = this.state
    camera.id = 0
    camera.enabled = false
    camera.uuid = uuidv4()
    // camera.active = false
    camera.status = STATUS.DISABLED

    this.setState({
      camera: camera,
      status: {} as CameraStatus,
      title: t('cameras.add_camera'),
    })

    const urlpath = path.join(ROUTES.settings.cameras, 'new')
    window.history.pushState(camera, 'cameras', urlpath)
  }

  toggleCamera = () => {
    const { camera } = this.state
    RTMIP.toggleCamera(camera.id)
      .then(() => {
        camera.enabled = !camera.enabled
        this.setState({ camera })
      })
      .catch(alert)
  }

  handleForm = (p: Record<string, any>, e: any) => {
    const camera = Object.assign(this.state.camera, p)

    this.setState({ camera })
  }

  handleInput = (value: any, key: string) => {
    const { camera } = this.state
    Object.assign(camera, { [key]: value })

    this.setState({ camera })
  }

  handleChangeType = (type: string) => {
    const { camera } = this.state

    RTMIP.cameraHandlerForm(type)
      .then((resp) => {
        camera.type = type
        this.setState({ camera, form: resp.form, support: resp.support })
      })
      .catch(alert)
  }

  handleParams = (value: any, key: string) => {
    const { camera } = this.state
    Object.assign(camera.params as Object, { [key]: value })

    this.setState({ camera })
  }

  handleParamsPTZ = (value: any, key: string) => {
    const { camera } = this.state
    const p = camera.params as CameraMDASH | CameraMJPEG

    let ptz = p.ptz || ({} as PTZ)
    Object.assign(ptz, { [key]: value })
    p.ptz = ptz

    this.setState({ camera })
  }

  handleParamsDecode = (value: any, key: string) => {
    const { camera } = this.state
    const p = camera.params as CameraMDASH | CameraMJPEG | CameraCACHE

    let d = p.decode || ({} as DecodeOpt)
    Object.assign(d, { [key]: value })
    p.decode = d

    this.setState({ camera })
  }

  handleParamsTranspose = (value: any, key: string) => {
    const { camera } = this.state
    const p = camera.params as CameraMJPEG

    let t = p.transpose || ({} as TransposeOpt)
    Object.assign(t, { [key]: value })
    p.transpose = t

    this.setState({ camera })
  }

  handleUpload = (resp: any) => {
    const { camera } = this.state
    camera.addr = resp.filename

    this.setState({ camera })
    this.refUpload.current.close()
  }

  handleUploadProgress = (percent: number) => {
    this.setState({ uploadProgress: Math.round(percent) })

    this.refUpload.current.open()
  }

  handleUploadError = (p: any, c: any) => {
    console.error(p)
    alert(new Error(p.response.error))

    this.setState({ uploadProgress: 0 })
    this.refUpload.current.close()
  }

  isChanged = (): boolean => {
    return this.state.initial !== JSON.stringify(this.state.camera)
  }

  handleExport = () => {
    const { camera, status } = this.state

    if (status.encoding) {
      status.encoding.state = 1
      status.encoding.progress = 0
    }

    RTMIP.cameraExportCached(camera.id).catch(alert)

    this.setState({ status })
  }

  handleScanner = () => {
    this.setState({ inScan: true })

    RTMIP.camerasScanner()
      .then((founded) => {
        this.setState({ founded })
        this.refScan.current.open()
      })
      .catch(alert)
      .then(() => this.setState({ inScan: false }))
  }

  handleToggleScanner = (e: any) => {
    if (e.type === 'focus') this.refScan.current.open()
  }

  handleONVIFaddr = (uri: string) => {
    const { camera } = this.state
    camera.addr = uri

    this.setState({ camera })
  }

  //
  // regions
  //

  createRegion = () => {
    const { drawRegion, camera } = this.state
    if (drawRegion) {
      drawRegion.edit = false
      this.setState({ drawRegion: undefined })
    }

    const region: Region = {
      id: shortid.generate(),
      name: 'new',
      color: [255, 255, 255],
      points: [],
      edit: true,
    }

    if (!camera.analytics_regions) camera.analytics_regions = []
    camera.analytics_regions.push(region)

    this.setState({ drawRegion: region })
  }

  removeRegion = (region: Region) => {
    const { camera } = this.state
    if (!camera.analytics_regions) return

    camera.analytics_regions = camera.analytics_regions.filter(
      (r: Region) => r.id !== region.id
    )

    this.setState({ camera })
  }

  drawRegion = (points: Point[]) => {
    const r = this.state.drawRegion as Region
    if (!r || r.points === undefined) return

    r.points = points
    this.setState({})
  }

  renameRegion = (r: Region | Line) => {
    const { drawRegion } = this.state
    if (drawRegion && drawRegion.id !== r.id) {
      drawRegion.edit = false
    }

    if (r.edit) {
      r.edit = false
      this.setState({ drawRegion: undefined })
    } else {
      r.edit = true
      this.setState({ drawRegion: r })
    }
  }

  inputRegionName = (region: Region | Line, name: string) => {
    region.name = name
    this.setState({})
  }

  handleChangeRegionColor = (region: Region | Line, color: ColorResult) => {
    const { r, g, b } = color.rgb
    region.color = [r, g, b]
    this.setState({})
  }

  //
  // lines
  //

  createLine = () => {
    const { drawRegion, camera } = this.state
    if (drawRegion) drawRegion.edit = false

    const line: Line = {
      id: shortid.generate(),
      name: 'line',
      color: [255, 255, 255],
      a: [Math.random(), Math.random()],
      b: [Math.random(), Math.random()],
      direction: DirectionNone,
      edit: true,
    }

    if (!camera.analytics_lines) camera.analytics_lines = []
    camera.analytics_lines.push(line)

    this.setState({ drawRegion: line })
  }

  removeLine = (line: Line) => {
    const { camera } = this.state
    if (!camera.analytics_lines) return

    camera.analytics_lines = camera.analytics_lines.filter(
      (l: Line) => l.id !== line.id
    )

    this.setState({ camera })
  }

  drawLine = (points: number[][]) => {
    const region = this.state.drawRegion
    if (!region) return

    // region.points = points
    this.setState({})
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, camera, status, drawRegion } = this.state

    return (
      <Content
        ref={this.refContent}
        loaded={loaded}
        error={error}
        header={this.renderHeader()}>
        <CenterRow right={this.renderStatusInfo()}>
          {camera.id > 0 && camera.enabled && (
            <div className='camera-form'>
              <div className='camera'>
                {/* {!drawRegion && (
                  <CameraHeader
                    camera={camera}
                    options={{ edit: false, open: true }}
                    heatmap
                  />
                )} */}
                <CameraPlayer
                  ref={this.refCamera}
                  camera={camera}
                  onDraw={drawRegion && this.drawRegion}
                  header={{
                    edit: false,
                    open: true,
                    events: true,
                  }}
                  withHeatmap
                />
              </div>
            </div>
          )}

          {(camera.errors || status.errors) && (
            <Errors value={camera.errors || status.errors || []} />
          )}

          {this.renderCachePanel(status)}

          <Panel>{this.renderForm()}</Panel>

          {status.cmdline && (
            <Panel bodyFill className='errors-panel'>
              <Message showIcon type='info'>
                <code className='errors-text'>{status.cmdline}</code>
              </Message>
            </Panel>
          )}

          {status.log && status.log.length > 0 && (
            <Errors
              type='warning'
              title={t('cameras.grabber_log')}
              value={status.log}
            />
          )}
        </CenterRow>
      </Content>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { title, camera, exceptions } = this.state

    return (
      <Header
        left={
          <HeaderLeft back={ROUTES.settings.cameras}>
            {camera.enabled && (
              <Link to={`${ROUTES.livestream}?camera=${camera.id}`}>
                <IconButton icon={<FaVideo />} circle />
              </Link>
            )}
          </HeaderLeft>
        }
        right={
          exceptions > 0 && (
            <Whisper
              speaker={<Tooltip>{t('analytics.exceptions')}</Tooltip>}
              placement='bottom'>
              <Badge content={exceptions}>
                <Link
                  to={`${ROUTES.settings.exceptions}?search=${camera.name}`}>
                  <IconButton icon={<FaRegObjectUngroup />} circle />
                </Link>
              </Badge>
            </Whisper>
          )
        }>
        <HeaderTitle {...camera}>{title}</HeaderTitle>
      </Header>
    )
  }

  renderCachePanel(s: CameraStatus) {
    if (!s || (!s.decoding && !s.analytics && !s.encoding)) return
    const { t } = this.props
    const { camera } = this.state
    const p = camera.params as CameraCACHE

    return (
      <Panel
        header={
          <Stack justifyContent='space-between'>
            {t('statpanel.cache_title')}
            {p.export && (
              <Stack justifyContent='flex-end'>
                {this.renderCachePanelBtn(s)}
              </Stack>
            )}
          </Stack>
        }>
        <div>
          {s.decoding && (
            <div className='cameras-inlineprogress'>
              {t('statpanel.decoding')}
              <Progress.Line
                percent={s.decoding.progress}
                status={s.decoding.state === 2 ? 'success' : 'active'}
              />
            </div>
          )}

          {s.analytics && camera.schemes_id?.length && (
            <div className='cameras-inlineprogress'>
              {t('statpanel.analytics')}
              <Progress.Line
                percent={s.analytics.progress}
                status={s.analytics.state === 2 ? 'success' : 'active'}
              />
            </div>
          )}

          {s.encoding && p.export && (
            <div className='cameras-inlineprogress'>
              {t('statpanel.encoding')}
              <Progress.Line
                percent={s.encoding.progress}
                status={s.encoding.state === 2 ? 'success' : 'active'}
              />
            </div>
          )}
        </div>
      </Panel>
    )
  }

  renderCachePanelBtn(s: CameraStatus) {
    const { t } = this.props

    if (s.encoding && s.encoding.state === 2 && s.encoding.filename) {
      return (
        <a
          href={RTMIP.url(s.encoding.filename)}
          target='_blank'
          rel='noreferrer'
          download>
          <IconButton appearance='primary' icon={<FaSave />} />
        </a>
      )
    }

    if (s.analytics && s.analytics.state === 2 && s.encoding?.state === 0) {
      return (
        <Button appearance='primary' onClick={this.handleExport}>
          {t('statpanel.export_title')}
        </Button>
      )
    }

    return null
  }

  renderStatusInfo() {
    const { t } = this.props
    const { camera, status } = this.state

    if (!status) return

    return (
      <div className='camera-info'>
        {status.stat?.map((stat) => (
          <StatPanel key={stat.name} {...stat}>
            {stat.name === 'fps' && (
              <Activity
                section='cameras'
                id={camera.id}
                name={camera.name}
                current={parseInt(stat.text)}
                title={t('cameras.activity.fpm')}
              />
            )}
          </StatPanel>
        ))}
        {status.archive && status.archive.size > 0 && (
          <StatPanel
            name={t('cameras.archive')}
            text={Math.round(status.archive.size / 1024 / 1024).toString()}
            unit='MB'
          />
        )}
      </div>
    )
  }

  renderForm() {
    const { t } = this.props
    const { camera, types, readonly } = this.state

    return (
      <Form
        formValue={camera}
        onChange={this.handleForm}
        disabled={readonly}
        fluid>
        <Grid fluid>
          <Row gutter={15}>
            <Col md={16}>
              <Form.Group>
                <Form.ControlLabel>{t('name')}</Form.ControlLabel>
                <Form.Control name='name' />
              </Form.Group>
            </Col>
            <Col md={8}>
              <Form.Group>
                <Form.ControlLabel>{t('sector')}</Form.ControlLabel>
                <SelectSector
                  value={camera.sector}
                  onChange={(v) => this.handleInput(v, 'sector')}
                  disabled={readonly}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('desc')}</Form.ControlLabel>
                <Form.Control name='desc' />
              </Form.Group>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>{t('type')}</Form.ControlLabel>
                <RadioGroup
                  as={Stack}
                  className='form-type-selector'
                  value={camera.type}
                  onChange={(v: any) => this.handleChangeType(v as string)}
                  appearance='picker'
                  justifyContent='center'
                  disabled={readonly}
                  inline>
                  <div>
                    {types.map((type) => (
                      <Radio key={type} value={type}>
                        {type}
                      </Radio>
                    ))}
                  </div>
                </RadioGroup>
              </Form.Group>
            </Col>
          </Row>

          {this.renderTypeDesc()}
          {this.renderAddresses()}
          {this.renderParams()}

          <Divider />
          {this.renderAnalytics()}
          {this.renderArchive()}
          <Divider />
          {this.renderFooter()}
        </Grid>
      </Form>
    )
  }

  renderTypeDesc() {
    const { t, i18n } = this.props
    const { camera, form } = this.state

    const desc = form?.find((sf) => sf.name === 'desc')
    if (desc) {
      return (
        <Row>
          <Col md={24}>
            <Message showIcon type='info'>
              {desc.value}
            </Message>
          </Col>
        </Row>
      )
    }

    const key = 'cameras.help.' + camera.type
    if (i18n.exists(key)) {
      return (
        <Row>
          <Col md={24}>
            <Message showIcon type='info'>
              {t(key)}
            </Message>
          </Col>
        </Row>
      )
    }

    return null
  }

  renderScannerMenu() {
    const { camera, founded } = this.state
    // if (!founded) return null

    return (
      <SelectPicker
        ref={this.refScan}
        className='cameras-founded'
        // open={true}
        value={camera.addr || ''}
        data={founded || []}
        onSelect={(v) => this.handleInput(v, 'addr')}
        valueKey='addr'
        labelKey='addr'
        groupBy='type'
        renderMenuItem={(addr: any, f: any) => (
          <div key={f.addr} className='scannermenu-item'>
            {f.addr} <Tag>{f.name}</Tag>
          </div>
        )}
        block
      />
    )
  }

  renderAddresses() {
    const { t } = this.props
    const { camera, support, inScan, uploadProgress, readonly } = this.state
    if (!support) return null

    return [
      (support.sub_addr || support.addr) && (
        <Row key='addr'>
          <Col md={24}>
            <Form.Group>
              <Form.ControlLabel>{t('addr')}</Form.ControlLabel>
              <InputGroup>
                <InputAddress
                  value={camera.addr || ''}
                  onChange={(v) => this.handleInput(v, 'addr')}
                  disabled={readonly}
                />
                {/* <Form.Control name='addr' /> */}

                {this.renderScannerMenu()}

                {!readonly && camera.addr.startsWith('rtsp://') && (
                  <ONVIF addr={camera.addr} onAddr={this.handleONVIFaddr} />
                )}

                <InputGroup.Button
                  disabled={readonly || inScan}
                  onClick={this.handleScanner}>
                  {inScan ? <SpinnerIcon spin pulse /> : <FaSearch />}
                </InputGroup.Button>
                {[TYPE_CACHE, TYPE_VIDEO, TYPE_IMAGE].includes(camera.type) && (
                  <Uploader
                    action={RTMIP.url('/api/cameras/upload')}
                    headers={RTMIP.authHeaders()}
                    onSuccess={this.handleUpload}
                    onProgress={this.handleUploadProgress}
                    onError={this.handleUploadError}
                    accept='image/jpeg,video/mp4'
                    fileListVisible={false}
                    disabled={readonly}
                    withCredentials
                    draggable>
                    <Whisper
                      ref={this.refUpload}
                      placement='top'
                      trigger='none'
                      speaker={
                        <Popover>
                          <Progress.Line
                            style={{ width: 200 }}
                            percent={uploadProgress}
                          />
                        </Popover>
                      }>
                      <InputGroup.Button>
                        <FaUpload />
                      </InputGroup.Button>
                    </Whisper>
                  </Uploader>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      ),
      support.sub_addr && (
        <Row key='subaddr'>
          <Col md={24}>
            <Form.Group>
              <Form.ControlLabel>{t('cameras.sub_addr')}</Form.ControlLabel>
              <InputAddress
                value={(camera.params as any)?.sub_addr || ''}
                onChange={(v) => this.handleParams(v, 'sub_addr')}
                disabled={readonly}
                // readonly={readonly}
              />
            </Form.Group>
          </Col>
        </Row>
      ),
    ]
  }

  renderParams() {
    const { t } = this.props
    const { camera, types, form } = this.state

    switch (camera.type) {
      case TYPE_MDASH:
      case TYPE_HLS:
        return this.renderMDASH(camera.params as CameraMDASH)
      case TYPE_MJPEG:
        return this.renderMJPEG(camera.params as CameraMJPEG)
      case TYPE_CACHE:
        return this.renderCACHE(camera.params as CameraCACHE)
      case TYPE_IMAGE:
        return this.renderIMAGE(camera.params as CameraIMAGE)
      case TYPE_VIDEO:
        return this.renderVIDEO(camera.params as CameraVIDEO)
      case TYPE_WEBCAM:
        return this.renderWEBCAM(camera.params as CameraWEBCAM)
      case '':
      case undefined:
        return null
      default:
        if (types.includes(camera.type)) {
          return this.renderCustomForm(form, camera.params as any)
        }

        return (
          <Message type='warning'>{t('errors.unsupported_camera')}</Message>
        )
    }
  }

  renderMDASH(p: CameraMDASH) {
    const { t } = this.props
    const { camera, readonly } = this.state

    return (
      <div>
        <Row>
          <Col md={24}>
            <Form.Group>
              <Form.ControlLabel>{t('cameras.input_args')}</Form.ControlLabel>
              <InputGroup>
                {camera.addr && camera.addr.startsWith('rtsp://') && (
                  <InputGroup.Button
                    data-checked={p?.rtsp_tcp}
                    onClick={() => this.handleParams(!p?.rtsp_tcp, 'rtsp_tcp')}
                    disabled={readonly}>
                    <b>{p?.rtsp_tcp ? 'TCP' : 'UDP'}</b>
                  </InputGroup.Button>
                )}
                <InputGroup.Button
                  data-checked={p?.stream_loop}
                  onClick={() =>
                    this.handleParams(!p?.stream_loop, 'stream_loop')
                  }
                  disabled={readonly}>
                  <b>LOOP</b>
                </InputGroup.Button>
                <Input
                  value={p?.input_args || ''}
                  onChange={(v) => this.handleParams(v, 'input_args')}
                  disabled={readonly}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {camera.addr?.startsWith('rtsp://') && p && this.renderPTZ(p.ptz)}
        <Divider />
        <h5>{t('cameras.decode_options')}</h5>
        <Row gutter={15}>
          {this.renderDecode(p?.decode, 8)}
          <Col md={8}>
            <Form.Group>
              <Form.ControlLabel>{t('cameras.sync_time')}</Form.ControlLabel>
              <InputNumber
                value={p?.sync_time || 0}
                onChange={(v: any) => this.handleParams(v, 'sync_time')}
                disabled={readonly}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    )
  }

  renderMJPEG(p: CameraMJPEG) {
    const { t } = this.props
    const { camera, readonly } = this.state

    return (
      <div>
        <Row>
          <Col md={24}>
            <Form.Group>
              <Form.ControlLabel>{t('cameras.input_args')}</Form.ControlLabel>
              <InputGroup>
                {camera.addr && camera.addr.startsWith('rtsp://') && (
                  <InputGroup.Button
                    data-checked={p?.rtsp_tcp}
                    onClick={() => this.handleParams(!p?.rtsp_tcp, 'rtsp_tcp')}
                    disabled={readonly}>
                    <b>{p?.rtsp_tcp ? 'TCP' : 'UDP'}</b>
                  </InputGroup.Button>
                )}
                <InputGroup.Button
                  data-checked={p?.stream_loop}
                  onClick={() =>
                    this.handleParams(!p?.stream_loop, 'stream_loop')
                  }
                  disabled={readonly}>
                  <b>LOOP</b>
                </InputGroup.Button>
                <Input
                  value={p?.input_args || ''}
                  onChange={(v) => this.handleParams(v, 'input_args')}
                  disabled={readonly}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        {camera.addr?.startsWith('/dev/video') && p?.input_formats?.length && (
          <Row>
            <Col md={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t('cameras.input_format')}
                </Form.ControlLabel>
                <InputPicker
                  value={p?.input_format || ''}
                  data={JSON.parse(JSON.stringify(p?.input_formats)) || []}
                  onChange={(v: any) => this.handleParams(v, 'input_format')}
                  renderValue={(v) => v}
                  labelKey='size'
                  valueKey='value'
                  groupBy='format'
                  disabled={readonly}
                  block
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        {camera.addr?.startsWith('rtsp://') && p && this.renderPTZ(p.ptz)}

        <Divider />
        <h5>{t('cameras.decode_options')}</h5>
        <Row gutter={15}>
          {this.renderDecode(p?.decode || ({} as DecodeOpt), 8)}
          {this.renderTranspose(p?.transpose || ({} as TransposeOpt))}
        </Row>
      </div>
    )
  }

  renderCACHE(p: CameraCACHE) {
    const { t } = this.props
    const { readonly } = this.state

    return (
      <div>
        <Row>
          <Col md={24}>
            <Form.Group>
              <Form.ControlLabel>{t('cameras.input_args')}</Form.ControlLabel>
              <Input
                value={p?.input_args || ''}
                onChange={(v) => this.handleParams(v, 'input_args')}
                disabled={readonly}
              />
            </Form.Group>
          </Col>
        </Row>

        <Divider />
        <h5>{t('cameras.decode_options')}</h5>
        <Row gutter={15}>
          {this.renderDecode(p?.decode, 8)}
          <Col md={8}>
            <Form.Group>
              <Form.ControlLabel> </Form.ControlLabel>
              <Checkbox
                checked={p?.export || false}
                onChange={() => this.handleParams(!p.export, 'export')}
                disabled={readonly}>
                {t('export')}
                <Whisper
                  trigger='hover'
                  placement='top'
                  speaker={<Tooltip>{t('cameras.help.cache_export')}</Tooltip>}>
                  <span>
                    &nbsp;
                    <FaQuestionCircle />
                  </span>
                </Whisper>
              </Checkbox>
            </Form.Group>
          </Col>
        </Row>
      </div>
    )
  }

  renderIMAGE(p: CameraIMAGE) {
    const { t } = this.props
    const { readonly } = this.state

    return (
      <Row gutter={15}>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('cameras.framerate')}</Form.ControlLabel>
            <InputNumber
              value={p?.framerate || 5}
              min={0}
              max={30}
              step={1}
              defaultValue='5'
              onChange={(v: any) => this.handleParams(v, 'framerate')}
              disabled={readonly}
            />
          </Form.Group>
        </Col>

        <Col md={24}>
          <ButtonGroup disabled={readonly} justified block>
            <Button
              appearance={p.validate ? 'primary' : 'default'}
              onClick={() => this.handleParams(!p.validate, 'validate')}>
              {t('cameras.validate')}
            </Button>
            <Button
              appearance={p.unique ? 'primary' : 'default'}
              onClick={() => this.handleParams(!p.unique, 'unique')}>
              {t('cameras.unique')}
            </Button>
            <Button
              appearance={p.logerrors ? 'primary' : 'default'}
              onClick={() => this.handleParams(!p.logerrors, 'logerrors')}>
              {t('cameras.logerrors')}
            </Button>
          </ButtonGroup>
          {/* <Form.Group>
            <Form.ControlLabel> </Form.ControlLabel>
            <Checkbox
              checked={p?.validate || false}
              onChange={() => this.handleParams(!p.validate, 'validate')}>
              {t('cameras.validate')}
            </Checkbox>
          </Form.Group> */}
        </Col>
        {/* <Col md={8}>
          <Form.Group>
            <Form.ControlLabel> </Form.ControlLabel>
            <Checkbox
              checked={p?.unique || false}
              onChange={() => this.handleParams(!p.unique, 'unique')}>
              {t('cameras.unique')}
            </Checkbox>
          </Form.Group>
        </Col> */}
      </Row>
    )
  }

  renderVIDEO(p: CameraVIDEO) {
    const { t } = this.props
    const { readonly } = this.state

    return (
      <Row gutter={15}>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('cameras.framerate')}</Form.ControlLabel>
            <InputNumber
              value={p?.framerate || 5}
              min={0}
              max={30}
              step={1}
              defaultValue='5'
              onChange={(v: any) => this.handleParams(v, 'framerate')}
              disabled={readonly}
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel> </Form.ControlLabel>
            <Checkbox
              checked={p?.cache || false}
              onChange={() => this.handleParams(!p.cache, 'cache')}
              disabled={readonly}>
              {t('cameras.cache')}
            </Checkbox>
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderWEBCAM(p: CameraWEBCAM) {
    const { t } = this.props
    const { readonly } = this.state

    return (
      <Row gutter={15}>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('cameras.framerate')}</Form.ControlLabel>
            <InputNumber
              value={p?.framerate || 5}
              min={0}
              max={30}
              step={1}
              defaultValue='5'
              onChange={(v: any) => this.handleParams(v, 'framerate')}
              disabled={readonly}
            />
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderCustomForm(form: SettingsField[] | null | undefined, p: any) {
    if (!form) return null
    const { t } = this.props
    const { readonly } = this.state

    form = form.filter((f) => f.type !== 'hidden')

    return (
      <div>
        <Divider />
        <h5>{t('cameras.decode_options')}</h5>
        <Row gutter={15}>
          {form.map((field) => (
            <Col
              md={field.width || form!.length > 1 ? 12 : 24}
              key={field.name}>
              <Form.Group>
                <SettingsInput
                  {...field}
                  value={p[field.name]}
                  onChange={this.handleParams}
                  readonly={readonly}
                />
              </Form.Group>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  renderPTZ(ptz: PTZ | undefined) {
    const { t } = this.props
    const { readonly } = this.state

    ptz = ptz || ({} as PTZ)

    return (
      <Row>
        <Col md={24}>
          <Form.Group>
            <Whisper
              trigger='hover'
              placement='top'
              speaker={<Tooltip>{t('cameras.ptz.help')}</Tooltip>}>
              <InputGroup>
                <InputGroup.Button
                  data-checked={ptz.enabled}
                  onClick={() => this.handleParamsPTZ(!ptz?.enabled, 'enabled')}
                  disabled={readonly}>
                  <b>PTZ</b>
                </InputGroup.Button>
                <Input
                  value={ptz.port || ''}
                  onChange={(v: any) => this.handleParamsPTZ(v, 'port')}
                  placeholder={t('cameras.ptz.port_placeholder')}
                  disabled={readonly}
                />
              </InputGroup>
            </Whisper>
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderDecode(p: DecodeOpt, w: number) {
    const { t } = this.props
    const { readonly } = this.state

    p = p || ({} as DecodeOpt)

    return [
      <Col md={w} key='decode_framerate'>
        <Form.Group>
          <Form.ControlLabel>{t('cameras.framerate')}</Form.ControlLabel>
          <InputNumber
            value={p?.framerate || 5}
            min={0}
            max={30}
            step={1}
            defaultValue='5'
            onChange={(v: any) => this.handleParamsDecode(v, 'framerate')}
            disabled={readonly}
          />
        </Form.Group>
      </Col>,
      <Col md={w} key='decode_quality'>
        <Form.Group>
          <Form.ControlLabel>
            {t('cameras.quality')}
            <Help>{t('cameras.help.quality')}</Help>
          </Form.ControlLabel>
          <InputNumber
            value={p?.quality || 3}
            min={1}
            max={31}
            defaultValue='3'
            onChange={(v: any) => this.handleParamsDecode(v, 'quality')}
            disabled={readonly}
          />
        </Form.Group>
      </Col>,
    ]
  }

  renderTranspose(p: TransposeOpt) {
    const { t } = this.props
    const { readonly } = this.state

    p = p || ({} as TransposeOpt)

    return [
      <Col md={8} key='transpose.framesize'>
        <Form.Group>
          <Form.ControlLabel>{t('cameras.framesize')}</Form.ControlLabel>
          <Input
            value={p?.framesize || ''}
            onChange={(v: any) => this.handleParamsTranspose(v, 'framesize')}
            placeholder='1280x720'
            disabled={readonly}
          />
        </Form.Group>
      </Col>,
      <Col md={8} key='transpose.crop'>
        <Form.Group>
          <Form.ControlLabel>{t('cameras.crop')}</Form.ControlLabel>
          <Input
            value={p?.crop || ''}
            onChange={(v: any) => this.handleParamsTranspose(v, 'crop')}
            placeholder='w:h:x:y'
            disabled={readonly}
          />
        </Form.Group>
      </Col>,
      <Col md={8} key='transpose.rotate'>
        <Form.Group>
          <Form.ControlLabel>{t('cameras.rotate')}</Form.ControlLabel>
          <Input
            value={p?.rotate || ''}
            onChange={(v: any) => this.handleParamsTranspose(v, 'rotate')}
            placeholder='45*(PI/180)'
            disabled={readonly}
          />
        </Form.Group>
      </Col>,
      <Col md={8} key='transpose.transpose'>
        <Form.Group>
          <Form.ControlLabel>{t('cameras.transpose')}</Form.ControlLabel>
          <ButtonGroup disabled={readonly} justified>
            <Button
              active={p?.transpose === 2}
              onClick={() => this.handleParamsTranspose(2, 'transpose')}
              disabled={readonly}>
              <Icon as={FaRedoAlt} flip='horizontal' />
            </Button>
            <Button
              active={!p?.transpose}
              onClick={() => this.handleParamsTranspose(0, 'transpose')}
              disabled={readonly}>
              <Icon as={FaRegSquare} />
            </Button>
            <Button
              active={p?.transpose === 1}
              onClick={() => this.handleParamsTranspose(1, 'transpose')}
              disabled={readonly}>
              <Icon as={FaRedoAlt} />
            </Button>
            <Button
              active={p?.hflip}
              onClick={() => this.handleParamsTranspose(!p.hflip, 'hflip')}
              disabled={readonly}>
              <Icon as={FaArrowsAltH} />
            </Button>
            <Button
              active={p?.vflip}
              onClick={() => this.handleParamsTranspose(!p.vflip, 'vflip')}
              disabled={readonly}>
              <Icon as={FaArrowsAltV} />
            </Button>
          </ButtonGroup>
        </Form.Group>
      </Col>,
    ]
  }

  renderAnalytics() {
    const { t } = this.props
    const { camera, schemes, devices, readonly } = this.state

    return (
      <div>
        <Checkbox
          className='form-section-checkbox'
          checked={camera.analytics_enabled}
          onChange={(e, v) => this.handleInput(v, 'analytics_enabled')}
          disabled={readonly}>
          <h5>{t('cameras.analytics_enable')}</h5>
        </Checkbox>
        {camera.analytics_enabled && (
          <div>
            <Row gutter={15}>
              <Col md={devices ? 18 : 24}>
                <Form.Group>
                  <Form.ControlLabel>
                    {t('cameras.analytics_schemes')}
                  </Form.ControlLabel>
                  <TagPicker
                    name='schemes_id'
                    value={camera.schemes_id}
                    data={schemes || []}
                    labelKey='name'
                    valueKey='id'
                    onChange={(v) => this.handleInput(v, 'schemes_id')}
                    renderMenuItem={(label: any, scm: any) => (
                      <div>
                        {label}{' '}
                        <Tag>
                          {t('cameras.framerate').toLocaleLowerCase()}:{' '}
                          {scm.framerate}
                        </Tag>
                      </div>
                    )}
                    onOpen={this.loadSchemes}
                    cleanable={false}
                    placement='auto'
                    disabled={readonly}
                    block
                  />
                </Form.Group>
              </Col>
              {devices && (
                <Col md={6}>
                  <Form.Group>
                    <Form.ControlLabel>{t('cameras.device')}</Form.ControlLabel>
                    <TagPicker
                      name='device'
                      value={camera.devices || []}
                      data={devices}
                      onChange={(v) => this.handleInput(v, 'devices')}
                      valueKey='id'
                      labelKey='name'
                      cleanable={false}
                      disabled={readonly}
                      block
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>

            {camera.id > 0 && (
              <Row gutter={15}>
                <Col md={12}>
                  <Form.Group>
                    <Form.ControlLabel>
                      {t('cameras.regions')}
                    </Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Button
                        onClick={this.createRegion}
                        disabled={!camera.enabled}>
                        <FaPlus />
                      </InputGroup.Button>
                      <TagGroup className='analytics_regions'>
                        {camera.analytics_regions &&
                          camera.analytics_regions.map((r: Region) => (
                            <Tag
                              key={r.id}
                              className='region-tag'
                              data-input={r.edit}
                              closable={false}>
                              <FaCircle
                                className='rs-icon-circle'
                                style={{
                                  color: `rgb(${r.color})`,
                                  cursor: 'pointer',
                                }}
                                onClick={() => this.renameRegion(r)}
                              />
                              {r.edit && (
                                <BlockPicker
                                  className='cameras-regioncolor'
                                  color={`rgb(${r.color})`}
                                  colors={[
                                    '#FFFFFF',
                                    '#377CD7',
                                    '#F65151',
                                    '#F6D765',
                                    '#61AB4F',
                                    '#C36FA6',
                                    '#444444',
                                  ]}
                                  onChange={(c) =>
                                    this.handleChangeRegionColor(r, c)
                                  }
                                />
                              )}

                              {r.edit ? (
                                <Input
                                  className='region-tag-input'
                                  value={r.name}
                                  onChange={(v: string) =>
                                    this.inputRegionName(r, v)
                                  }
                                />
                              ) : (
                                <span
                                  className='region-tag-input'
                                  onClick={() => this.renameRegion(r)}>
                                  {r.name}
                                </span>
                              )}

                              {r.edit ? (
                                <FaCheck
                                  className='rs-icon-check'
                                  onClick={() => this.renameRegion(r)}
                                />
                              ) : (
                                <FaTimes
                                  className='rs-icon-close'
                                  onClick={() => this.removeRegion(r)}
                                />
                              )}
                            </Tag>
                          ))}
                      </TagGroup>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.ControlLabel>{t('cameras.lines')}</Form.ControlLabel>
                    <InputGroup>
                      <InputGroup.Button
                        onClick={this.createLine}
                        disabled={!camera.enabled}>
                        <FaPlus />
                      </InputGroup.Button>
                      <TagGroup className='analytics_regions'>
                        {camera.analytics_lines &&
                          camera.analytics_lines.map((r: Line) => (
                            <Tag
                              key={r.id}
                              className='region-tag'
                              data-input={r.edit}
                              closable={false}>
                              <FaCircle
                                className='rs-icon-circle'
                                style={{
                                  color: `rgb(${r.color})`,
                                  cursor: 'pointer',
                                }}
                                onClick={() => this.renameRegion(r)}
                              />
                              {r.edit && (
                                <BlockPicker
                                  className='cameras-regioncolor'
                                  color={`rgb(${r.color})`}
                                  colors={[
                                    '#FFFFFF',
                                    '#377CD7',
                                    '#F65151',
                                    '#F6D765',
                                    '#61AB4F',
                                    '#C36FA6',
                                    '#444444',
                                  ]}
                                  onChange={(c) =>
                                    this.handleChangeRegionColor(r, c)
                                  }
                                />
                              )}

                              {r.edit ? (
                                <Input
                                  className='region-tag-input'
                                  value={r.name}
                                  onChange={(v: string) =>
                                    this.inputRegionName(r, v)
                                  }
                                />
                              ) : (
                                <span
                                  className='region-tag-input'
                                  onClick={() => this.renameRegion(r)}>
                                  {r.name}
                                </span>
                              )}

                              {r.edit ? (
                                <FaCheck
                                  className='rs-icon-check'
                                  onClick={() => this.renameRegion(r)}
                                />
                              ) : (
                                <FaTimes
                                  className='rs-icon-close'
                                  onClick={() => this.removeLine(r)}
                                />
                              )}
                            </Tag>
                          ))}
                      </TagGroup>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Divider />
          </div>
        )}
      </div>
    )
  }

  renderArchive() {
    const { t } = this.props
    const { camera, status, support, readonly } = this.state

    if (!support?.archive) return

    const p = camera.params as ArchiveOpt

    return (
      <div>
        <Checkbox
          className='form-section-checkbox'
          checked={p?.archive_enabled}
          onChange={(e, v) => this.handleParams(v, 'archive_enabled')}
          disabled={readonly}>
          <h5>{t('cameras.archive_enable')}</h5>
        </Checkbox>

        {p?.archive_enabled && (
          <div>
            <Row gutter={15}>
              <Col md={8}>
                <Form.ControlLabel>
                  {t('cameras.archive_stream')}
                </Form.ControlLabel>
                <RadioGroup
                  as={Stack}
                  appearance='picker'
                  value={p?.archive_stream || 'main'}
                  onChange={(v: any) => {
                    this.handleParams(v || 'mp4', 'archive_stream')
                  }}
                  justifyContent='center'
                  inline>
                  <div>
                    <Radio value='main'>
                      {t('cameras.archive_stream_main')}
                    </Radio>
                    <Radio
                      value='sub'
                      disabled={!(camera.params as IpcamOpt).sub_addr}>
                      {t('cameras.archive_stream_sub')}
                    </Radio>
                  </div>
                </RadioGroup>
              </Col>

              <Col md={8}>
                <Form.ControlLabel>
                  {t('cameras.archive_format')}
                </Form.ControlLabel>
                <RadioGroup
                  as={Stack}
                  appearance='picker'
                  defaultValue='mp4'
                  value={p?.archive_mp4 ? 'mp4' : 'mjpeg'}
                  onChange={(v: any) => {
                    this.handleParams(!p?.archive_mp4, 'archive_mp4')
                  }}
                  justifyContent='center'
                  inline>
                  <div>
                    <Radio value='mp4' disabled={support?.archive == 'mjpeg'}>
                      MP4
                    </Radio>
                    <Radio value='mjpeg' disabled={support?.archive == 'mp4'}>
                      MJPEG
                    </Radio>
                  </div>
                </RadioGroup>
              </Col>

              <Col md={8}>
                <Form.ControlLabel>
                  {t('cameras.archive_size')}
                </Form.ControlLabel>
                <Form.Group>
                  <InputGroup>
                    <InputNumber
                      value={p?.archive_size || 1}
                      min={1}
                      step={1}
                      onChange={(v: any) =>
                        this.handleParams(v, 'archive_size')
                      }
                      disabled={!p?.archive_enabled}
                    />

                    <InputGroup.Addon>GB</InputGroup.Addon>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col md={8}>
                <Form.ControlLabel>&nbsp;</Form.ControlLabel>
                <Form.Group>
                  <Checkbox
                    checked={p?.archive_byevents || false}
                    onChange={() =>
                      this.handleParams(
                        !p?.archive_byevents,
                        'archive_byevents'
                      )
                    }>
                    {t('cameras.archive_byevents')}
                  </Checkbox>
                </Form.Group>
              </Col>

              <Col md={8}>
                <Form.ControlLabel>
                  {t('cameras.archive_byevents_before')}
                </Form.ControlLabel>
                <InputNumber
                  value={p?.archive_byevents_before || 5}
                  min={1}
                  step={1}
                  onChange={(v: any) =>
                    this.handleParams(v, 'archive_byevents_before')
                  }
                  disabled={!p?.archive_byevents}
                />
              </Col>

              <Col md={8}>
                <Form.ControlLabel>
                  {t('cameras.archive_byevents_after')}
                </Form.ControlLabel>
                <InputNumber
                  value={p?.archive_byevents_after || 5}
                  min={1}
                  step={1}
                  onChange={(v: any) =>
                    this.handleParams(v, 'archive_byevents_after')
                  }
                  disabled={!p?.archive_byevents}
                />
              </Col>
            </Row>
            {status.archive && status.archive.size > 0 && (
              <Message type='info' showIcon>
                <div>
                  {`${status.archive.files} ${t('files')} / ${Math.round(
                    status.archive.size / 1024 / 1024
                  )} MB`}
                </div>
                <div>
                  {`${moment(status.archive.time_at).format('LLL')}`} -{' '}
                  {`${moment(status.archive.time_to).format('LLL')}`}
                </div>
              </Message>
              // <Row gutter={15}>
              //   <Col md={24}>
              //     <Stack
              //       alignItems='center'
              //       justifyContent='space-around'
              //       spacing={20}>
              //       <FaServer />
              //       <div>
              //         {`${status.archive.files} ${t('files')} / ${
              //           status.archive.size
              //         } MB`}
              //       </div>
              //       <div>{`${moment(status.archive.time_at).format(
              //         'LLL'
              //       )}`}</div>
              //     </Stack>
              //   </Col>
              // </Row>
            )}
          </div>
        )}
      </div>
    )
  }

  renderFooter() {
    const { t } = this.props
    const { camera, readonly } = this.state

    return (
      <Footer className='footer-buttons'>
        <ButtonToolbar align='left'>
          {camera.id > 0 && (
            <Button
              appearance='primary'
              onClick={this.toggleCamera}
              color={camera.enabled ? 'orange' : 'green'}
              disabled={readonly}>
              {camera.enabled ? t('disable') : t('enable')}
            </Button>
          )}
        </ButtonToolbar>

        <ButtonToolbar align='right' justifyContent='flex-end'>
          {camera.id > 0 && (
            <Button
              disabled={readonly || !this.isChanged()}
              onClick={this.loadCamera}
              loading={this.state.inCancel}>
              {t('cancel')}
            </Button>
          )}
          <ButtonGroup>
            <Button
              type='submit'
              appearance='primary'
              disabled={
                readonly ||
                !camera.name ||
                !camera.type ||
                (camera.type !== TYPE_WEBCAM && !camera.addr)
              }
              onClick={this.saveCamera}
              loading={this.state.inSave}>
              {t('save')}
            </Button>
            <IconButton
              onClick={this.handleCopy}
              icon={<Icon as={FaCopy} />}
              disabled={readonly}
            />
          </ButtonGroup>
        </ButtonToolbar>
      </Footer>
    )
  }
}

export default withTranslation()(withRouter(CameraForm))

import React, { ReactNode } from 'react'
import { Button, ButtonGroup } from 'rsuite'

//
// CheckButtons
//

interface CheckGroupProps {
  data: any
  name: string
  onClick: (value: any, name: string) => void
  buttons: CheckGroupBtnProps[]
}

interface CheckGroupBtnProps {
  value: string
  icon?: string
  text?: string
}

export class CheckButtonGroup extends React.Component<CheckGroupProps> {
  render() {
    const { data, name, onClick, buttons } = this.props
    return (
      <ButtonGroup justified block>
        {buttons.map((b, i) => (
          <CheckButton
            key={i}
            data={data}
            name={name}
            value={b.value}
            onClick={() => onClick(b.value, name)}
            icon={b.icon}>
            {b.text || b.value}
          </CheckButton>
        ))}
      </ButtonGroup>
    )
  }
}

interface CheckProps {
  data: any
  name: string
  value: any
  onClick: (value: any, name: string) => void
  icon?: ReactNode
  children?: any
}

export class CheckButton extends React.Component<CheckProps> {
  render() {
    const { data, name, value, onClick, children, icon } = this.props
    return (
      <Button
        appearance={data[name] === value ? 'primary' : 'default'}
        onClick={() => onClick(value, name)}>
        {icon}
        {children}
      </Button>
    )
  }
}

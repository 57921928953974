import { Icon } from '@rsuite/icons'
import ReactEcharts from 'echarts-for-react'
import humanizeDuration from 'humanize-duration'
import React from 'react'
import {
  FaArrowLeft,
  FaCircle,
  FaQuestionCircle,
  FaSearch,
  FaTimes,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
  ButtonToolbar,
  Col,
  Grid,
  IconButton,
  Input,
  InputGroup,
  Message,
  Panel,
  Row,
  Content as RsuiteContent,
  Header as RsuiteHeader,
  Stack,
  Tooltip,
  Whisper,
  toaster,
} from 'rsuite'

import i18n from 'i18next'
import moment from 'moment-timezone'
import path from 'path'
import { Scrollbars } from 'react-custom-scrollbars'

import { trans } from 'i18n'
import { ActivityData, RTMIPError } from 'rtmip'

export interface ContentState {
  loaded?: boolean
  error?: string
  search?: string
  title?: string
}

interface Props extends ContentState {
  children?: any
  header?: any
  className?: string

  handleSearch?: (s: string) => void
}

export default class Content extends React.Component<Props> {
  render() {
    const p = this.props

    return (
      <RsuiteContent className={`content ${p.className}`}>
        {p.header}

        {p.error && (
          <Message className='content-error' type='error'>
            {trans(p.error)}
          </Message>
        )}
        {!p.loaded && <Spinner className='spinner-screen' />}

        {p.loaded && p.children}
      </RsuiteContent>
    )
  }
}

//
// Header
//

interface HeaderProps {
  left?: any
  right?: any
  children?: any
}

export class Header extends React.Component<HeaderProps> {
  render() {
    return (
      <RsuiteHeader className='content-header'>
        <Stack justifyContent='space-between' wrap={false}>
          <Stack.Item>{this.props.left}</Stack.Item>
          <Stack.Item>{this.props.children}</Stack.Item>
          <Stack.Item className='header-buttons'>{this.props.right}</Stack.Item>
        </Stack>
      </RsuiteHeader>
    )
  }
}

//
// Header with Search
//

interface HeaderSearchProps {
  left?: any
  right?: any
  children?: any

  onSearch: (v: string) => void
  value?: string
}

export class HeaderSearch extends React.Component<HeaderSearchProps> {
  render() {
    return (
      <Header left={this.props.left} right={this.props.right}>
        <ButtonToolbar className='content-header_toolbar'>
          <Search onChange={this.props.onSearch} value={this.props.value} />
          {this.props.children}
        </ButtonToolbar>
      </Header>
    )
  }
}

//
// Search
//

interface SearchProps {
  onChange: (v: string) => void
  value?: string
}

export class Search extends React.Component<SearchProps> {
  render() {
    return (
      <InputGroup inside className='content-search'>
        <InputGroup.Addon>
          <FaSearch />
        </InputGroup.Addon>
        <Input
          placeholder={trans('search')}
          onChange={this.props.onChange}
          value={this.props.value || ''}
        />
        {this.props.value && (
          <InputGroup.Addon className='rs-btn'>
            <FaTimes onClick={() => this.props.onChange('')} />
          </InputGroup.Addon>
        )}
      </InputGroup>
    )
  }
}

//
// Back Buttron
//

interface HeaderLeftProps {
  back?: string
  children?: any
}

export class HeaderLeft extends React.Component<HeaderLeftProps> {
  render() {
    const to = this.props.back || path.dirname(window.location.pathname)

    return (
      <ButtonToolbar align='left' className='content-header_left'>
        {window.history.state ? (
          <IconButton
            onClick={() => window.history.back()}
            icon={<Icon as={FaArrowLeft} />}
            circle
          />
        ) : (
          <Link to={to}>
            <IconButton
              onClick={() => window.history.back()}
              icon={<Icon as={FaArrowLeft} />}
              circle
            />
          </Link>
        )}

        {this.props.children}
      </ButtonToolbar>
    )
  }
}

//
// HeaderTitle
//

interface HeaderTitleProps extends StatusIconProps {
  children?: any
}
export class HeaderTitle extends React.Component<HeaderTitleProps> {
  render() {
    return (
      <h4 className='content-title'>
        <StatusIcon {...this.props} />
        {this.props.children}
      </h4>
    )
  }
}

//
// StatusIcon
//

interface StatusIconProps {
  id?: number
  enabled?: boolean
  active?: boolean
  errors?: any[] | null
  status?: string | any
}

export class StatusIcon extends React.Component<StatusIconProps> {
  render() {
    if (!this.props.id) return ''

    return (
      <Icon
        as={FaCircle}
        className='status-icon'
        data-status={typeof this.props.status === 'string' && this.props.status}
        data-enabled={this.props.enabled}
        data-active={this.props.active}
        data-errors={hasErrors(this.props.errors)}
      />
    )
  }
}

export class StatusInlineIcon extends React.Component<StatusIconProps> {
  render() {
    const { status, enabled, active, errors } = this.props

    return (
      <Icon
        as={FaCircle}
        data-status={typeof status === 'string' && status}
        data-enabled={enabled}
        data-active={active}
        data-errors={hasErrors(errors)}
      />
    )
  }
}

export function hasErrors(errors?: any[] | null): string | boolean {
  if (!errors || !errors.length) return false

  const time = errors[0].time
  if (!time) return false

  if (moment(time).isAfter(moment().add(-1, 'minutes'))) {
    return true
  }

  return 'old'
}

//
// Errors
//

interface ErrorsProps {
  type?: 'error' | 'success' | 'warning' | 'info'
  title?: string
  value?: RTMIPError[] | null
}

export class Errors extends React.Component<ErrorsProps> {
  fmtErrorTime = (time: string): string => {
    const t = moment(time)
    if (moment().isSame(t, 'day')) return t.format('HH:mm:ss')
    return t.format('DD MMM, HH:mm')
  }

  render() {
    const errors = this.props.value

    if (!errors || !errors.length) return ''

    return (
      <Panel bodyFill className='errors-panel'>
        <Message
          showIcon
          type={this.props.type || 'error'}
          title={this.props.title}>
          <Scrollbars autoHeight autoHeightMax={250} autoHide>
            {errors.map((e: RTMIPError, i: number) => (
              <div className='errors-line' key={i}>
                <span className='errors-time'>{this.fmtErrorTime(e.time)}</span>
                <code className='errors-text'>{e.message}</code>
              </div>
            ))}
          </Scrollbars>
        </Message>
      </Panel>
    )
  }
}

//
// Spinner
//

interface SpinnerProps {
  className?: string

  center?: boolean
  small?: boolean
  screen?: boolean
  button?: boolean
}

export class Spinner extends React.Component<SpinnerProps> {
  render() {
    const { className, center, small, screen, button } = this.props

    const c = [] as string[]
    if (center) c.push('spinner-center')
    if (small) c.push('spinner-small')
    if (screen) c.push('spinner-screen')
    if (button) c.push('spinner-button')
    if (className) c.push(className)

    return (
      <div className={`spinner ${c.join(' ')}`}>
        <div className='spinner-point' />
        <div className='spinner-point' />
        <div className='spinner-point' />
        <div className='spinner-point' />
        <div className='spinner-point' />
        <div className='spinner-point' />

        <div className='spinner-line' />
        <div className='spinner-line' />
        <div className='spinner-line' />
        <div className='spinner-line' />
        <div className='spinner-line' />
        <div className='spinner-line' />
      </div>
    )
  }
}

//
// CenterRow
//

interface CenterRowProps {
  left?: any
  children?: any
  right?: any
}

export class CenterRow extends React.Component<CenterRowProps> {
  render() {
    const { left, children, right } = this.props

    return (
      <Grid fluid className='center-row'>
        <Row>
          {left ? (
            <Col md={24} lg={4}>
              {left}
            </Col>
          ) : (
            <Col mdHidden lg={4}></Col>
          )}

          <Col md={24} lg={16}>
            {children}
          </Col>

          {right ? (
            <Col md={24} lg={4}>
              {right}
            </Col>
          ) : (
            <Col mdHidden lg={4}></Col>
          )}
        </Row>
      </Grid>
    )
  }
}

//
// StatPanel
//

interface StatPanelProps {
  name: string
  html?: string
  icon?: any
  state?: string
  children?: any

  text?: string
  unit?: string
  data?: ActivityData | null
}

export class StatPanel extends React.Component<StatPanelProps> {
  getSeries = () => {
    const { name, data } = this.props
    if (!data) return []

    return [
      {
        type: 'line',
        name: name,
        data: data || [],
        lineStyle: { width: 1 },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#fff4',
              },
              {
                offset: 1,
                color: '#fff0',
              },
            ],
          },
        },
        showSymbol: false,
        smooth: true,
      },
    ]
  }

  render() {
    const { html, icon, children, state, text, unit, data } = this.props

    const tkey = `statpanel.${this.props.name}`
    const name = i18n.exists(tkey) ? i18n.t(tkey) : this.props.name

    return (
      <Panel className='stat-panel' data-state={state} header={name}>
        {html && <div dangerouslySetInnerHTML={{ __html: html || ' -/- ' }} />}
        {children}
        {text && (
          <b>
            {text}
            <span className='unit'>{unit}</span>
          </b>
        )}
        {icon && <div className='widget-right-icon'>{icon}</div>}
        {data && (
          <ReactEcharts
            className='stat-panel-activity'
            style={{ height: 64 }}
            theme='dark'
            option={{
              backgroundColor: '#0000',
              xAxis: {
                type: 'time',
                show: false,
              },
              yAxis: {
                type: 'value',
                show: false,
              },
              grid: {
                left: -5,
                right: -5,
                top: 2,
                bottom: 2,
              },
              // visualMap: [
              //   {
              //     show: false,
              //     type: 'continuous',
              //     min: 0,
              //     color: ['#f04f4300', '#f04f43'],
              //   },
              // ],
              series: this.getSeries(),
              tooltip: {
                trigger: 'axis',
                show: true,
                appendToBody: true,
                backgroundColor: 'rgba(30, 31, 32, 0.95)',
                borderWidth: 0,
              },
            }}
          />
        )}
      </Panel>
    )
  }
}

interface ActivityChartProps {
  name: string
  data: ActivityData
  height?: number
  width?: number
  ymax?: number
}

export class ActivityChart extends React.Component<ActivityChartProps> {
  render() {
    const { name, data, height, width, ymax } = this.props

    // do not render empty data
    if (!data?.some((v) => v[1] > 0)) return null

    return (
      <ReactEcharts
        className='cell-activity'
        style={{ height: height || 70, width }}
        theme='dark'
        option={{
          backgroundColor: '#0000',
          xAxis: {
            type: 'time',
            show: false,
          },
          yAxis: {
            type: 'value',
            show: false,
            max: ymax,
          },
          grid: {
            left: 2,
            right: 2,
            top: 2,
            bottom: 2,
          },
          series: [
            {
              type: 'line',
              name: name,
              data: data,
              lineStyle: { width: 1 },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#fff4',
                    },
                    {
                      offset: 1,
                      color: '#fff0',
                    },
                  ],
                },
              },
              showSymbol: false,
              smooth: true,
            },
          ],
          tooltip: {
            trigger: 'axis',
            show: true,
            appendToBody: true,
            backgroundColor: 'rgba(30, 31, 32, 0.95)',
            borderWidth: 0,
          },
        }}
      />
    )
  }
}

interface HelpProps {
  children: any
}

export class Help extends React.Component<HelpProps> {
  render() {
    const { children } = this.props

    return (
      <Whisper
        container={document.getElementById('#root')!}
        placement='top'
        trigger='hover'
        speaker={<Tooltip>{children}</Tooltip>}>
        <span>
          <Icon className='help-icon' as={FaQuestionCircle} />
        </span>
      </Whisper>
    )
  }
}

//
// functions
//

export function setTitle(key: string, name?: string) {
  if (i18n.exists(key)) {
    document.title = i18n.t(key) + ' | RTMIP'
  } else document.title = 'RTMIP'

  if (name) document.title = name + ' | ' + document.title
}

// Alert.config({ duration: 5000 })

export function alert(err: Error | string, desc?: string) {
  if (err instanceof Error) {
    toaster.push(
      <Message showIcon type='error'>
        {desc || err.message}
      </Message>
    )
    console.error(desc, err.message)
  } else {
    toaster.push(
      <Message showIcon type='error'>
        {desc || err}
      </Message>
    )
    console.error(desc, err)
  }

  return err
}

export function success(desc?: any) {
  if (typeof desc === 'string') {
    toaster.push(
      <Message showIcon type='success'>
        {desc}
      </Message>
    )
  } else {
    toaster.push(
      <Message showIcon type='success'>
        {trans('success')}
      </Message>
    )
  }
}

export function info(desc: any) {
  toaster.push(
    <Message showIcon type='info'>
      {desc}
    </Message>
  )
}

export function warn(desc: any, timeout?: number) {
  toaster.push(
    <Message showIcon type='warning' duration={timeout}>
      {desc}
    </Message>
  )
}

declare global {
  interface Window {
    success: (msg: string) => void
  }
}

window.alert = alert
window.success = success

export function getDuration(lang: string) {
  return humanizeDuration.humanizer({
    round: true,
    delimiter: ' ',
    largest: 2,
    units: ['h', 'm'],
    language: lang,
    languages: {
      en: {
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
      },
      ru: {
        h: () => 'ч',
        m: () => 'м',
        s: () => 'с',
      },
      es: {
        h: () => 'h',
        m: () => 'м',
        s: () => 's',
      },
    },
  })
}

import React from 'react'
import { Input } from 'rsuite'
import URL from 'url-parse'

interface Props {
  value: string
  onChange(v: any)
  disabled?: boolean
}
interface State {
  value: string
}

export default class InputAddress extends React.Component<Props, State> {
  state = {
    value: '',
  } as State

  componentDidMount() {
    this.escapeValue()
  }

  componentDidUpdate(props: Props) {
    if (props.value !== this.props.value) {
      this.escapeValue()
    }
  }

  escapeValue() {
    const value = this.escape(this.props.value)
    this.setState({ value: value || '' })
  }

  escape(s: string): string {
    if (!s) return ''
    if (!this.props.disabled) return s

    try {
      const url = new URL(s)
      if (url.password) url.set('password', '******')
      return url.href
    } catch {
      return s
    }
  }

  render() {
    const { disabled } = this.props

    return (
      <Input
        value={disabled ? this.state.value : this.props.value}
        onChange={(v) => this.props.onChange(v)}
        disabled={disabled}
      />
    )
  }
}

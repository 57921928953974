import WebSocketAsPromised from 'websocket-as-promised'

const pingMsg = 'ping'

export type Callback = (data: any) => void

export class WS {
  addr: string
  wsp: WebSocketAsPromised
  handler?: string
  onerror?: Callback
  onmessage?: Callback
  closed?: boolean

  constructor(addr: string, token: string) {
    const u = new URL(addr === '/' ? window.location.toString() : addr)

    // replace protocol http -> ws or https -> wss
    u.protocol = u.protocol === 'https:' ? 'wss:' : 'ws:'
    u.pathname = '/ws'
    u.search = `?jwt=${token}`

    this.addr = u.toString()
    this.wsp = new WebSocketAsPromised(this.addr, {
      packMessage: (data) => this.prepareRequest(data),
      unpackMessage: (data) => this.parseResponse(data),
      attachRequestId: (data, id) => {
        Object.assign(data, { request_id: id })
        return data
      },
      extractRequestId: (data) => {
        if (data) return data.request_id
      },
    })
  }

  prepareRequest = (data: any) => {
    const msg = { handler: this.handler, data }
    return `${JSON.stringify(msg)}\n`
  }

  parseResponse = (data: any) => {
    if (this.closed || data.startsWith(pingMsg)) return null

    const msg = JSON.parse(data)

    if (msg.error) {
      if (this.onerror) this.onerror(msg.error)
      else console.error(msg.error)
    }

    if (msg.data && this.onmessage) this.onmessage(msg.data)

    return msg.data
  }

  open = (handler: string, callback?: Callback): Promise<WS> => {
    this.handler = handler
    this.onmessage = callback

    return this.dial()
  }

  dial = (): Promise<WS> => {
    return new Promise((resolve, reject) => {
      this.wsp
        .open()
        .then(() => resolve(this))
        .catch((e) => {
          if (this.closed) return
          console.error(e)
          reject(e)
        })
    })
  }

  reconectInterval = 0
  send = (data: any) => {
    if (this.closed) return

    try {
      if (this.wsp.isOpened) this.wsp.sendPacked(data)
      if (this.wsp.isClosed) this.dial()
    } catch (e) {
      if (!this.closed) console.warn(e)
    }
  }

  sendRequest = (data: any) => {
    return this.wsp.sendRequest(data)
  }

  subscribe(data?: any) {
    try {
      this.wsp.sendPacked({ start: true, ...data })
    } catch (e) {
      if (!this.closed) console.warn(e)
    }
  }

  unsubscribe(data?: any) {
    try {
      this.wsp.sendPacked({ start: false, ...data })
    } catch (e) {
      if (!this.closed) console.warn(e)
    }
  }

  close = () => {
    this.closed = true

    try {
      this.wsp.close()
    } catch (e) {
      console.warn(e)
    }
  }

  //
  // prepared connections
  //

  camerasFrame = (f: Callback) => {
    return this.open('/cameras/frame', f)
  }

  camerasDetected = (f: Callback) => {
    return this.open('/cameras/detected', f)
  }

  camerasSubscribe = (f: Callback) => {
    return this.open('/cameras/subscribe', f)
  }

  camerasParseFrame = (f: Callback) => {
    return this.open('/cameras/parseframe', f)
  }

  // events subscriptions

  events = (f: Callback) => {
    return this.open('/events', f)
  }

  alerts = (f: Callback) => {
    return this.open('/alerts', f)
  }

  popups = (f: Callback) => {
    return this.open('/popups', f)
  }

  //

  video = (f?: Callback) => {
    return this.open('/video', f)
  }

  //

  shellSubscribe = (f: Callback) => {
    return this.open('/shell/subscribe', f)
  }
}

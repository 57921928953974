import {
  ITEMSVIEW_BBOX,
  ITEMSVIEW_HIDE,
  pointsCenter,
  settings,
} from 'components/floormaps/utils'
import React from 'react'
import { Circle, Layer, Line, Stage, Text } from 'react-konva'

import { Line as ALine, Point, Region } from 'rtmip'

interface Props {
  width: number
  height: number
  regions?: Region[]
  lines?: ALine[]
  items?: Region[]
  itemsKeypoints?: Record<string, number[]>[]
  itemsLines?: number[][]
  showLabels?: boolean
  pointsLimit?: number

  onDraw?: (points: Point[]) => void
}

interface State {
  id?: string
  points?: Point[]
}

class Canvas extends React.Component<Props, State> {
  state = {
    points: [] as Point[],
  } as State

  componentDidUpdate() {
    if (!this.props.onDraw && this.state.points?.length) {
      // eslint-disable-next-line
      this.state.points = [] as Point[]
    }
  }

  onClick = (e: any) => {
    const { onDraw, pointsLimit } = this.props
    const points = this.state.points || []

    if (onDraw && e.evt.button === 0) {
      const x = e.evt.layerX / e.evt.target.clientWidth
      const y = e.evt.layerY / e.evt.target.clientHeight

      if (pointsLimit && points.length === pointsLimit) {
        const i = this.lookupClosePoint(x, y)
        if (i == -1) return
        points[i] = [x, y] as Point
      } else {
        points.push([x, y])
      }

      this.setState({ points })

      onDraw(points)
    }
  }

  lookupClosePoint = (x, y): number => {
    const { points } = this.state

    let dist = 99
    let index = -1

    points?.forEach((p, i) => {
      const d = Math.sqrt(
        Math.pow(Math.abs(p[0] - x), 2) + Math.pow(Math.abs(p[1] - y), 2)
      )

      if (d < dist) {
        dist = d
        index = i
      }
    })

    return index
  }

  onDragPoint = (r: Region, p: any) => {
    const { width, height } = this.props

    let x = p.attrs.x / width
    let y = p.attrs.y / height

    if (x < 0) x = 0
    if (x > 1) x = 1

    if (y < 0) y = 0
    if (y > 1) y = 1

    r.points[p.attrs.index] = [x, y]
    this.setState({ points: r.points })
  }

  onDragStart = (r: Region, p: any) => {
    const { width, height } = this.props

    r.points[p.attrs.index] = [p.x() / width, p.y() / height]
    this.setState({ points: r.points })
  }

  onDragEnd = (r: Region, p: any) => {
    const { width, height, onDraw } = this.props

    let x = p.attrs.x / width
    let y = p.attrs.y / height

    if (x < 0) x = 0
    if (x > 1) x = 1

    if (y < 0) y = 0
    if (y > 1) y = 1

    if (onDraw) {
      r.points[p.attrs.index] = [x, y]
      this.setState({ points: r.points })

      onDraw(r.points)
    }
  }

  onPointClick = (r: Region, p: any, e: any) => {
    const { onDraw } = this.props

    // middle click
    if (onDraw && e.button === 2) {
      r.points.splice(p.attrs.index, 1)
      this.setState({ points: r.points })

      onDraw(r.points)
    }
  }

  getRegionPoints = (r: Region): number[] => {
    const { width, height } = this.props

    if (!r?.points?.length || !Array.isArray(r.points)) return []
    if (!Array.isArray(r.points[0]) || r.points[0].length !== 2) return []

    // console.log(r)

    try {
      const points = r.points
        ?.map(([x, y]) => [Math.round(x * width), Math.round(y * height)])
        .flat()

      return points || []
    } catch (error) {
      console.log(error)
    }

    return []
  }

  getCenterPoint = (r: Region): { x: number; y: number } => {
    const { width, height } = this.props
    let x = 0
    let y = 0
    if (!r?.points?.length || !Array.isArray(r.points)) return { x: 0, y: 0 }
    if (!Array.isArray(r.points[0]) || r.points[0].length !== 2)
      return { x: 0, y: 0 }

    r.points.forEach((p) => {
      x += p[0]
      y += p[1]
    })

    return {
      x: (x / r.points.length) * width,
      y: (y / r.points.length) * height - 6,
    }
  }

  //
  // lines
  //

  onDragLinePoint = (a: number[], p: any) => {
    const { width, height } = this.props

    a[0] = p.attrs.x / width
    a[1] = p.attrs.y / height

    this.setState({})
  }

  onDragLineStart = (a: number[], p: any) => {
    const { width, height } = this.props

    a[0] = p.x() / width
    a[1] = p.y() / height

    this.setState({})
  }

  onDragLineEnd = (l: ALine, a: Point, p: any) => {
    const { width, height, onDraw } = this.props

    let x = p.attrs.x / width
    let y = p.attrs.y / height

    if (x < 0) x = 0
    if (x > 1) x = 1

    if (y < 0) y = 0
    if (y > 1) y = 1

    a[0] = x
    a[1] = y

    if (onDraw) {
      this.setState({})
      onDraw([a, l.b])
    }
  }

  getLinePoints = (l: ALine): number[] => {
    const { width, height } = this.props

    return [l.a[0] * width, l.a[1] * height, l.b[0] * width, l.b[1] * height]
  }

  //
  // render
  //

  render() {
    const { width, height, regions, lines, onDraw } = this.props

    return (
      <Stage
        className='camera-canvas'
        width={width}
        height={height}
        onClick={this.onClick}
        onContextMenu={(e: any) => e.evt.preventDefault()}
        style={{ zIndex: onDraw ? 200 : 1 }}>
        <Layer>
          {this.renderLines()}
          {this.renderKeypoints()}
          {regions?.map((r: Region) => {
            if (!r) return null
            return (
              <Line
                key={r.id + '_region'}
                points={this.getRegionPoints(r)}
                stroke={`rgba(${r.color[0]},${r.color[1]},${r.color[2]},${
                  r.color[3] || 0.4
                })`}
                strokeWidth={2}
                fill={`rgba(${r.color[0]},${r.color[1]},${r.color[2]},${
                  r.color[3] / 4 || 0.1
                })`}
                lineJoin='round'
                closed
              />
            )
          })}
          {lines?.map((l: ALine) => [
            <Line
              key={l.id + '_line'}
              points={this.getLinePoints(l)}
              stroke={`rgba(${l.color[0]},${l.color[1]},${l.color[2]},0.8)`}
              strokeWidth={2}
            />,
          ])}

          {/* floormaps */}
          {this.renderItems()}
          {this.renderItemsText()}

          {/* regions */}
          {this.renderEditableRegions()}
          {this.renderEditableLines()}
        </Layer>
      </Stage>
    )
  }

  renderItems() {
    const { items } = this.props
    const itemsview = settings.getItemsView()

    if (!items || itemsview === ITEMSVIEW_HIDE) return null
    if (itemsview === ITEMSVIEW_BBOX) {
      return items?.map((item: Region) => (
        <Line
          key={item.id + '_' + item.name + '_item'}
          points={this.getRegionPoints(item)}
          stroke={`rgba(${item.color[0]},${item.color[1]},${item.color[2]},${item.color[3]})`}
          strokeWidth={2}
          lineJoin='round'
          shadowColor='black'
          shadowBlur={1}
          listening={false}
          closed
        />
      ))
    }

    const { width, height } = this.props

    return items?.map((item: Region) => {
      if (!item.bbox || !item || item.points?.length != 4) return null
      const c = pointsCenter(item.points)
      const r = (item.bbox[2] + item.bbox[3]) / 2

      return (
        <Circle
          key={item.id + '_' + item.name + '_item'}
          x={c[0] * width}
          y={c[1] * height}
          radius={Math.max(r * ((width + height) / 10), 4)}
          fill={`rgba(${item.color[0]},${item.color[1]},${item.color[2]},${item.color[3]})`}
          strokeWidth={2}
          lineJoin='round'
          shadowColor='black'
          shadowBlur={1}
          listening={false}
        />
      )
    })
  }

  renderItemsText() {
    const { items } = this.props
    const itemsview = settings.getItemsView()

    if (!items || itemsview === ITEMSVIEW_HIDE) return null

    return items.map((item: Region) => (
      <Text
        key={item.id + '_' + item.name + '_text'}
        {...this.getCenterPoint(item)}
        text={item.name.slice(0, 10)}
        fill='black'
        shadowColor='white'
        shadowBlur={2}
        fontSize={13}
        listening={false}
      />
    ))
  }

  renderEditableRegions() {
    const { width, height, regions, showLabels } = this.props
    if (!regions) return undefined

    const r = regions.find((r: Region) => r && r.edit)
    if (!r || !r.points) return undefined

    if (!this.state.points?.length || this.state.id !== r.id) {
      // eslint-disable-next-line
      this.state.points = r.points
      this.state.id = r.id
    }

    return r.points.map(([x, y], i) => {
      return [
        <Circle
          key={`${r.id}_point_${i}`}
          x={x * width}
          y={y * height}
          radius={6}
          fill='white'
          stroke='black'
          strokeWidth={1}
          draggable
          dragBoundFunc={(pos) => {
            if (pos.x < 0) pos.x = 0
            if (pos.x > width) pos.x = width

            if (pos.y < 0) pos.y = 0
            if (pos.y > height) pos.y = height

            return pos
          }}
          index={i}
          onDragMove={(e: any) => this.onDragPoint(r, e.target)}
          onDragStart={(e: any) => this.onDragStart(r, e.target)}
          onDragEnd={(e: any) => this.onDragEnd(r, e.target)}
          onClick={(e: any) => this.onPointClick(r, e.target, e.evt)}
        />,
        <Text
          key={`${r.id}_text_${i}`}
          x={x * width}
          y={y * height}
          text={showLabels ? i.toString() : undefined}
          stroke='white'
          strokeWidth={1}
          shadowColor='black'
          shadowBlur={1}
          scale={{ x: 1.2, y: 1.2 }}
          listening={false}
        />,
      ]
    })
  }

  renderEditableLines() {
    const { width, height, lines } = this.props
    if (!lines) return undefined

    const l = lines.find((l: ALine) => l.edit)
    if (!l) return undefined

    return [
      <Circle
        key={l.id + '_a'}
        x={l.a[0] * width}
        y={l.a[1] * height}
        radius={6}
        fill='white'
        stroke='black'
        strokeWidth={1}
        draggable
        dragBoundFunc={(pos) => {
          if (pos.x < 0) pos.x = 0
          if (pos.x > width) pos.x = width

          if (pos.y < 0) pos.y = 0
          if (pos.y > height) pos.y = height

          return pos
        }}
        onDragMove={(e: any) => this.onDragLinePoint(l.a, e.target)}
        onDragStart={(e: any) => this.onDragLineStart(l.a, e.target)}
        onDragEnd={(e: any) => this.onDragLineEnd(l, l.a, e.target)}
      />,
      <Circle
        key={l.id + '_b'}
        x={l.b[0] * width}
        y={l.b[1] * height}
        radius={6}
        fill='white'
        stroke='black'
        strokeWidth={1}
        draggable
        dragBoundFunc={(pos) => {
          if (pos.x < 0) pos.x = 0
          if (pos.x > width) pos.x = width

          if (pos.y < 0) pos.y = 0
          if (pos.y > height) pos.y = height

          return pos
        }}
        onDragMove={(e: any) => this.onDragLinePoint(l.b, e.target)}
        onDragStart={(e: any) => this.onDragLineStart(l.b, e.target)}
        onDragEnd={(e: any) => this.onDragLineEnd(l, l.b, e.target)}
      />,
    ]
  }

  renderKeypoints() {
    const { itemsKeypoints, width, height, showLabels } = this.props
    if (!itemsKeypoints) return

    const points = [] as any[]

    itemsKeypoints.forEach((kp: Record<string, number[]>) => {
      for (const [key, point] of Object.entries(kp)) {
        points.push(
          <Circle
            key={key}
            x={point[0] * width}
            y={point[1] * height}
            radius={5}
            fill='#29f8'
            stroke='#fffa'
            strokeWidth={2}
          />
        )
        if (showLabels) {
          points.push(
            <Text
              key={key + '_label'}
              x={point[0] * width}
              y={point[1] * height}
              text={key}
              stroke='white'
              strokeWidth={1}
              shadowColor='black'
              shadowBlur={1}
              scale={{ x: 1.2, y: 1.2 }}
              listening={false}
            />
          )
        }
      }
    })

    return points
  }

  renderLines() {
    const { itemsLines, width, height } = this.props
    if (!itemsLines) return

    const lines = [] as any[]

    itemsLines.forEach((line: number[], key) => {
      for (let i = 0; i < line.length; i += 2) {
        if (i + 3 >= line.length) break

        const points = [
          line[i] * width,
          line[i + 1] * height,
          line[i + 2] * width,
          line[i + 3] * height,
        ]

        lines.push(
          <Line
            key={`${key}_${i}`}
            points={points}
            stroke='#29f7'
            strokeWidth={3}
          />
        )
      }
    })

    return lines
  }

  // drawLine(lines: any[], kp: Recor, at: string, to: string) {
  //   const { width, height } = this.props

  //   const a = kp[at]
  //   const b = kp[to]
  //   if (!a || !b) return

  //   const points = [a[0] * width, a[1] * height, b[0] * width, b[1] * height]

  //   lines.push(
  //     <Line key={at + to} points={points} stroke='#29f7' strokeWidth={3} />
  //   )
  // }
}

export default Canvas

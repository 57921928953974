import CONFIG from 'config'
import { Permission, User } from './entities'
import { RTMIP } from './rtmip'

export * from './entities'
export type { JournalReqOpt, ReqFrameResponse, profilePhotoOpt } from './rtmip'
export { WS } from './ws'

const rtmip = new RTMIP(CONFIG.RTMIP_ADDR)
export default rtmip

export function checkAccess(
  user: User | undefined,
  path: string,
  level: number = 0
): boolean {
  // no user - no access
  if (!user) return false

  // no user roles - then display all, but backend should restrict access
  if (!user.roles) return true

  let perm = { access: 0 } as Permission

  user.roles.forEach((role) => {
    const p = role.permissions?.find((p: Permission) => {
      return p.sectionpath.startsWith(path)
    })
    if (!p) return
    if (!perm) perm = p
    else if (perm.access < p.access) perm = p
  })

  if (!perm) return true

  return perm.access >= level
}

import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaHistory, FaPen, FaTimes } from 'react-icons/fa'
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom'
import { ButtonToolbar, IconButton, Panel, Tooltip, Whisper } from 'rsuite'

import { CameraPlayer } from 'components/cameras'
import { DevicePanel } from 'components/devices'
import Content, {
  ContentState,
  Header,
  HeaderLeft,
  HeaderTitle,
  alert,
  setTitle,
} from 'content'
import ROUTES from 'routes'
import RTMIP, {
  Camera,
  Device,
  Event,
  FloorMap,
  FloorMapObject,
  Item,
} from 'rtmip'
import FloorMapCanvas from './floormapcanvas'
import FloorMapEvents from './floormapevents'
import { OBJ_CAMERA, OBJ_DEVICE, TYPE_IMAGE, TYPE_MAP } from './utils'

interface RouteParams {
  id: string
}

interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}

interface State extends ContentState {
  fmap: FloorMap

  cameras: Camera[]
  devices: Device[]
  openCameras: Record<number, Camera>
  openDevices: Record<number, Device>

  showEvents?: boolean
  events?: Event[]
  camerasItems: Record<number, Item[]>

  expanded?: Camera

  size: {
    w: number
    h: number
  }
}

class FloorMapView extends React.Component<Props, State> {
  state = {
    openCameras: {},
    openDevices: {},
    camerasItems: {},
    size: {
      w: 0,
      h: 0,
    },
  } as State

  componentDidMount() {
    this.loadFloormap()
    this.loadCameras()
    this.loadDevices()
  }

  loadFloormap = () => {
    const id = Number(this.props.match.params.id)
    RTMIP.floormap(id).then(this.setFloormap).catch(this.setError)
  }

  setFloormap = (fmap: FloorMap) => {
    setTitle('floormaps.title', fmap.name)

    if (!fmap.objects) fmap.objects = []
    this.setState({ fmap: fmap, title: fmap.name, loaded: true })
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  loadCameras = () => {
    RTMIP.cameras()
      .then((cameras) => this.setState({ cameras }))
      .catch(alert)
  }

  loadDevices = () => {
    RTMIP.devices()
      .then((devices) => this.setState({ devices }))
      .catch(alert)
  }

  onEvent = (e: Event) => {}

  //
  // handlers
  //

  handleCanvasSize = (w?: number, h?: number) => {
    if (!w || !h) return
    this.setState({ size: { w, h } })
  }

  handleObjectDoubleClick = (o: FloorMapObject) => {
    switch (o.type) {
      case OBJ_CAMERA:
        return this.handleOpenCamera(o.id)
      case OBJ_DEVICE:
        return this.handleOpenDevice(o.id)
    }
  }

  handleOpenCamera = (id: number) => {
    const { openCameras, cameras } = this.state

    if (openCameras[id]) {
      delete openCameras[id]
    } else {
      const cam = cameras.find((cam) => cam.id === id)
      if (cam) openCameras[id] = cam
    }

    this.setState({ openCameras })
  }

  handleOpenDevice = (id: number) => {
    const { openDevices, devices } = this.state

    if (openDevices[id]) {
      delete openDevices[id]
    } else {
      const dev = devices.find((dev) => dev.id === id)
      if (dev) openDevices[id] = dev
    }

    this.setState({ openDevices })
  }

  toggleEventsPanel = () => {
    this.setState({ showEvents: !this.state.showEvents })
  }

  handleEvents = (events?: Event[]) => {
    console.log(events)
    this.setState({ events })
  }

  handleItems = (cam_id: number, items: Item[]) => {
    const { camerasItems } = this.state
    camerasItems[cam_id] = items

    this.setState({})
  }

  handleExpandCamera = (cam?: Camera) => {
    const { expanded } = this.state

    if (expanded === cam) this.setState({ expanded: undefined })
    else this.setState({ expanded: cam })
  }

  //
  // render
  //

  render() {
    const { fmap, loaded, error } = this.state

    if (fmap && fmap.type === TYPE_IMAGE && !fmap.map_image) {
      const url = path.join(ROUTES.floormaps, fmap.id.toString(), 'edit')
      return <Redirect to={url} />
    }

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <Panel className='content-panel floormap-panel'>
          {this.renderMap()}
        </Panel>

        {this.renderEventsTimeline()}
        {this.renderWidgets()}
      </Content>
    )
  }

  renderMap() {
    const { fmap, events } = this.state
    if (!fmap) return null

    return (
      <FloorMapCanvas
        fmap={fmap}
        onObjectDoubleClick={this.handleObjectDoubleClick}
        onDrawFOVItems={this.handleItems}
        events={events}
      />
    )
  }

  renderHeader() {
    const { t } = this.props
    const { title, fmap, showEvents } = this.state

    return (
      <Header
        left={<HeaderLeft back={ROUTES.floormaps}></HeaderLeft>}
        right={
          fmap && (
            <ButtonToolbar align='right'>
              <Whisper
                trigger='hover'
                placement='bottom'
                speaker={<Tooltip>{t('floormaps.events_timeline')}</Tooltip>}>
                <IconButton
                  icon={<FaHistory />}
                  onClick={this.toggleEventsPanel}
                  active={showEvents}
                />
              </Whisper>
              <Link
                to={path.join(ROUTES.floormaps, fmap.id.toString(), 'edit')}>
                <Whisper
                  trigger='hover'
                  placement='bottomEnd'
                  speaker={<Tooltip>{t('floormaps.edit_mode')}</Tooltip>}>
                  <IconButton icon={<FaPen />} />
                </Whisper>
              </Link>
            </ButtonToolbar>
          )
        }>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
    )
  }

  renderEventsTimeline() {
    const { fmap, showEvents } = this.state
    if (!fmap || !showEvents) return null
    if (fmap.type === TYPE_MAP) return null

    const cameras = fmap.objects?.filter((o) => o.type === OBJ_CAMERA)
    if (!cameras || !cameras.length) return null

    return <FloorMapEvents cameras={cameras} onEvents={this.handleEvents} />
  }

  renderWidgets() {
    const { openCameras, openDevices, expanded } = this.state

    if (expanded) {
      return this.renderCamera(expanded)
    }

    return (
      <div
        className='floormaps-widgets'
        data-devices={Object.keys(openDevices).length > 0}
        data-cameras={Object.keys(openCameras).length}>
        <div className='floormaps-cameras'>
          {Object.values(openCameras).map(this.renderCamera)}
        </div>
        <div>{Object.values(openDevices).map(this.renderDevice)}</div>
      </div>
    )
  }

  renderCamera = (cam: Camera) => {
    const { expanded } = this.state

    return (
      <div key={cam.id} className='floormap-wgt'>
        <div className='camera'>
          <CameraPlayer
            camera={cam}
            header={{ open: true, edit: true, events: true }}
            expanded={expanded === cam}
            onExpand={() => this.handleExpandCamera(cam)}
          />
        </div>
        <IconButton
          className='floormap-wgt-close-btn'
          appearance='subtle'
          icon={<FaTimes />}
          onClick={() => this.handleOpenCamera(cam.id)}
          size='xs'
          circle
        />
      </div>
    )
  }

  renderDevice = (dev: Device) => {
    return (
      <div key={dev.id} className='floormap-wgt floormap-wgt_device'>
        <DevicePanel {...dev} />
        <IconButton
          className='floormap-wgt-close-btn'
          icon={<FaTimes />}
          onClick={() => this.handleOpenDevice(dev.id)}
          size='xs'
          circle
        />
      </div>
    )
  }
}

export default withTranslation()(withRouter(FloorMapView))

// status
export const DISABLED = 'disabled'
export const ENABLED = 'enabled'
export const ACTIVE = 'active'
export const ERRORS = 'errors'
export const INIT = 'init'

// state of events
export const ALERT = 'alert'

export const FAIL = 'fail'
export const PASS = 'pass'
export const HIDE = 'hide'

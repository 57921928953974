import 'cropperjs/dist/cropper.css'
import React from 'react'
import { Cropper } from 'react-cropper'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button, ButtonGroup, ButtonToolbar, IconButton, Modal } from 'rsuite'

import { Spinner } from 'content'
import { BiRotateLeft, BiRotateRight } from 'react-icons/bi'

interface Props extends WithTranslation {
  img: string
  onHide: () => void
  onResult: (img: Blob) => Promise<any>
}

interface State {
  img: string
  inProgress: boolean
}

class ProfileImageEditor extends React.Component<Props, State> {
  state = {
    img: this.props.img,
  } as State

  refCropper = React.createRef<any>()

  cropper() {
    return this.refCropper.current?.cropper
  }

  handleRotate = (degree: number) => {
    this.cropper().rotate(degree)
  }

  handleSave = () => {
    this.setState({ inProgress: true })
    const cropper = this.cropper()

    // if crop box exist, crop image
    const cropbox = cropper.getCropBoxData()
    if (cropbox.width) cropper.crop()

    cropper.getCroppedCanvas().toBlob((img: Blob) => {
      this.props.onResult(img).then(() => {
        this.setState({ inProgress: false })
      })
    })
  }

  //
  //
  //

  render() {
    const { t, onHide } = this.props
    const { img, inProgress } = this.state
    if (!img) return

    return (
      <Modal onClose={() => onHide()} open>
        <Cropper
          ref={this.refCropper}
          src={img}
          className='profile-cropper'
          guides={false}
          autoCrop={false}
        />
        {inProgress && <Spinner className='spinner-center spinner-small' />}
        <Modal.Footer className='profile-cropper-toolbar'>
          <ButtonToolbar align='center'>
            <ButtonGroup>
              <IconButton
                disabled={inProgress}
                onClick={() => this.handleRotate(-90)}
                icon={<BiRotateLeft />}
              />
              <IconButton
                disabled={inProgress}
                onClick={() => this.handleRotate(90)}
                icon={<BiRotateRight />}
              />
            </ButtonGroup>
            <Button
              appearance='primary'
              type='submit'
              className='profile-cropper-savebtn'
              onClick={this.handleSave}
              disabled={inProgress}>
              {t('save')}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withTranslation()(ProfileImageEditor)

import React from 'react'
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaArrowUp,
} from 'react-icons/fa'
import { Slider } from 'rsuite'

import { alert } from 'content'
import RTMIP, { PTZdata } from 'rtmip'

interface Props {
  cameraid: number
  data: PTZdata
}
interface State {}

export default class CameraPTZ extends React.Component<Props, State> {
  executeTimeout = 0

  handleUp = () => {
    this.props.data.y -= 0.1
    this.execute()
  }

  handleDown = () => {
    this.props.data.y += 0.1
    this.execute()
  }

  handleLeft = () => {
    this.props.data.x -= 0.1
    this.execute()
  }

  handleRight = () => {
    this.props.data.x += 0.1
    this.execute()
  }

  handleZoom = (v: number) => {
    this.props.data.zoom = v

    this.execute()
    this.setState({})
  }

  execute = () => {
    window.clearInterval(this.executeTimeout)

    this.executeTimeout = window.setTimeout(() => {
      const { cameraid, data } = this.props
      RTMIP.cameraPTZ(cameraid, data).catch(alert)
    }, 100)
  }

  render() {
    const { data } = this.props

    console.log(data)

    return (
      <div className='ptz'>
        <FaArrowUp onClick={this.handleUp} className='ptz-btn ptz-up' />
        <FaArrowDown onClick={this.handleDown} className='ptz-btn ptz-down' />
        <FaArrowLeft onClick={this.handleLeft} className='ptz-btn ptz-left' />
        <FaArrowRight
          onClick={this.handleRight}
          className='ptz-btn ptz-right'
        />
        <Slider
          className='ptz-zoom'
          min={0}
          max={1}
          step={0.1}
          value={data.zoom}
          onChange={this.handleZoom}
          vertical
          progress
        />
      </div>
    )
  }
}

import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaExclamationCircle, FaHeartbeat, FaPen } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
  ButtonToolbar,
  Checkbox,
  IconButton,
  Panel,
  Table,
  Tag,
  Toggle,
} from 'rsuite'

import Content, {
  ContentState,
  DelButton,
  HeaderSearch,
  TableFooter,
  alert,
  hasErrors,
  setTitle,
} from 'content'
import PageTable from 'pagetable'
import ROUTES from 'routes'
import RTMIP, { Notification } from 'rtmip'

interface Props extends WithTranslation {}
interface State extends ContentState {
  ntfs: Notification[]
  selected: Record<number, Notification>
}

class Notifications extends React.Component<Props, State> {
  state = {
    selected: {},
  } as State

  componentDidMount() {
    setTitle('notifications.title')

    RTMIP.notifications()
      .then((ntfs: Notification[]) => {
        this.setState({ ntfs: ntfs || [], loaded: true })
      })
      .catch((err: Error) => {
        this.setState({ error: err.message, loaded: true })
      })
  }

  handleSearch = (s: string) => {
    this.setState({ search: s })
  }

  handleSelect = (ntf: Notification) => {
    const { selected } = this.state
    selected[ntf.id] ? delete selected[ntf.id] : (selected[ntf.id] = ntf)

    this.setState({ selected })
  }

  handleSelectAll = (checked: boolean, ntfs: Notification[]) => {
    const selected = {} as Record<number, Notification>
    if (checked) ntfs.forEach((ntf) => (selected[ntf.id] = ntf))

    this.setState({ selected })
  }

  perfomDelete = () => {
    let { ntfs, selected } = this.state
    if (!ntfs || !ntfs.length) return

    Object.values(selected).forEach((ntf: Notification) => {
      RTMIP.deleteNotification(ntf.id).catch(alert)
    })

    ntfs = ntfs.filter((ntf: Notification) => {
      return selected[ntf.id] === undefined
    })

    this.setState({ ntfs: ntfs, selected: {} })
  }

  toggleNotification = (ntf: Notification, state: any) => {
    RTMIP.toggleNotification(ntf.id)
      .then((resp: Notification) => {
        ntf.enabled = resp.enabled
        this.setState({})
      })
      .catch(alert)
  }

  getData = (): Notification[] => {
    const search = this.state.search || ''

    if (search) {
      return this.state.ntfs?.filter((ntf: Notification) => {
        return (
          ntf.name.includes(search) ||
          ntf.type.includes(search) ||
          ntf.recipients?.includes(search) ||
          ntf.subject.includes(search) ||
          ntf.text.includes(search)
        )
      })
    }

    return this.state.ntfs || []
  }

  //
  //
  //

  render() {
    const { t } = this.props
    const { loaded, error, selected } = this.state
    const ntfs = this.getData() || []

    const { Column, HeaderCell, Cell } = Table

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <Panel className='content-panel'>
          <PageTable
            data={ntfs}
            total={ntfs.length}
            sort={{ col: 'name', type: 'asc' }}>
            <Column width={65} sortable>
              <HeaderCell> </HeaderCell>
              <Cell dataKey='enabled'>
                {(ntf: Notification | any) => (
                  <Toggle
                    key={`toggle_${ntf.id}`}
                    checked={ntf.enabled}
                    data-errors={hasErrors(ntf.errors)}
                    onChange={(v) => this.toggleNotification(ntf, v)}
                  />
                )}
              </Cell>
            </Column>

            <Column width={200} sortable>
              <HeaderCell>{t('notifications.name')}</HeaderCell>
              <Cell dataKey='name'></Cell>
            </Column>

            <Column width={85} sortable>
              <HeaderCell>{t('notifications.type')}</HeaderCell>
              <Cell dataKey='type' align='center'>
                {(ntf: Notification | any) => <Tag>{ntf.type}</Tag>}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>{t('notifications.recipients')}</HeaderCell>
              <Cell>
                {(ntf: Notification | any) =>
                  ntf.recipients?.map((r: string) => <Tag key={r}>{r}</Tag>)
                }
              </Cell>
            </Column>

            <Column flexGrow={1} sortable>
              <HeaderCell>{t('notifications.subject')}</HeaderCell>
              <Cell dataKey='subject' />
            </Column>

            <Column width={65}>
              <HeaderCell>{t('notifications.system')}</HeaderCell>
              <Cell align='center'>
                {(ntf: Notification | any) =>
                  ntf.system && <FaExclamationCircle />
                }
              </Cell>
            </Column>

            <Column fixed='right' width={90}>
              <HeaderCell className='control-table-header-cell'>
                <Checkbox
                  checked={
                    ntfs?.length > 0 &&
                    Object.keys(selected).length === ntfs?.length
                  }
                  onChange={(v, checked) => this.handleSelectAll(checked, ntfs)}
                />
              </HeaderCell>
              <Cell className='actions-cell'>
                {(ntf: Notification | any) => (
                  <ButtonToolbar>
                    <Link
                      to={path.join(
                        window.location.pathname,
                        ntf.id.toString()
                      )}>
                      <IconButton appearance='subtle' icon={<FaPen />} />
                    </Link>
                    <Checkbox
                      checked={selected[ntf.id] !== undefined || false}
                      onChange={() => this.handleSelect(ntf)}
                    />
                  </ButtonToolbar>
                )}
              </Cell>
            </Column>
          </PageTable>

          <TableFooter>{t('notifications.add_notification')}</TableFooter>
        </Panel>
      </Content>
    )
  }

  renderHeader() {
    const { search, selected } = this.state
    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={
          <ButtonToolbar>
            <Link to={`${ROUTES.status}/notifications`}>
              <IconButton icon={<FaHeartbeat />} circle />
            </Link>
            <DelButton
              onConfirm={this.perfomDelete}
              disabled={Object.keys(selected || {}).length === 0}
              selected={selected}
              circle
            />
          </ButtonToolbar>
        }
      />
    )
  }
}

export default withTranslation()(Notifications)

import humanizeDuration, { Unit } from 'humanize-duration'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Button, Popover, Slider, Tag, Whisper } from 'rsuite'

interface Props extends WithTranslation {
  days?: boolean
  hours?: boolean
  minutes?: boolean
  value: number
  min: number
  max?: number
  maxDays?: number
  units?: Unit[]
  appearance?: 'link' | 'primary' | 'default' | 'subtle' | 'ghost'
  onChange: (value: number) => void
}

class DurationPicker extends React.Component<Props> {
  d = 0
  h = 0
  m = 0

  constructor(props: Props) {
    super(props)

    let { days, value, min, max } = props
    if (min && value < min) value = min
    if (max && value > max) value = max

    this.d = parseInt((value / 86400) as any)
    this.h = parseInt(((value % 86400) / 3600) as any)
    this.m = parseInt((((value % 86400) % 3600) / 60) as any)

    if (!days) {
      this.h += this.d * 24
      this.d = 0
    }
  }

  onChange = (v: number, key: string) => {
    const { d, h, m } = this
    const { onChange, min, max } = this.props

    let value = 0
    switch (key) {
      case 'd':
        value = v * 86400 + h * 3600 + m * 60
        this.d = v
        break
      case 'h':
        value = d * 86400 + v * 3600 + m * 60
        this.h = v
        break
      case 'm':
        value = d * 86400 + h * 3600 + v * 60
        this.m = v
        break
    }

    if (min && value < min) value = min
    if (max && value > max) value = max

    onChange(value)
  }

  getValue = (): string => {
    const { value, units, i18n } = this.props
    const opt = {
      largest: 2,
      language: i18n.language,
    } as Record<string, any>
    if (units) opt.units = units

    return humanizeDuration(value * 1000, opt)
  }

  //
  //
  //

  render() {
    const { t, days, hours, minutes, maxDays, appearance } = this.props
    const { d, h, m } = this

    return (
      <Whisper
        placement='bottom'
        trigger='focus'
        enterable
        speaker={
          <Popover>
            <div className='duration-picker'>
              {days && (
                <div className='duration-picker_row'>
                  <Slider
                    min={0}
                    max={maxDays || 31}
                    value={d}
                    step={1}
                    onChange={(v) => this.onChange(v, 'd')}
                    handleTitle={this.renderSliderValue(d)}
                    tooltip={false}
                    graduated={maxDays ? maxDays < 125 : true}
                    progress
                  />
                  {t('charts.days')}
                </div>
              )}
              {hours && (
                <div className='duration-picker_row'>
                  <Slider
                    min={0}
                    max={24}
                    value={h}
                    step={1}
                    onChange={(v) => this.onChange(v, 'h')}
                    handleTitle={this.renderSliderValue(h)}
                    tooltip={false}
                    graduated
                    progress
                  />
                  {t('charts.hours')}
                </div>
              )}
              {minutes && (
                <div className='duration-picker_row'>
                  <Slider
                    min={0}
                    max={60}
                    value={m}
                    step={1}
                    onChange={(v) => this.onChange(v, 'm')}
                    handleTitle={this.renderSliderValue(m)}
                    tooltip={false}
                    graduated
                    progress
                  />
                  {t('charts.minutes')}
                </div>
              )}
            </div>
          </Popover>
        }>
        <Button appearance={appearance || 'default'} block>
          {this.getValue()}
        </Button>
      </Whisper>
    )
  }

  renderSliderValue(value: any) {
    return (
      <div className='duration-slider_handler'>
        <Tag color='blue'>{value}</Tag>
      </div>
    )
  }
}

export default withTranslation()(DurationPicker)

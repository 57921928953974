import React from 'react'
import './textfield.less'

interface Props {
  label?: any
  children?: any
  tail?: any
}

export default class TextField extends React.Component<Props> {
  render() {
    const { label, children, tail } = this.props

    return (
      <div className='textfield_group'>
        {label && <div className='subtitle'>{label}</div>}
        <div className='textfield'>
          <div className='textfield_text'>{children}</div>
          {tail && <div className='textfield_tail'>{tail}</div>}
        </div>
      </div>
    )
  }
}

const storageKey = 'cameraview'

const HIDE = 'hide'
const SHOW = 'show'

class viewSettings {
  settings = {} as Record<string, string>

  constructor() {
    this.settings = JSON.parse(localStorage.getItem(storageKey) || '{}')
  }

  __store() {
    localStorage.setItem(storageKey, JSON.stringify(this.settings))
  }

  toggle(key: string) {
    if (this.settings[key] === SHOW) this.settings[key] = HIDE
    else if (this.settings[key] === HIDE) this.settings[key] = SHOW
    else this.settings[key] = HIDE

    this.__store()
  }

  get(key: string, def?: string): string {
    if (this.settings[key]) return this.settings[key]
    return def || SHOW
  }

  set(key: string, val?: string) {
    if (val) this.settings[key] = val
    else this.toggle(key)

    this.__store()
  }

  show(key: string): boolean {
    if (this.settings[key]) return this.settings[key] === SHOW
    else return true
  }

  hide(key: string): boolean {
    return !this.show(key)
  }
}

const vs = new viewSettings()
export default vs

import moment from 'moment-timezone'
import React from 'react'
import { FaExclamationCircle } from 'react-icons/fa'
import {
  Checkbox,
  DatePicker,
  Divider,
  FlexboxGrid,
  Form,
  Input,
  InputGroup,
  InputNumber,
  Loader,
  Message,
  Popover,
  SelectPicker,
  Slider,
  Tag,
  Whisper,
} from 'rsuite'

import { trans } from 'i18n'
import { SettingsField } from 'rtmip'
import { getLocale } from 'utils/calendar'
import { Help } from './content'

interface Props extends SettingsField {
  size?: 'lg' | 'md' | 'sm' | 'xs'
  className?: string
  onChange?: (value: any, name: string) => void
  i18nPrefix?: string
}

export function parseBool(v: any): boolean {
  if (typeof v === 'boolean') return v
  return v === 'true'
}

interface State {
  default?: any
}

export default class SettingsInput extends React.Component<Props, State> {
  state = {} as State

  parseOptions(v: any) {
    if (!v) return []

    if (Array.isArray(v)) {
      return v.map((s) => {
        if (typeof s === 'string') return { label: s, value: s }
        if (typeof s === 'number') return { label: s.toString(), value: s }
        return s
      })
    }

    if (typeof v === 'string') {
      try {
        return JSON.parse(v)
      } catch (err) {}

      const ss = (v as string).split(',')
      return ss.map((s) => ({ label: s, value: s }))
    }

    return v
  }

  getLabel = (): string => {
    const { label, name, i18nPrefix } = this.props
    return trans(label || name, i18nPrefix)
  }

  //
  //
  //

  render() {
    const { type, value } = this.props

    switch (type) {
      case 'num':
      case 'number':
      case 'int':
      case 'integer':
        return this.renderNumber()

      case 'checkbox':
      case 'bool':
      case 'boolean':
        return this.renderCheckbox()

      case 'picker':
      case 'select':
        return this.renderSelect()

      case 'slider':
        return this.renderSlider()

      case 'date':
        return this.renderDate()

      case 'html':
        return this.renderHTML()

      case 'tag':
        return this.renderTag()

      case 'popover':
        return this.renderPopover()

      case 'spinner':
        return this.renderSpinner()

      case 'title':
        return <h5>{value || this.getLabel() || ''}</h5>

      case 'erroricon':
        if (!value) return null
        return this.renderErrorIcon()

      case 'error':
        if (!value) return null
        return (
          <Message type='error' showIcon>
            {value}
          </Message>
        )

      case 'row':
        return this.renderRow()

      case 'separator':
        return <Divider />

      default:
        return this.renderTextInput()
    }
  }

  renderNumber() {
    const { name, value, onChange, className, data, unit } = this.props
    const label = this.getLabel()

    return (
      <div className={className}>
        {label && (
          <Form.ControlLabel>
            {label}
            {this.renderHelp()}
          </Form.ControlLabel>
        )}
        <InputGroup>
          <InputNumber
            size={this.props.size}
            value={value || 0}
            onChange={(v) => onChange && onChange(v, name)}
            disabled={this.props.readonly}
            step={
              data?.step !== undefined
                ? Number(data.step) || undefined
                : undefined
            }
            min={
              data?.min !== undefined
                ? Number(data.min) || undefined
                : undefined
            }
            max={
              data?.max !== undefined
                ? Number(data.max) || undefined
                : undefined
            }
          />
          {unit && <InputGroup.Addon>{unit}</InputGroup.Addon>}
        </InputGroup>
      </div>
    )
  }

  renderCheckbox() {
    const { name, onChange, className } = this.props
    const label = this.getLabel()
    const value = parseBool(this.props.value)

    return (
      <div className={className}>
        <Form.ControlLabel>&nbsp;</Form.ControlLabel>
        <div>
          <Checkbox
            checked={value}
            value={value as any}
            onChange={() => onChange && onChange(!value, name)}
            disabled={this.props.readonly}>
            {label}
            {this.renderHelp()}
          </Checkbox>
        </div>
      </div>
    )
  }

  renderSelect() {
    const { name, value, onChange, className, size } = this.props
    const label = this.getLabel()

    const data = this.parseOptions(this.props.data?.options)

    return (
      <div className={className}>
        {label && (
          <Form.ControlLabel>
            {label}
            {this.renderHelp()}
          </Form.ControlLabel>
        )}
        <SelectPicker
          data={data}
          value={value || 0}
          size={size}
          onChange={(v) => onChange && onChange(v, name)}
          disabled={this.props.readonly}
          block
        />
      </div>
    )
  }

  renderSlider() {
    const { name, value, onChange, className, data } = this.props
    const label = this.getLabel()

    return (
      <div className={className}>
        {label && (
          <Form.ControlLabel>
            {label}
            {this.renderHelp()}
          </Form.ControlLabel>
        )}
        <Slider
          value={value || 0}
          onChange={(v) => onChange && onChange(v, name)}
          disabled={this.props.readonly}
          step={
            data?.step !== undefined
              ? Number(data.step) || undefined
              : undefined
          }
          min={
            data?.min !== undefined ? Number(data.min) || undefined : undefined
          }
          max={
            data?.max !== undefined ? Number(data.max) || undefined : undefined
          }
          progress
        />
      </div>
    )
  }

  renderDate() {
    const { name, onChange, className, data } = this.props
    const label = this.getLabel()

    let value = this.props.value || this.props.default
    if (value) value = moment(value).toDate()

    const defaultValue = moment().startOf('day')

    return (
      <div className={className}>
        {label && (
          <Form.ControlLabel>
            {label}
            {this.renderHelp()}
          </Form.ControlLabel>
        )}
        <DatePicker
          value={value}
          defaultValue={defaultValue.toDate()}
          onSelect={(v) => onChange && onChange(v, name)}
          size={this.props.size}
          format={data?.format || 'DD MMMM YYYY'}
          ranges={[]}
          locale={getLocale()}
          block
        />
      </div>
    )
  }

  renderHTML() {
    const { className, value } = this.props
    const label = this.getLabel()

    return (
      <div className={className}>
        {label && (
          <Form.ControlLabel>
            {label}
            {this.renderHelp()}
          </Form.ControlLabel>
        )}
        <div dangerouslySetInnerHTML={{ __html: value || '' }} />
      </div>
    )
  }

  renderTag() {
    const { className, value } = this.props
    const label = this.getLabel()

    return (
      <div className={className}>
        {label && (
          <Form.ControlLabel>
            {label}
            {this.renderHelp()}
          </Form.ControlLabel>
        )}
        <div>
          <Tag>{value}</Tag>
        </div>
      </div>
    )
  }

  renderPopover() {
    const { className, desc, value } = this.props
    const label = this.getLabel()

    return (
      <div className={className}>
        {label && <Form.ControlLabel>{label}</Form.ControlLabel>}
        <div className='settingsinput-item'>
          <Whisper
            speaker={<Popover arrow>{desc}</Popover>}
            placement='autoVertical'
            trigger='hover'
            preventOverflow
            enterable>
            <Tag>{value}</Tag>
          </Whisper>
        </div>
      </div>
    )
  }

  renderSpinner() {
    const { className } = this.props

    return (
      <div className={className}>
        <Form.ControlLabel>&nbsp;</Form.ControlLabel>
        <div className='settingsinput-item'>
          <Loader />
        </div>
      </div>
    )
  }

  renderErrorIcon() {
    const { className, desc, value } = this.props

    return (
      <div className={className}>
        <Form.ControlLabel>&nbsp;</Form.ControlLabel>
        <div className='settingsinput-item'>
          <Whisper
            speaker={
              <Popover data-state='error' arrow>
                {value || desc}
              </Popover>
            }
            placement='autoVertical'
            trigger='hover'
            preventOverflow
            enterable>
            <div>
              <FaExclamationCircle data-state='fail' />
            </div>
          </Whisper>
        </div>
      </div>
    )
  }

  renderTextInput() {
    const { name, value, type, onChange, className, unit } = this.props
    const label = this.getLabel()

    return (
      <div className={className}>
        {label && (
          <Form.ControlLabel>
            {label}
            {this.renderHelp()}
          </Form.ControlLabel>
        )}
        <InputGroup>
          <Input
            size={this.props.size}
            value={value || ''}
            onChange={(v) => onChange && onChange(v, name)}
            disabled={this.props.readonly}
            type={type}
          />
          {unit && <InputGroup.Addon>{unit}</InputGroup.Addon>}
        </InputGroup>
      </div>
    )
  }

  renderHelp() {
    const { desc } = this.props
    if (!desc) return null

    return <Help>{desc}</Help>
  }

  renderRow() {
    const { className, onChange } = this.props
    const row = this.props.data as SettingsField[]
    if (!row || !row.length) return null

    return (
      <FlexboxGrid>
        {row.map((sf) => (
          <FlexboxGrid.Item
            colspan={sf.width || 4}
            className='settingsinput-rowitem'>
            <SettingsInput
              {...sf}
              className={className}
              onChange={(v, name) => onChange && onChange(v, name)}
            />
          </FlexboxGrid.Item>
        ))}
      </FlexboxGrid>
    )
  }
}

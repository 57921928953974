import React from 'react'
import moment from 'moment-timezone'

import RTMIP, { ReqFrameResponse, WS } from 'rtmip'
import { Spinner } from 'content'

interface Props {
  camera_id: number
  rate?: number
  className?: string

  onError?: () => boolean
}

interface State {
  imgData: any
  imgTime: number
  tick: boolean
}

const imgPrefix = 'data:image/jpg;base64,'

export default class WSFRAMES extends React.Component<Props, State> {
  state = {
    imgData: undefined,
    imgTime: 0,
    tick: false,
  } as State

  ws?: WS = undefined
  timeout: number = 0
  unmounted = false

  time = moment().valueOf() - 10
  mstimeout = 200
  timeOffset = 0
  checkInterval

  componentDidMount() {
    this.setTime()

    const { rate } = this.props
    if (rate) this.mstimeout = 1000 / rate

    this.ws = RTMIP.ws()
    this.ws
      .camerasFrame(this.setFrame)
      .then((ws) => this.getFrame())
      .catch(this.onError)

    this.checkInterval = window.setInterval(() => {
      if (this.ws?.wsp.isClosed) {
        if (this.props.onError) {
          this.props.onError()
          return
        }

        console.log('reconnect websocket')
        this.ws = RTMIP.ws()
        this.ws
          .camerasFrame(this.setFrame)
          .then((ws) => this.getFrame())
          .catch(this.onError)
      }
    }, 10000)
  }

  componentWillUnmount() {
    this.unmounted = true
    window.clearInterval(this.checkInterval)

    this.ws?.close()
    if (this.timeout) window.clearTimeout(this.timeout)
  }

  setTime = () => {
    this.time = moment().valueOf()
  }

  getFrame = () => {
    if (!this.ws || this.unmounted) return

    const { imgTime } = this.state
    this.ws.send({ camera_id: this.props.camera_id, timems: imgTime })
  }

  setFrame = (resp: ReqFrameResponse) => {
    if (!resp || !resp.frame) {
      this.onError(new Error('frame not exist'))
      return
    }

    this.setTime()

    this.setState({
      imgData: imgPrefix + resp.frame,
      imgTime: resp.timems,
      tick: !this.state.tick,
    })
    this.onLoad()
  }

  onError = (err: Error) => {
    console.error(err)

    const { onError } = this.props
    if (onError && !onError()) return

    window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(this.getFrame, 5000)
    this.setState({ tick: false, imgData: undefined })
  }

  onLoad = () => {
    if (this.unmounted) return

    const msdurr = moment().valueOf() - this.time - this.mstimeout
    window.setTimeout(this.getFrame, -msdurr)
  }

  //
  // render
  //

  render() {
    const { camera_id, className } = this.props
    const { imgData, tick } = this.state

    return (
      <div className={className}>
        {imgData ? (
          <img
            alt={'camera:' + camera_id}
            src={imgData}
            className={className}
          />
        ) : (
          <div className='camera-loader'>
            <Spinner small center />
          </div>
        )}
        <div className='frames-heartbeat' data-tick={tick} />
      </div>
    )
  }
}

import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCar, FaPen, FaUsers } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonToolbar,
  Checkbox,
  Footer,
  Form,
  IconButton,
  Input,
  Modal,
  Panel,
  Table,
  Tooltip,
  Whisper,
} from 'rsuite'

import Content, {
  ContentState,
  DelButton,
  HeaderSearch,
  setTitle,
} from 'content'
import PageTable from 'pagetable'
import ROUTES from 'routes'
import RTMIP, { Group } from 'rtmip'

interface Props extends WithTranslation {}
interface State extends ContentState {
  groups: Group[]

  toDelete: Record<number, Group>

  form: {
    show: boolean
    id?: number
    name?: string
  }
}

class Groups extends React.Component<Props, State> {
  state = {
    toDelete: {},
    form: { show: false },
  } as State

  componentDidMount() {
    setTitle('profiles.groups.title')

    RTMIP.groups().then(this.setGroups).catch(this.setError)
  }

  setGroups = (groups: Group[]) => {
    this.setState({ groups: groups || [], loaded: true })
  }

  setError = (err: Error) => {
    this.setState({
      error: err.message,
      loaded: true,
      form: { show: false, id: 0, name: '' },
    })
    console.error(err.message)
  }

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleDelete = (g: Group) => {
    const { toDelete } = this.state
    toDelete[g.id] ? delete toDelete[g.id] : (toDelete[g.id] = g)

    this.setState({ toDelete })
  }

  perfomDelete = () => {
    let { groups, toDelete } = this.state
    Object.values(toDelete).forEach((g: Group) => {
      RTMIP.deleteGroup(g.id).catch(console.error)
    })

    groups = groups.filter((g: Group) => {
      return toDelete[g.id] === undefined
    })

    this.setState({ groups: groups, toDelete: {} })
  }

  toggleNewGroupWindow = (id?: number, name?: string) => {
    this.setState({
      form: { show: !this.state.form.show, id: id, name: name },
    })
  }

  getData = (): Group[] => {
    let search = this.state.search || ''
    let groups = this.state.groups || ([] as Group[])

    if (search) {
      search = search.toLowerCase()
      groups = groups.filter((g: Group) =>
        g.name.toLowerCase().includes(search)
      )
    }

    return groups
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, toDelete } = this.state
    const { Column, HeaderCell, Cell } = Table

    const data = this.getData()

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {this.renderForm()}
        <Panel className='content-panel'>
          <PageTable
            data={data}
            total={data.length}
            sort={{ col: 'name', type: 'asc' }}>
            <Column width={100} sortable>
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey='id' align='center' />
            </Column>

            <Column flexGrow={1} sortable>
              <HeaderCell>{t('profiles.groups.name')}</HeaderCell>
              <Cell dataKey='name' />
            </Column>

            <Column fixed='right' align='right' width={180}>
              <HeaderCell> </HeaderCell>
              <Cell className='actions-cell'>
                {(g: Group | any) => (
                  <ButtonToolbar>
                    <Whisper
                      speaker={<Tooltip>{t('profiles.people.title')}</Tooltip>}
                      placement='top'>
                      <Link to={`${ROUTES.profiles.people}?search=${g.name}`}>
                        <IconButton appearance='subtle' icon={<FaUsers />} />
                      </Link>
                    </Whisper>

                    <Whisper
                      speaker={<Tooltip>{t('profiles.cars.title')}</Tooltip>}
                      placement='top'>
                      <Link to={`${ROUTES.profiles.cars}?search=${g.name}`}>
                        <IconButton appearance='subtle' icon={<FaCar />} />
                      </Link>
                    </Whisper>

                    <Whisper
                      speaker={<Tooltip>{t('edit')}</Tooltip>}
                      placement='top'>
                      <IconButton
                        appearance='subtle'
                        onClick={() => this.toggleNewGroupWindow(g.id, g.name)}
                        icon={<FaPen />}
                      />
                    </Whisper>

                    <Checkbox
                      checked={toDelete && toDelete[g.id] !== undefined}
                      onChange={() => this.handleDelete(g)}
                    />
                  </ButtonToolbar>
                )}
              </Cell>
            </Column>
          </PageTable>

          <Footer className='footer'>
            <ButtonToolbar justifyContent='center'>
              <Button
                onClick={() => this.toggleNewGroupWindow()}
                appearance='primary'>
                {t('profiles.groups.btn_new')}
              </Button>
            </ButtonToolbar>
          </Footer>
        </Panel>
      </Content>
    )
  }

  renderHeader() {
    const { search, toDelete } = this.state
    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={
          <DelButton
            onConfirm={this.perfomDelete}
            disabled={!Object.keys(toDelete).length}
            circle
          />
        }
      />
    )
  }

  //
  // form
  //

  handleForm(name: string) {
    const { form } = this.state
    form.name = name
    this.setState({ form })
  }

  performForm = () => {
    const { groups, form } = this.state

    if (!form.id && form.name) {
      RTMIP.createGroup(form.name)
        .then((g: Group) => {
          groups.push(g)
          this.setState({ groups })
        })
        .catch(this.setError)
        .then(() => this.toggleNewGroupWindow())
    } else if (form.id && form.name) {
      RTMIP.renameGroup(form.id, form.name)
        .then((g: Group) => {
          const group = groups.find((group: Group) => group.id === g.id)
          if (group) group.name = g.name
        })
        .catch(this.setError)
        .then(() => this.toggleNewGroupWindow())
    }
  }

  renderForm() {
    const { t } = this.props
    const { form } = this.state

    if (!form.show) return ''

    return (
      <Modal onClose={() => this.toggleNewGroupWindow()} open>
        <Modal.Header>
          <Modal.Title>
            {form.id
              ? t('profiles.groups.formtitle_rename')
              : t('profiles.groups.formtitle_new')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>{t('profiles.groups.name')}</Form.ControlLabel>
              <Input
                value={form.name || ''}
                onChange={(v: string) => this.handleForm(v)}
                onPressEnter={this.performForm}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <ButtonToolbar justifyContent='flex-end'>
            <Button
              appearance='primary'
              disabled={!form.name}
              onClick={this.performForm}>
              {form.id
                ? t('profiles.groups.btn_rename')
                : t('profiles.groups.btn_new')}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withTranslation()(Groups)

const LOCALSTORAGE_VECTOR_KEY = 'vector'

export const DEFAULT_VECTOR_THRESHOLD = 1.1

export interface StorageFace {
  vector: number[]
  threshold: number
  nodetect?: boolean
}

export function localStorageSaveFace(face: StorageFace) {
  if (!face.threshold) face.threshold = DEFAULT_VECTOR_THRESHOLD
  localStorage.setItem(LOCALSTORAGE_VECTOR_KEY, JSON.stringify(face))
}

export function localStorageLoadFace(): StorageFace | undefined {
  const val = localStorage.getItem(LOCALSTORAGE_VECTOR_KEY)
  if (!val) return undefined

  try {
    return JSON.parse(val) as StorageFace
  } catch (error) {
    return undefined
  }
}

export function localStorageRemoveFace() {
  localStorage.removeItem(LOCALSTORAGE_VECTOR_KEY)
}

export function vectorsEqual(a?: number[], b?: number[]): boolean {
  if (!a && !b) return true
  if (!a || !b) return false

  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false
  }

  return true
}

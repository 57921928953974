import React from 'react'

import { Spinner } from 'content'
import RTMIP from 'rtmip'

interface Props {
  src: string
  className?: string
}

export default class CACHE extends React.Component<Props, any> {
  video: any = React.createRef<HTMLVideoElement>()

  getOffset = (): number => {
    return parseInt((parseFloat(this.video.currentTime) * 1000) as any)
  }

  //
  // render
  //

  render() {
    const src = RTMIP.urlauth2(this.props.src)

    if (!src) return this.renderLoader()

    return (
      <video
        ref={(ref) => (this.video = ref)}
        src={src}
        className={this.props.className}
        // crossOrigin='use-credentials'
        crossOrigin='anonymous'
        controls
        autoPlay
      />
    )
  }

  renderLoader() {
    return (
      <div className={this.props.className}>
        <div className='camera-loader'>
          <Spinner className='spinner-small spinner-center' />
        </div>
      </div>
    )
  }
}
